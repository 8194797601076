import { useState } from 'react';

export const useLocalModal = (initialIsOpen?: boolean) => {
  const [isOpenModal, setIsOpenModal] = useState(
    initialIsOpen != null ? initialIsOpen : false
  );

  const handleOpenModal = () => setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);
  const handleSwitchModal = () => setIsOpenModal((prev) => !prev);

  return {
    isOpenModal,
    setIsOpenModal,
    handleOpenModal,
    handleCloseModal,
    handleSwitchModal,
  };
};
