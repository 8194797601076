import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateLocationMutationVariables = Types.Exact<{
  input: Types.CreateLocationInput;
}>;

export type CreateLocationMutation = {
  __typename?: 'Mutation';
  CreateLocation: {
    __typename?: 'LocationEntity';
    id: string;
    name: string;
    address: string;
  };
};

export const CreateLocationDocument = gql`
  mutation CreateLocation($input: CreateLocationInput!) {
    CreateLocation(input: $input) {
      id
      name
      address
    }
  }
`;
export type CreateLocationMutationFn = Apollo.MutationFunction<
  CreateLocationMutation,
  CreateLocationMutationVariables
>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLocationMutation,
    CreateLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLocationMutation,
    CreateLocationMutationVariables
  >(CreateLocationDocument, options);
}
export type CreateLocationMutationHookResult = ReturnType<
  typeof useCreateLocationMutation
>;
export type CreateLocationMutationResult =
  Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<
  CreateLocationMutation,
  CreateLocationMutationVariables
>;
