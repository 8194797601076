import { FC, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/elements/Button';
import InputForm from 'components/elements/inputs';
import { TitleH2 } from 'components/styles/common';
import { useAddCommentMutation } from 'graphql/mutations/orders/__generated__/AddCommentToOrder.generated';
import { useMeQuery } from 'graphql/queries/me/__generated__/me.generated';
import { initials } from 'helpers/common';
import { CommentEntity, OrderEntity } from 'types.generated';
import { AddCommentDto, addCommentSchema } from './schema';
import { ScCommentItem, ScComments } from './styled';
import { getFullDate } from 'helpers/dayjs/formats';

interface CommentsProps {
  order: OrderEntity;
  refetch: any;
}

const Comments: FC<CommentsProps> = ({ order, refetch }) => {
  const [addComment] = useAddCommentMutation();
  const { data: dataMe } = useMeQuery();

  const userName = useMemo(
    () => (dataMe != null ? `${dataMe.Me.name}` : ''),
    [dataMe]
  );

  const methods = useForm<AddCommentDto>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    resolver: yupResolver(addCommentSchema),
  });

  const { handleSubmit, reset } = methods;

  const onSubmit: SubmitHandler<AddCommentDto> = async (data) => {
    addComment({
      variables: {
        text: data.text,
        orderId: order.id,
      },
    }).then(() => {
      refetch();
      reset();
    });
  };

  return (
    <ScComments>
      <TitleH2>Comments</TitleH2>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="avatar">
            <p>{dataMe != null ? initials(userName) : ''}</p>
          </div>
          <InputForm<AddCommentDto>
            id="text"
            type="text"
            required
            autoComplete="off"
          />
          <Button>Post</Button>
          <p className="desc-text">Only you and other staff can see comments</p>
        </form>
      </FormProvider>
      <div className="comments">
        {order.comments != null &&
          order.comments.length > 0 &&
          order.comments.map((comment) => (
            <CommentItem {...comment} key={comment.id} />
          ))}
      </div>
    </ScComments>
  );
};

export default Comments;

interface CommentProps extends CommentEntity {}

const CommentItem: FC<CommentProps> = ({ text, user, createdDate }) => {
  return (
    <ScCommentItem>
      <div className="icon" />
      <p className="user-name">{user?.name}</p>
      <p className="text">{text}</p>
      <p className="date">{getFullDate(createdDate, 'DD MMM, HH:mm')}</p>
    </ScCommentItem>
  );
};
