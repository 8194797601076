import { FC, MutableRefObject, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Edit2, MoreVertical, Search, Trash2 } from 'react-feather';
import { yupResolver } from '@hookform/resolvers/yup';
import { Cell } from 'react-table';
import { TitleH2, TitleH4 } from 'components/styles/common';
import { ScContent } from '../styled';
import { ScActionsBlock, ScLocation } from './styled';
import { Button } from 'components/elements/Button';
import { Modal } from 'components/elements/Modal';
import { useLocationsQuery } from 'graphql/queries/locations/__generated__/locations.generated';
import { CreateLocationModal } from 'components/modules/CreateLocationModal';
import { useComponentVisible } from 'hooks/visibility';
import { theme } from 'theme';
import { SearchDto, searchSchema } from 'hooks/search/search';
import InputForm from 'components/elements/inputs';
import { LocationEntity } from 'types.generated';
import Table from 'components/elements/Table';
import { useDeleteLocationMutation } from 'graphql/mutations/locations/__generated__/deleteLocation.generated';
import { errorAlert, infoAlert } from 'helpers/alerts';
import { useUpdateLocationMutation } from 'graphql/mutations/locations/__generated__/updateLocation.generated';
import { getSiteTheme } from 'hooks/getSiteTheme';

export const Locations = () => {
  const { data: locationsData, loading, refetch } = useLocationsQuery();
  const [deleteLocation] = useDeleteLocationMutation();
  const [updateLocation] = useUpdateLocationMutation();

  const methods = useForm<SearchDto>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    resolver: yupResolver(searchSchema),
    defaultValues: {
      searchValue: '',
    },
  });
  const { watch } = methods;

  const searchValue = watch('searchValue');

  const [info, setInfo] = useState<{
    address: null | string;
    name: null | string;
    id: null | string;
  }>({
    address: null,
    name: null,
    id: null,
  });

  const [isOpenCreateLocationModal, setOpenCreateLocationModal] =
    useState(false);

  const onCreateLocationModal = () =>
    setOpenCreateLocationModal((prev) => !prev);

  const closeCreateLocationModal = () => {
    setOpenCreateLocationModal((prev) => !prev);
    setInfo({ address: null, name: null, id: null });
  };

  const locations = useMemo(() => {
    if (locationsData != null)
      return locationsData.CurrentOrganization.locations?.filter((item) => {
        const name = item.address.toLowerCase();

        const search =
          searchValue != null ? searchValue.toLowerCase() : searchValue;
        return name.indexOf(search) > -1;
      });
  }, [locationsData, searchValue]);

  const handleDeleteLocation = useCallback(
    (deleteLocationId: string) => {
      deleteLocation({ variables: { deleteLocationId } }).then(({ errors }) => {
        if (errors != null)
          errorAlert('Looks like this location in used in some activity');
        else refetch();
      });
    },
    [deleteLocation, refetch]
  );

  const handleUpdateLocation = (id: string, address: string, name: string) => {
    updateLocation({
      variables: {
        input: {
          id,
          address,
          name,
        },
      },
    }).then(() => {
      infoAlert('Location updated!');
      refetch();
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },

      {
        Header: 'Address',
        accessor: 'address',
      },
      {
        Header: ' ',
        accessor: 'actions',
        Cell: (props: Cell<LocationEntity>) => {
          const { id, address, name } = props.row.original;
          return (
            <TdActions
              {...{
                id,
                address,
                name,
                handleDeleteLocation,
                setInfo,
                onCreateLocationModal,
              }}
              key={id}
            />
          );
        },
      },
    ],
    [handleDeleteLocation]
  );

  return (
    <ScContent>
      <ScLocation>
        <div className="header">
          <TitleH2>Locations</TitleH2>
          <Button onClick={onCreateLocationModal}>Add location</Button>
        </div>
        <div className="body">
          <FormProvider {...methods}>
            <form>
              <InputForm<SearchDto>
                id="searchValue"
                placeholder="Search"
                type="text"
                Icon={Search}
              />
            </form>
          </FormProvider>
          <div className="location-table">
            <Table<LocationEntity>
              {...{
                columns,
                data: locations as LocationEntity[],
                emptyText: 'No locations',
                emptyDescription: 'You don’t have any location yet.',
                isLoading: loading,
              }}
            />
          </div>
        </div>
      </ScLocation>
      {isOpenCreateLocationModal && (
        <Modal onClose={closeCreateLocationModal}>
          {(props) => (
            <CreateLocationModal
              {...props}
              onClose={closeCreateLocationModal}
              refetch={refetch}
              update={handleUpdateLocation}
              {...info}
            />
          )}
        </Modal>
      )}
    </ScContent>
  );
};

interface TdActionsProps {
  id: string;
  address: string;
  name: string;
  handleDeleteLocation: (deleteLocationId: string) => void;
  setInfo: React.Dispatch<
    React.SetStateAction<{
      address: null | string;
      name: null | string;
      id: null | string;
    }>
  >;
  onCreateLocationModal: () => void;
}

export const TdActions: FC<TdActionsProps> = ({
  id,
  address,
  name,
  handleDeleteLocation,
  setInfo,
  onCreateLocationModal,
}) => {
  const { ref, openerRef, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleOpen = () => setIsComponentVisible((prev) => !prev);

  const handleUpdate = useCallback(() => {
    setInfo({ address, name, id });
    onCreateLocationModal();
  }, [address, id, name, onCreateLocationModal, setInfo]);

  return (
    <div className="actions">
      <div ref={ref}>
        <MoreVertical width={16} onClick={handleOpen} />
      </div>

      {isComponentVisible && (
        <ActionsBlock
          openerRef={openerRef}
          handleDeleteLocation={() => {
            handleDeleteLocation(id);
            handleOpen();
          }}
          handleUpdate={handleUpdate}
        />
      )}
    </div>
  );
};

interface ActionsBlockProps {
  openerRef: MutableRefObject<any>;
  handleDeleteLocation: () => void;
  handleUpdate: () => void;
}

const ActionsBlock: FC<ActionsBlockProps> = ({
  openerRef,
  handleDeleteLocation,
  handleUpdate,
}) => {
  const { isLight } = getSiteTheme();

  return (
    <ScActionsBlock
      ref={openerRef}
      className={`${!isLight && '!bg-slate-900'}`}
    >
      <div className="item" onClick={handleUpdate}>
        <Edit2 width={16} color={theme.colorSecondaryVioletDark} />{' '}
        <TitleH4>Edit</TitleH4>
      </div>
      <div className="item" onClick={handleDeleteLocation}>
        <Trash2 width={16} color={theme.colorErrorRedDark} />{' '}
        <TitleH4>Delete</TitleH4>
      </div>
    </ScActionsBlock>
  );
};
