import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelOrderMutationVariables = Types.Exact<{
  cancelOrderId: Types.Scalars['String'];
}>;

export type CancelOrderMutation = {
  __typename?: 'Mutation';
  CancelOrder: {
    __typename?: 'BookRespond';
    status?: string | null;
    order: { __typename?: 'OrderEntity'; id: string };
  };
};

export const CancelOrderDocument = gql`
  mutation CancelOrder($cancelOrderId: String!) {
    CancelOrder(id: $cancelOrderId) {
      status
      order {
        id
      }
    }
  }
`;
export type CancelOrderMutationFn = Apollo.MutationFunction<
  CancelOrderMutation,
  CancelOrderMutationVariables
>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      cancelOrderId: // value for 'cancelOrderId'
 *   },
 * });
 */
export function useCancelOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelOrderMutation,
    CancelOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(
    CancelOrderDocument,
    options
  );
}
export type CancelOrderMutationHookResult = ReturnType<
  typeof useCancelOrderMutation
>;
export type CancelOrderMutationResult =
  Apollo.MutationResult<CancelOrderMutation>;
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<
  CancelOrderMutation,
  CancelOrderMutationVariables
>;
