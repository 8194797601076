import * as yup from 'yup';

export type CreateLocationDto = {
  name: string;
  address: string;
};

export const createLocationSchema: yup.SchemaOf<CreateLocationDto> = yup.object(
  {
    name: yup.string().required(),
    address: yup.string().required(),
  }
);
