import axios from 'lib/axios';

export type LoginUserDto = {
  email: string;
  password: string;
};
export type LoginTokens = {
  access_token: string;
  refresh_token: string;
};
export type LoginUserRespond = LoginTokens;
export type TokenData = {
  userId: string;
  permissionId?: string;
};
export const loginUser = async (
  data: LoginUserDto
): Promise<LoginUserRespond> => {
  return await axios
    .post('/controller/auth/user/login', data)
    .then(({ data }) => data);
};
