import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyRoleQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MyRoleQuery = {
  __typename?: 'Query';
  Me: {
    __typename?: 'UserEntity';
    permission?: {
      __typename?: 'PermissionEntity';
      roles: Array<string>;
      organization: { __typename?: 'OrganizationEntity'; name: string };
    } | null;
  };
};

export const MyRoleDocument = gql`
  query MyRole {
    Me {
      permission {
        roles
        organization {
          name
        }
      }
    }
  }
`;

/**
 * __useMyRoleQuery__
 *
 * To run a query within a React component, call `useMyRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyRoleQuery(
  baseOptions?: Apollo.QueryHookOptions<MyRoleQuery, MyRoleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyRoleQuery, MyRoleQueryVariables>(
    MyRoleDocument,
    options
  );
}
export function useMyRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyRoleQuery, MyRoleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyRoleQuery, MyRoleQueryVariables>(
    MyRoleDocument,
    options
  );
}
export type MyRoleQueryHookResult = ReturnType<typeof useMyRoleQuery>;
export type MyRoleLazyQueryHookResult = ReturnType<typeof useMyRoleLazyQuery>;
export type MyRoleQueryResult = Apollo.QueryResult<
  MyRoleQuery,
  MyRoleQueryVariables
>;
