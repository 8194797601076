import { XIcon } from '@heroicons/react/solid';
import { FC } from 'react';
import { ScCross } from './styled';

export interface CrossProps {
  onClick: () => void;
  color?: string;
  right?: number;
  top?: number;
  size?: number;
}

export const Cross: FC<CrossProps> = ({ onClick, ...rest }) => {
  return (
    <ScCross {...rest} className="!z-10">
      <button type="button" onClick={onClick}>
        <XIcon />
      </button>
    </ScCross>
  );
};
