import styled from 'styled-components/macro';
import { hexToRGB } from 'helpers/common';

export const ScModal = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: 0 !important;
  overflow: hidden auto;
  z-index: 1111;
`;

interface ScBgProps {
  bgWidth?: number;
  bgHeight?: number;
  bgRadius?: number;
  alpha?: number;
}

export const ScModalBg = styled.div<ScBgProps>`
  top: 0;
  left: 0;
  width: ${({ bgWidth }) => (bgWidth != null ? `${bgWidth}px` : '100%')};
  height: ${({ bgHeight }) => (bgHeight != null ? `${bgHeight}px` : '100%')};
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme, alpha }) =>
    hexToRGB(theme.colorBlack, alpha != null ? alpha : 0.8)};
  border-radius: ${({ bgRadius }) =>
    bgRadius != null ? `${bgRadius}px` : '0'};
  /* backdrop-filter: ${({ theme }) => theme.backgroundBlur}; */
`;

export const ScModalContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
`;

export enum VerticalAlign {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom',
}

export interface ScModalContentProps {
  verticalAlign?: VerticalAlign;
  fullHeight?: boolean;
  absolute?: boolean;
}

export const ScModalContent = styled.div<ScModalContentProps>`
  position: ${({ absolute = false }) => (absolute ? `absolute` : `relative`)};
  display: inline-block;
  vertical-align: ${({ verticalAlign }) =>
    verticalAlign == null ? VerticalAlign.MIDDLE : verticalAlign};
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
  width: 100%;
  cursor: auto;
  ${({ fullHeight }) => fullHeight && `height: 100%;`}
`;
