import { Dispatch, SetStateAction, useCallback } from 'react';

import { Tokens } from './provider';

export type TokensActions = {
  clearTokens: () => void;
  setTokens: (tokens: Tokens) => void;
  setDefault: () => void;
};

type TokensState = {
  stateTokens: Tokens;
  setStateTokens: Dispatch<SetStateAction<Tokens>>;
};
type UseTokensActions = (data: TokensState) => TokensActions;

export const useTokensActions: UseTokensActions = ({ setStateTokens }) => {
  const clearTokens = useCallback<TokensActions['clearTokens']>(() => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('token_type');
    setStateTokens({
      access_token: '',
      refresh_token: null,
      tokenType: null,
    });
  }, [setStateTokens]);

  const setTokens = useCallback<TokensActions['setTokens']>(
    ({ access_token, refresh_token, tokenType }: Tokens) => {
      localStorage.setItem('access_token', access_token);
      if (refresh_token) {
        localStorage.setItem('refresh_token', refresh_token);
      }
      if (tokenType) {
        localStorage.setItem('token_type', tokenType);
      }

      setStateTokens({
        access_token,
        refresh_token: refresh_token ?? null,
        tokenType: tokenType ?? null,
      });
    },
    [setStateTokens]
  );

  const setDefault = useCallback<TokensActions['setDefault']>(() => {
    const access_token = localStorage.getItem('access_token');
    if (!access_token) {
      clearTokens();
      return;
    }

    setTokens({
      access_token,
      refresh_token: localStorage.getItem('refresh_token'),
      tokenType: localStorage.getItem('token_type'),
    });
  }, [clearTokens, setTokens]);

  return {
    setTokens,
    setDefault,
    clearTokens,
  };
};
