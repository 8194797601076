import { FC, forwardRef, useCallback, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/elements/Button';
import TextareaForm from 'components/elements/inputs/textarea';
import { Modal } from 'components/elements/Modal';
import { TitleH2, TitleH3 } from 'components/styles/common';

import { useUpdatePaymentMethodMutation } from 'graphql/mutations/payment/__generated__/updatePaymentMethod.generated';
import { GetPaymentMethodsInfoQuery } from 'graphql/queries/payment/__generated__/getPaymentMethodsInfo.generated';
import { successAlert } from 'helpers/alerts';
import { ColorsEnums } from 'helpers/enums/common';
import { useLocalModal } from 'hooks/useLocalModal';
import { PayByInvoiceEntity } from 'types.generated';
import { CreatePayByInvoiceDto, CreatePayByInvoiceSchema } from './schema';
import { ScPayByInvoiceModal, ScPaymentComponent } from './styled';
import { useCreatePayByInvoiceMutation } from 'graphql/mutations/payment/__generated__/createBankTransfer.generated';
import { useUpdatePayByInvoiceMutation } from 'graphql/mutations/payment/__generated__/updateBankTransfer.generated';
import { Switch } from 'components/catalyst-elements/switch';

interface SetupPaumentOnPayByInvoiceProps {
  paymentMethodsInfo: GetPaymentMethodsInfoQuery;
  refetch: any;
  loadingGetPaymentMethodsInfo: boolean;
}

export const SetupPaumentOnPayByInvoice: FC<
  SetupPaumentOnPayByInvoiceProps
> = ({ paymentMethodsInfo, refetch, loadingGetPaymentMethodsInfo }) => {
  const { isOpenModal, handleCloseModal, handleOpenModal } = useLocalModal();

  const [updatePaymentMethodPayByInvoice, { loading }] =
    useUpdatePaymentMethodMutation();

  const data = useMemo(() => {
    return paymentMethodsInfo.CurrentOrganization;
  }, [paymentMethodsInfo]);

  const switchPayByInvoice = useCallback(() => {
    if (paymentMethodsInfo != null)
      updatePaymentMethodPayByInvoice({
        variables: {
          input: {
            isPayByInvoiceActive: !data.isPayByInvoiceActive,
            isStripeActive: data.isStripeActive,
          },
        },
      }).then(({ data }) => {
        if (data != null) refetch();
      });
  }, [data, paymentMethodsInfo, refetch, updatePaymentMethodPayByInvoice]);

  return (
    <ScPaymentComponent>
      <div className="top">
        <div className="title">
          <TitleH3>Pay by invoice</TitleH3>
          {data.payByInvoice != null && (
            <div className="toggle">
              <label>
                <Switch
                  defaultChecked={data.isPayByInvoiceActive}
                  onChange={switchPayByInvoice}
                  disabled={loading || loadingGetPaymentMethodsInfo}
                />
              </label>
            </div>
          )}
        </div>
        {data.payByInvoice ? (
          <Button color={ColorsEnums.white} onClick={handleOpenModal}>
            Manage
          </Button>
        ) : (
          <Button onClick={handleOpenModal}>Setup</Button>
        )}
      </div>
      <p className="description">
        Proin eget risus ipsum. Quisque nibh nunc, feugiat quis sem vulputate,
        lobortis lobortis urna. Donec ullamcorper facilisis dolor, vel elementum
        urna commodo rhoncus. Pellentesque quis imperdiet leo.
      </p>
      {isOpenModal && (
        <Modal onClose={handleCloseModal} alpha={0.5}>
          {(props) => (
            <PayByInvoiceModal
              {...props}
              onClose={handleCloseModal}
              refetch={refetch}
              payByInvoice={data.payByInvoice}
            />
          )}
        </Modal>
      )}
    </ScPaymentComponent>
    //OLD
    // <ScSetupPaymen border={true}>
    //   <div>
    //     <TitleH3>Pay by invoice</TitleH3>
    //     {data.payByInvoice && (
    // <Button color={ColorsEnums.white} onClick={handleOpenModal}>
    //   Update invoice
    // </Button>
    //     )}
    //   </div>
    //   {data.payByInvoice == null ? (
    //     <Button onClick={handleOpenModal}>Create invoice</Button>
    //   ) : (
    //     <div className="toggle">
    //       <label>
    // <Toggle
    //   defaultChecked={data.isPayByInvoiceActive}
    //   icons={{
    //     checked: null,
    //     unchecked: null,
    //   }}
    //   onChange={switchPayByInvoice}
    //   disabled={loading || loadingGetPaymentMethodsInfo}
    // />
    //       </label>
    //     </div>
    //   )}

    // {isOpenModal && (
    //   <Modal onClose={handleCloseModal} alpha={0.5}>
    //     {(props) => (
    //       <PayByInvoiceModal
    //         {...props}
    //         onClose={handleCloseModal}
    //         refetch={refetch}
    //         payByInvoice={data.payByInvoice}
    //       />
    //     )}
    //   </Modal>
    // )}
    // </ScSetupPaymen>
  );
};

interface PayByInvoiceModalProps {
  onClose: () => void;
  refetch: any;
  payByInvoice?: PayByInvoiceEntity | null;
}

const PayByInvoiceModal = forwardRef<HTMLDivElement, PayByInvoiceModalProps>(
  (props, ref) => {
    const { payByInvoice } = props;
    const [createPayByInvoice, { loading }] = useCreatePayByInvoiceMutation();
    const [updatePayByInvoice, { loading: loadingUpdate }] =
      useUpdatePayByInvoiceMutation();

    const methods = useForm<CreatePayByInvoiceDto>({
      mode: 'onSubmit',
      reValidateMode: 'onBlur',
      shouldFocusError: true,
      resolver: yupResolver(CreatePayByInvoiceSchema),
      defaultValues: {
        additionalDetails: payByInvoice?.additionalDetails || undefined,
        paymentInstructions: payByInvoice?.paymentInstructions || undefined,
      },
    });
    const { handleSubmit } = methods;

    const onSubmit: SubmitHandler<CreatePayByInvoiceDto> = (input) => {
      if (payByInvoice == null) {
        return createPayByInvoice({
          variables: {
            input: {
              additionalDetails: input.additionalDetails || '',
              paymentInstructions: input.paymentInstructions || '',
            },
          },
        }).then(({ data }) => {
          if (data != null) {
            successAlert('Invoice created!');
            props.refetch();
            props.onClose();
          }
        });
      } else {
        return updatePayByInvoice({
          variables: {
            input: {
              ...input,
              id: `${payByInvoice.id}`,
            },
          },
        }).then(({ data }) => {
          if (data != null) {
            successAlert('Invoice updated!');
            props.refetch();
            props.onClose();
          }
        });
      }
    };

    return (
      <ScPayByInvoiceModal ref={ref}>
        <TitleH2>Invoice settings</TitleH2>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextareaForm<CreatePayByInvoiceDto>
              label="Additional details"
              id="additionalDetails"
              rows={8}
            />
            <TextareaForm<CreatePayByInvoiceDto>
              label="Payment instructions"
              id="paymentInstructions"
              rows={8}
            />
            <div className="form-footer">
              <Button
                type="button"
                color={ColorsEnums.white}
                onClick={props.onClose}
                disabled={loading || loadingUpdate}
              >
                Close
              </Button>
              <Button
                disabled={loading || loadingUpdate}
                isLoading={loading || loadingUpdate}
              >
                {payByInvoice != null ? 'Save' : 'Create'}
              </Button>
            </div>
          </form>
        </FormProvider>
      </ScPayByInvoiceModal>
    );
  }
);
