import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InviteUserMutationVariables = Types.Exact<{
  input: Array<Types.InviteUserInput> | Types.InviteUserInput;
}>;

export type InviteUserMutation = {
  __typename?: 'Mutation';
  InviteUser: Array<{ __typename?: 'PermissionEntity'; id: string }>;
};

export const InviteUserDocument = gql`
  mutation InviteUser($input: [InviteUserInput!]!) {
    InviteUser(input: $input) {
      id
    }
  }
`;
export type InviteUserMutationFn = Apollo.MutationFunction<
  InviteUserMutation,
  InviteUserMutationVariables
>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteUserMutation,
    InviteUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(
    InviteUserDocument,
    options
  );
}
export type InviteUserMutationHookResult = ReturnType<
  typeof useInviteUserMutation
>;
export type InviteUserMutationResult =
  Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<
  InviteUserMutation,
  InviteUserMutationVariables
>;
