import { FC } from 'react';
import { ScTeacherItem } from './styled';
import avatar from 'theme/images/avatar.svg';
import { OptionType } from 'components/elements/inputs/common';
import { getSiteTheme } from 'hooks/getSiteTheme';

const TeacherItem: FC<OptionType> = ({ avatarUrl, label }) => {
  const { isLight } = getSiteTheme();
  return (
    <ScTeacherItem>
      <img
        src={avatarUrl || avatar}
        alt=""
        style={{
          width: 25,
          height: 25,
          borderRadius: 90,
          objectFit: 'cover',
        }}
      />
      <p className={`${isLight ? 'text-black' : 'text-white'}`}>{label}</p>
    </ScTeacherItem>
  );
};

export default TeacherItem;
