import axios from 'lib/axios';

import { LoginTokens } from './login';

export type ImproveTokenDto = {
  organizationId: string;
};
export type ImproveTokenRespond = LoginTokens;
export const improveToken = async (
  data: ImproveTokenDto
): Promise<ImproveTokenRespond> => {
  return await axios
    // .post('/controller/auth/improve-token', data)
    .post('/controller/auth/organization/login', data)
    .then(({ data }) => data);
};
