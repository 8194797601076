import { FC } from 'react';
import { Badge } from 'components/catalyst-elements/badge';

interface IStatus {
  status: string;
}

export const handlerChangeStatusName = (status: string) => {
  const lowerCaseStatus = status.toLowerCase();

  switch (lowerCaseStatus) {
    case 'await':
      return 'Upcoming';
    case 'ongoing':
      return 'Started';
    case 'completed':
      return 'Completed';
    case 'archived':
      return 'Archived';

    default:
      break;
  }
};

const Status: FC<IStatus> = ({ status }) => {
  const handlerStatus = (status: string) => {
    console.log(status, 'STATUS');
    switch (status) {
      case 'await':
        return <Badge color="amber">{handlerChangeStatusName(status)}</Badge>;
      case 'ongoing':
        return <Badge color="sky">{handlerChangeStatusName(status)}</Badge>;
      case 'completed':
        return <Badge color="lime">{handlerChangeStatusName(status)}</Badge>;
      case 'archived':
        return <Badge color="zinc">{handlerChangeStatusName(status)}</Badge>;
      case 'successed':
        return <Badge color="green">{status}</Badge>;
      case 'successes':
        return <Badge color="green">{status}</Badge>;
      case 'failed':
        return <Badge color="red">{status}</Badge>;
      case 'refunded':
        return <Badge color="teal">{status}</Badge>;
      case 'canceled':
        return <Badge color="red">{status}</Badge>;
      default:
        break;
    }
  };

  return <>{handlerStatus(status)}</>;
};

export default Status;

/* 
<div className="flex gap-3">
    <Badge color="lime">documentation</Badge>
    <Badge color="purple">help wanted</Badge>
    <Badge color="rose">bug</Badge>
</div> 
*/
