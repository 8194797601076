import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCheckoutFieldsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetCheckoutFieldsQuery = {
  __typename?: 'Query';
  CurrentOrganization: {
    __typename?: 'OrganizationEntity';
    checkoutFields?: Array<{
      __typename?: 'CheckoutFieldsEntity';
      id: string;
      name: string;
      description?: string | null;
      type: string;
      required: boolean;
      enable: boolean;
      createdDate?: any | null;
      sortOrder: number;
    }> | null;
  };
};

export const GetCheckoutFieldsDocument = gql`
  query getCheckoutFields {
    CurrentOrganization {
      checkoutFields {
        id
        name
        description
        type
        required
        enable
        createdDate
        sortOrder
      }
    }
  }
`;

/**
 * __useGetCheckoutFieldsQuery__
 *
 * To run a query within a React component, call `useGetCheckoutFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckoutFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckoutFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCheckoutFieldsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCheckoutFieldsQuery,
    GetCheckoutFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCheckoutFieldsQuery,
    GetCheckoutFieldsQueryVariables
  >(GetCheckoutFieldsDocument, options);
}
export function useGetCheckoutFieldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCheckoutFieldsQuery,
    GetCheckoutFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCheckoutFieldsQuery,
    GetCheckoutFieldsQueryVariables
  >(GetCheckoutFieldsDocument, options);
}
export type GetCheckoutFieldsQueryHookResult = ReturnType<
  typeof useGetCheckoutFieldsQuery
>;
export type GetCheckoutFieldsLazyQueryHookResult = ReturnType<
  typeof useGetCheckoutFieldsLazyQuery
>;
export type GetCheckoutFieldsQueryResult = Apollo.QueryResult<
  GetCheckoutFieldsQuery,
  GetCheckoutFieldsQueryVariables
>;
