// import { ErrorBlock } from './ErrorBlock';
import { ScInternalError } from './styled';
export const InternalError = () => {
  return (
    <ScInternalError>
      <p>🤔 Hmmmm... </p>
      <p>An unexpected error occurred</p>
      <a href="/">Go home</a>
    </ScInternalError>
  );
};
