import React, { FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import styled from 'styled-components';
import DragHandle from './DragHandle';
import { getSiteTheme } from 'hooks/getSiteTheme';

const DraggingRow = styled.td`
  background: #f0f0f0;
`;

const TableData = styled.td`
  &:first-of-type {
    min-width: 20ch;
  }
`;

interface DraggableTableRowProps {
  row: any;
}

const DraggableTableRow: FC<DraggableTableRowProps> = ({ row }) => {
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id: row.original.id,
  });
  // const style = {
  //   transform: CSS.Transform.toString(transform),
  //   transition: transition,
  // };

  const { isLight } = getSiteTheme();
  return (
    <tr ref={setNodeRef} {...row.getRowProps()}>
      {isDragging ? (
        <DraggingRow colSpan={row.cells.length}>&nbsp;</DraggingRow>
      ) : (
        row.cells.map((cell: any, i: any) => {
          if (i === 0) {
            return (
              <TableData
                className={`whitespace-nowrap px-3 py-4 text-sm ${
                  isLight ? 'text-gray-500' : 'text-slate-300'
                }`}
                {...cell.getCellProps()}
              >
                <DragHandle {...attributes} {...listeners} />
                <span>{cell.render('Cell')}</span>
              </TableData>
            );
          }
          return (
            <TableData
              className={`whitespace-nowrap px-6 py-4 text-sm ${
                isLight ? 'text-gray-500' : 'text-slate-300'
              }`}
              {...cell.getCellProps()}
            >
              {cell.render('Cell')}
            </TableData>
          );
        })
      )}
    </tr>
  );
};

export default DraggableTableRow;
