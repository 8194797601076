import React, { forwardRef, useMemo, useRef, useState } from 'react';
import { Spinner } from 'components/elements/Spinner';
import { useMeQuery } from 'graphql/queries/me/__generated__/me.generated';
import { useSettings } from 'hooks/useSettings';
import { Checkout } from './Checkout';
import { Locations } from './Locations';
import { MyProfile } from './MyProfile';
import { SettingsNavBar } from './NavBar';
import { Notifications } from './Notifications';
import { Organization } from './Organization';
import { Payment } from './Payment';
import { ScSettings } from './styled';
import { Team } from './Team';
import { Tags } from './Tags';
import { Terms } from './Terms';
import { Integrations } from './Integrations';
import { Cross } from 'components/elements/Button/Cross';
import { Transition } from '@headlessui/react';
import BurgerSvg from 'theme/images/burger.svg';
import { getSiteTheme } from 'hooks/getSiteTheme';

export enum SettingTab {
  PROFILE = 'profile',
  ORGANIZATION = 'organization',
  TEAM = 'team',
  PAYMENT = 'payment',
  LOCATIONS = 'locations',
  NOTIFICATIONS = 'notifications',
  CHECKOUT = 'checkout',
  TAGS = 'tags',
  TERMS = 'terms',
  INTEGRATIONS = 'integrations',
}

export const Settings = forwardRef<
  HTMLDivElement,
  { onSettingsModal: () => void }
>((props, ref) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  //@ts-ignore
  const { onSettingsModal } = props;

  const { data } = useMeQuery();

  const { activeTab, setActiveTab } = useSettings();

  const user = useMemo(() => {
    if (data != null) return data.Me;
    return null;
  }, [data]);

  const [activeId, setActiveId] = useState<any>();

  const defaultRef = useRef<any>();

  const components = useMemo(
    () => [
      {
        id: SettingTab.PROFILE,
        Component: <MyProfile />,
      },
      {
        id: SettingTab.ORGANIZATION,
        Component: <Organization />,
      },
      {
        id: SettingTab.TEAM,
        Component: <Team />,
      },
      {
        id: SettingTab.PAYMENT,
        Component: <Payment />,
      },
      {
        id: SettingTab.LOCATIONS,
        Component: <Locations />,
      },
      {
        id: SettingTab.NOTIFICATIONS,
        Component: <Notifications />,
      },
      {
        id: SettingTab.CHECKOUT,
        Component: <Checkout {...{ activeId, setActiveId }} />,
      },
      {
        id: SettingTab.TAGS,
        Component: <Tags />,
      },
      {
        id: SettingTab.TERMS,
        Component: <Terms />,
      },
      {
        id: SettingTab.INTEGRATIONS,
        Component: <Integrations />,
      },
    ],
    [activeId]
  );

  const Component = useMemo(
    () => components.find(({ id }) => id === activeTab)?.Component,
    [activeTab, components]
  );

  const { isLight } = getSiteTheme();

  return (
    <ScSettings
      ref={activeId != null ? defaultRef : ref}
      className={`!relative ${!isLight && 'dark'} !bg-white `}
    >
      {user != null && Component != null ? (
        <>
          <div className="absolute h-full lg:static z-30 lg:hidden">
            <Transition
              className="h-full"
              show={isSettingsOpen}
              enter="transition ease-out duration-300"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100 z-10"
              leave="transition ease-in duration-200"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              {() => (
                <div className="h-full relative">
                  <SettingsNavBar
                    email={user.email}
                    setIsSettingsOpen={setIsSettingsOpen}
                    isSettingsOpen={isSettingsOpen}
                    {...{ activeTab, setActiveTab }}
                  />
                  <div className="lg:hidden">
                    <Cross
                      size={24}
                      top={5}
                      right={5}
                      onClick={() => setIsSettingsOpen(false)}
                    />
                  </div>
                </div>
              )}
            </Transition>
          </div>
          <div
            className="absolute top-[5px] left-[5px] z-20 lg:hidden rounded-md"
            onClick={() => setIsSettingsOpen(true)}
          >
            <img src={BurgerSvg} color="black" width={26} alt="MenuSvg" />
          </div>
          <div className="hidden lg:block">
            <SettingsNavBar
              email={user.email}
              setIsSettingsOpen={setIsSettingsOpen}
              isSettingsOpen={isSettingsOpen}
              {...{ activeTab, setActiveTab }}
            />
          </div>
          <Cross
            size={24}
            top={5}
            right={5}
            onClick={() => {
              onSettingsModal();
            }}
          />
          {Component}
        </>
      ) : (
        <div className="spinner">
          <Spinner size={64} />
        </div>
      )}
    </ScSettings>
  );
});
