import { createContext, FC, useCallback, useState } from 'react';

export interface UnsavedContextData {
  anyChanges: boolean;
}

export interface UnsavedContextActions {
  handleSetAnyChanges: (value: boolean) => void;
}

export const UnsavedContext = createContext<
  UnsavedContextData & UnsavedContextActions
>(null as any);

export const UnsavedContextProvider: FC = ({ children }) => {
  const [anyChanges, setAnyChanges] = useState(false);

  const handleSetAnyChanges = useCallback(
    (value: boolean) => setAnyChanges(value),
    []
  );

  return (
    <UnsavedContext.Provider
      value={{
        handleSetAnyChanges,
        anyChanges,
      }}
    >
      {children}
    </UnsavedContext.Provider>
  );
};
