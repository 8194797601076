import styled from 'styled-components/macro';

export const ScAddAttendeeModal = styled.div`
  width: 100%;
  border-radius: 8px;
  /* overflow: hidden; */
  margin: 40px auto;
  /* min-height: 64px; */
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px;
  position: relative;
  & > h2 {
    margin-bottom: 32px;
  }
  form {
    & > .grid {
      display: flex;
      flex-direction: column;
      // display: grid;
      gap: 16px;
      grid-template-columns: 412px 412px;
    }
    & > .bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      & > .btns {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
        margin-top: 16px;
      }
    }
  }
  & > .spinner-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
