import styled from 'styled-components';

export const ScNotifications = styled.div`
  & > .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  & > .body {
    margin-top: 24px;
    & > .notification-table {
      height: 612px;
      tr {
        cursor: default;
        td {
          p {
            max-width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            :has(> .actions) {
              overflow: visible;
            }
          }
        }
        .actions {
          text-align: end;
          position: relative;
          svg {
            cursor: pointer;
          }
        }
      }
      .edit {
        & > svg {
          cursor: pointer;
        }
      }
    }
  }
`;

export const ScActionsBlock = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #ffffff;
  border-radius: 8px;
  padding: 8px 40px 8px 16px;
  border: 1px solid #ebecf0;
  right: 16px;
  z-index: 1;
  /* shadow-base */
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  & > .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
`;
