import { forwardRef, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/elements/Button';
import InputForm from 'components/elements/inputs';
import { ModalProps } from 'components/elements/Modal';
import { TextSmall, TitleH2 } from 'components/styles/common';
import { ScCreateLocationModal } from './styled';
import { CreateLocationDto, createLocationSchema } from './schema';
import { infoAlert } from 'helpers/alerts';
import { useTheme } from 'hooks/theme';
import { MapPin as MapPinIcon } from 'react-feather';
import { useCreateLocationMutation } from 'graphql/mutations/locations/__generated__/createLocation.generated';
import { getSiteTheme } from 'hooks/getSiteTheme';

export interface CreateLocationModalProps extends Pick<ModalProps, 'onClose'> {
  refetch: any;
  update?: (id: string, address: string, name: string) => void;
  id?: string | null;
  name?: string | null;
  address?: string | null;
}

export const CreateLocationModal = forwardRef<
  HTMLDivElement,
  CreateLocationModalProps
>((props, ref) => {
  const { update, id, name, address, refetch, onClose } = props;
  const { colorSecondaryGrayDark } = useTheme();

  const methods = useForm<CreateLocationDto>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    resolver: yupResolver(createLocationSchema),
    defaultValues: {
      name: name != null ? name : '',
      address: address != null ? address : '',
    },
  });
  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = methods;

  const [create, { loading, error }] = useCreateLocationMutation();

  const onSubmit: SubmitHandler<CreateLocationDto> = (input) => {
    if (update != null && id != null && onClose != null) {
      update(id, input.address, input.name);
      onClose();
      return;
    }
    return create({
      variables: {
        input,
      },
    });
  };

  useEffect(() => {
    if (isSubmitSuccessful && !error && onClose != null) {
      infoAlert('Location created!');
      refetch();
      //@ts-ignore
      setTimeout(() => onClose(), 0);

      reset();
    }
  }, [error, isSubmitSuccessful, onClose, refetch, reset]);

  const { isLight } = getSiteTheme();

  return (
    <ScCreateLocationModal
      className={`${isLight ? 'bg-white' : 'bg-slate-800'}`}
      ref={ref}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="title-text">
            <MapPinIcon width={40} height={32} color={colorSecondaryGrayDark} />
            <TitleH2>{id != null ? 'Update location' : 'Add location'}</TitleH2>
            <TextSmall color={colorSecondaryGrayDark}>
              You can create location entity to your organization
            </TextSmall>
          </div>

          <div className="input-div">
            <InputForm<CreateLocationDto>
              id="name"
              disabled={loading}
              required
              placeholder="Name"
            />
          </div>
          <div className="input-div">
            <InputForm<CreateLocationDto>
              id="address"
              disabled={loading}
              required
              placeholder="Address"
            />
          </div>

          <Button
            type="submit"
            disabled={Boolean(Object.keys(errors).length)}
            isLoading={loading}
            fullWidth={true}
          >
            {id != null ? 'Update' : 'Create'}
          </Button>
        </form>
      </FormProvider>
    </ScCreateLocationModal>
  );
});
