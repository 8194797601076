import axios from 'lib/axios';

import { LoginTokens } from './login';

export type RefreshUserTokenDto = {
  refreshToken: string;
};
export type RefreshUserTokenRespond = Omit<LoginTokens, 'refresh_token'>;

export const refreshUserToken = async (
  data: RefreshUserTokenDto
): Promise<RefreshUserTokenRespond> => {
  return await axios
    .post('/controller/auth/refresh', data)
    .then(({ data }) => data);
};
