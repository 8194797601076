import React, { createContext, FC, useCallback, useState } from 'react';
import { Modal } from 'components/elements/Modal';
import { OrderModal } from 'components/modules/OrderModal';
import {
  AddAttendeeModal,
  AddAttendeeModalProps,
} from 'components/modules/AddAttendeeModal';
import { TermsModal } from 'components/modules/TermsModal';
import { SearchModal } from 'components/modules/SearchModal';
import { Settings } from 'components/modules/Settings';

interface ModalsContextData {}

interface ModalsContextActions {
  onSettingsModal: () => void;
  openSettingsModal: () => void;

  onOrderModal: (
    orderId: string,
    activityId: string,
    classesCount: number,
    refetch: any
  ) => void;
  onMoveAttendeeModal: () => void;
  isOpenMoveAttendeeModal: boolean;
  closeOrderModal: () => void;
  onAddAttendeeModal: () => void;
  onAddAttendeeToActivityModal: (
    activityId: string | null,
    refetch: any
  ) => void;
  onTermsModal: () => void;
  onSearchModal: () => void;
  setIsPossibleToClose: React.Dispatch<React.SetStateAction<boolean>>;
  onOrganizationTermsModal: () => void;
  openOrganizationTermsModal: (terms: string | null) => void;
}

export const ModalsContext = createContext<
  ModalsContextData & ModalsContextActions
>(null as any);

export const ModalsContextProvider: FC = ({ children }) => {
  //<>Settings
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState(false);
  const [isPossibleToClose, setIsPossibleToClose] = useState(true);

  const onSettingsModal = useCallback(() => {
    if (isPossibleToClose) {
      setIsOpenSettingsModal((s) => {
        if (s) {
          window.location.reload();
        }
        return !s;
      });
    }
  }, [isPossibleToClose, setIsOpenSettingsModal]);

  const openSettingsModal = useCallback(() => {
    setIsOpenSettingsModal(true);
  }, [setIsOpenSettingsModal]);

  //<>Order
  const [isOpenOrderModal, setIsOpenOrderModal] = useState(false);
  const [selectedOrder, setOrder] = useState({
    orderId: '',
    activityId: '',
    classesCount: 0,
    refetch: null,
  });

  const onOrderModal = useCallback(
    (
      orderId: string,
      activityId: string,
      classesCount: number,
      refetch: any
    ) => {
      setIsOpenOrderModal((s) => !s);
      setOrder({ orderId, activityId, classesCount, refetch });
    },
    [setIsOpenOrderModal]
  );

  const handleOpenOrderModal = useCallback(() => {
    setIsOpenOrderModal((s) => !s);
  }, [setIsOpenOrderModal]);

  //<>MoveAttendee
  const [isOpenMoveAttendeeModal, setIsOpenMoveAttendeeModal] = useState(false);

  const onMoveAttendeeModal = useCallback(() => {
    setIsOpenMoveAttendeeModal((s) => !s);
  }, [setIsOpenMoveAttendeeModal]);

  //<>AddAttendee

  const [isOpenAddAttendeeModal, setIsOpenAddAttendeeModal] = useState(false);
  const [addAttendeeProps, setAddAttendeeProps] = useState<null | Omit<
    AddAttendeeModalProps,
    'onClose'
  >>(null);

  const onAddAttendeeModal = useCallback(() => {
    setIsOpenAddAttendeeModal((s) => !s);
    setAddAttendeeProps(null);
  }, [setIsOpenAddAttendeeModal]);

  const onAddAttendeeToActivityModal = useCallback(
    (activityId: string | null, refetch: any) => {
      setIsOpenAddAttendeeModal((s) => !s);
      setAddAttendeeProps({ activityId, refetch });
    },
    [setIsOpenAddAttendeeModal]
  );

  //<>Terms
  const [isOpenTermsModal, setIsOpenTermsModal] = useState(false);

  const onTermsModal = useCallback(
    (terms?: string) => {
      setIsOpenTermsModal((s) => !s);
    },
    [setIsOpenTermsModal]
  );

  //<>Terms

  //<>Terms Organization
  const [isOpenOrganizationTermsModal, setIsOpenOrganizationTermsModal] =
    useState(false);
  const [terms, setTerms] = useState<string | null>(null);

  const onOrganizationTermsModal = useCallback(() => {
    setIsOpenOrganizationTermsModal((s) => !s);
    setTerms(null);
  }, [setIsOpenOrganizationTermsModal]);

  const openOrganizationTermsModal = useCallback(
    (terms: string | null) => {
      setIsOpenOrganizationTermsModal((s) => !s);
      setTerms(terms);
    },
    [setIsOpenOrganizationTermsModal]
  );

  //<>Terms Organization

  const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);

  const onSearchModal = useCallback(() => {
    setIsOpenSearchModal((s) => !s);
  }, [setIsOpenSearchModal]);

  return (
    <ModalsContext.Provider
      value={{
        onSettingsModal,
        onOrderModal,
        onMoveAttendeeModal,
        isOpenMoveAttendeeModal,
        closeOrderModal: handleOpenOrderModal,
        onAddAttendeeModal,
        openSettingsModal,
        onAddAttendeeToActivityModal,
        onTermsModal,
        onSearchModal,
        setIsPossibleToClose,
        onOrganizationTermsModal,
        openOrganizationTermsModal,
      }}
    >
      {children}
      {isOpenSettingsModal && (
        <Modal onClose={onSettingsModal}>
          {(props) => <Settings {...props} onSettingsModal={onSettingsModal} />}
        </Modal>
      )}
      {isOpenOrderModal && (
        <Modal onClose={handleOpenOrderModal}>
          {(props) => (
            <OrderModal
              {...props}
              orderId={selectedOrder.orderId}
              activityId={selectedOrder.activityId}
              onClose={handleOpenOrderModal}
              classesCount={selectedOrder.classesCount}
              refetch={selectedOrder.refetch}
            />
          )}
        </Modal>
      )}
      {isOpenAddAttendeeModal && addAttendeeProps != null && (
        <Modal onClose={onAddAttendeeModal}>
          {(props) => (
            <AddAttendeeModal
              {...props}
              {...{ ...addAttendeeProps }}
              onClose={onAddAttendeeModal}
            />
          )}
        </Modal>
      )}
      {isOpenTermsModal && (
        <Modal onClose={onTermsModal}>
          {(props) => <TermsModal {...props} onClose={onTermsModal} />}
        </Modal>
      )}
      {isOpenOrganizationTermsModal && (
        <Modal onClose={onOrganizationTermsModal}>
          {(props) => (
            <TermsModal
              {...props}
              onClose={onOrganizationTermsModal}
              terms={terms}
            />
          )}
        </Modal>
      )}
      {isOpenSearchModal && (
        <Modal onClose={onSearchModal}>
          {(props) => <SearchModal {...props} onClose={onSearchModal} />}
        </Modal>
      )}
    </ModalsContext.Provider>
  );
};
