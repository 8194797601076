import * as React from 'react';
import {
  FieldPath,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';
import { ErrorText, Label } from '../common';
import { ScInputForm } from '../styled';
import { ScSelect } from './styled';

export type InputProps<T extends FieldValues = FieldValues> = {
  label?: string;
  id: FieldPath<T>;
  placeholder?: string;
  helperText?: React.ReactNode;
  type?: React.HTMLInputTypeAttribute;
  readOnly?: boolean;
  hideError?: boolean;
  validation?: RegisterOptions;
  options?: string[];
} & React.ComponentPropsWithoutRef<'select'>;

export default function SelectForm<T extends FieldValues = FieldValues>({
  label,
  placeholder = '',
  helperText,
  id,
  type = 'text',
  readOnly = false,
  hideError = false,
  validation,
  options,
  children,
  ...rest
}: InputProps<T>) {
  const {
    register,
    formState: { errors },
  } = useFormContext<T>();

  return (
    <ScInputForm>
      <Label label={label} id={id} />
      <div className="input-container">
        <ScSelect
          {...register(id, validation)}
          {...rest}
          name={id}
          id={id}
          placeholder={placeholder}
          aria-describedby={id}
          error={errors[id]}
        >
          {options != null
            ? options.map((item) => (
                <option key={`option_${item}`} value={item}>
                  {item}
                </option>
              ))
            : children}
        </ScSelect>
      </div>
      <ErrorText
        hideError={hideError}
        helperText={helperText}
        errors={errors}
        id={id}
      />
    </ScInputForm>
  );
}
