import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  Me: {
    __typename?: 'UserEntity';
    id: string;
    createdDate?: any | null;
    updatedDate?: any | null;
    deletedDate?: any | null;
    email: string;
    name?: string | null;
    confirmed: boolean;
    finished: boolean;
    postcode?: string | null;
    country?: string | null;
    avatar?: {
      __typename?: 'UserImageEntity';
      id: string;
      createdDate?: any | null;
      updatedDate?: any | null;
      deletedDate?: any | null;
      link: string;
      filename: string;
      key: string;
      userId: string;
    } | null;
    permission?: {
      __typename?: 'PermissionEntity';
      id: string;
      createdDate?: any | null;
      updatedDate?: any | null;
      deletedDate?: any | null;
      roles: Array<string>;
      status: string;
      userId: string;
      organizationId: string;
      organization: { __typename?: 'OrganizationEntity'; name: string };
    } | null;
  };
};

export const MeDocument = gql`
  query Me {
    Me {
      id
      createdDate
      updatedDate
      deletedDate
      email
      name
      confirmed
      finished
      postcode
      country
      avatar {
        id
        createdDate
        updatedDate
        deletedDate
        link
        filename
        key
        userId
      }
      permission {
        id
        createdDate
        updatedDate
        deletedDate
        roles
        status
        userId
        organizationId
        organization {
          name
        }
      }
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
