import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';

dayjs.extend(utc);

export const getFullDate = (time: any, format?: string) =>
  dayjs(time).format(format != null ? format : 'DD MMM, YYYY');

export const getCroppedDate = (time: any) => dayjs(time).format('DD. MMM');

export const getTime = (time: any) => dayjs(time).format('HH:mm');

export const getHours = (time: any) => dayjs(time).format('HH');

export const getMinutes = (time: any) => dayjs(time).format('mm');

const parseTime = (time: string) => {
  if (time.length > 1) {
    return time;
  }

  return `0${time}`;
};

export const getLectureLength = (startTime: string, endTime: string) => {
  const endHours = getHours(dayjs(endTime));
  const startHours = getHours(dayjs(startTime));

  const endMinutes = getMinutes(dayjs(endTime));
  const startMinutes = getMinutes(dayjs(startTime));

  const calculatedHours = `${Number(endHours) - Number(startHours)}`;
  const calculatedMinutes = `${Number(endMinutes) - Number(startMinutes)}`;

  return `${parseTime(calculatedHours)}:${parseTime(calculatedMinutes)}`;
};

export const addTimeToDate = (date: Date, time: Date) => {
  const h = +getHours(time);
  const m = +getMinutes(time);

  return new Date(date.setHours(h, m));
};

export const getDuration = (start: Date, end: Date) => {
  const start_h = +getHours(start);
  const end_h = +getHours(end);

  const start_m = +getMinutes(start);
  const end_m = +getMinutes(end);

  const m = end_m - start_m;
  const h = m >= 0 ? end_h - start_h : end_h - start_h - 1;

  return `${h >= 10 ? h : `0${h}`}:${
    m >= 0 ? (m >= 10 ? m : `0${m}`) : 60 + m
  }`;
};

export const addDurationToDate = (date: Date, time: string) => {
  const h = +time.split(':')[0];
  const m = +time.split(':')[1];

  return moment(date).add(h, 'h').add(m, 'm').toDate();
};

export const getMinutesFromString = (time: string) => {
  const h = +time.split(':')[0];
  const m = +time.split(':')[1];

  return m + h * 60;
};

export const minutesToString = (minutes: number) => {
  const h = (minutes / 60) >> 0;
  const m = minutes % 60;

  return `${h >= 10 ? h : `0${h}`}:${m >= 10 ? m : `0${m}`}`;
};

export const getHoursFromString = (time: string) => {
  const h = +time;

  return h / 24;
};

export const hoursToString = (hours: number) => {
  const h = (hours * 24) >> 0;

  return `${h >= 10 ? h : `0${h}`}:00:00`;
};

export function timeToSendNotificationToNumber(time: string) {
  return +time.split(':')[0] / 24;
}
