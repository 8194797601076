import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCurrentOrganizationSettingsMutationVariables = Types.Exact<{
  input: Types.UpdateOrganizationInput;
}>;

export type UpdateCurrentOrganizationSettingsMutation = {
  __typename?: 'Mutation';
  UpdateCurrentOrganization: {
    __typename?: 'OrganizationEntity';
    id: string;
    createdDate?: any | null;
    updatedDate?: any | null;
    deletedDate?: any | null;
    name: string;
    registrationNumber?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    stripeAccountId?: string | null;
    postcode?: string | null;
    country?: string | null;
    city?: string | null;
    address?: string | null;
    description?: string | null;
  };
};

export const UpdateCurrentOrganizationSettingsDocument = gql`
  mutation UpdateCurrentOrganizationSettings($input: UpdateOrganizationInput!) {
    UpdateCurrentOrganization(input: $input) {
      id
      createdDate
      updatedDate
      deletedDate
      name
      registrationNumber
      email
      phoneNumber
      stripeAccountId
      postcode
      country
      city
      address
      description
    }
  }
`;
export type UpdateCurrentOrganizationSettingsMutationFn =
  Apollo.MutationFunction<
    UpdateCurrentOrganizationSettingsMutation,
    UpdateCurrentOrganizationSettingsMutationVariables
  >;

/**
 * __useUpdateCurrentOrganizationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentOrganizationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentOrganizationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentOrganizationSettingsMutation, { data, loading, error }] = useUpdateCurrentOrganizationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrentOrganizationSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentOrganizationSettingsMutation,
    UpdateCurrentOrganizationSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentOrganizationSettingsMutation,
    UpdateCurrentOrganizationSettingsMutationVariables
  >(UpdateCurrentOrganizationSettingsDocument, options);
}
export type UpdateCurrentOrganizationSettingsMutationHookResult = ReturnType<
  typeof useUpdateCurrentOrganizationSettingsMutation
>;
export type UpdateCurrentOrganizationSettingsMutationResult =
  Apollo.MutationResult<UpdateCurrentOrganizationSettingsMutation>;
export type UpdateCurrentOrganizationSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCurrentOrganizationSettingsMutation,
    UpdateCurrentOrganizationSettingsMutationVariables
  >;
