import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPaymentMethodsInfoQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetPaymentMethodsInfoQuery = {
  __typename?: 'Query';
  CurrentOrganization: {
    __typename?: 'OrganizationEntity';
    id: string;
    isStripeActive: boolean;
    isPayByInvoiceActive: boolean;
    payByInvoice?: {
      __typename?: 'PayByInvoiceEntity';
      id: string;
      createdDate?: any | null;
      updatedDate?: any | null;
      deletedDate?: any | null;
      additionalDetails?: string | null;
      paymentInstructions?: string | null;
      organizationId: string;
    } | null;
  };
};

export const GetPaymentMethodsInfoDocument = gql`
  query GetPaymentMethodsInfo {
    CurrentOrganization {
      id
      isStripeActive
      isPayByInvoiceActive
      payByInvoice {
        id
        createdDate
        updatedDate
        deletedDate
        additionalDetails
        paymentInstructions
        organizationId
      }
    }
  }
`;

/**
 * __useGetPaymentMethodsInfoQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodsInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentMethodsInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPaymentMethodsInfoQuery,
    GetPaymentMethodsInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPaymentMethodsInfoQuery,
    GetPaymentMethodsInfoQueryVariables
  >(GetPaymentMethodsInfoDocument, options);
}
export function useGetPaymentMethodsInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentMethodsInfoQuery,
    GetPaymentMethodsInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaymentMethodsInfoQuery,
    GetPaymentMethodsInfoQueryVariables
  >(GetPaymentMethodsInfoDocument, options);
}
export type GetPaymentMethodsInfoQueryHookResult = ReturnType<
  typeof useGetPaymentMethodsInfoQuery
>;
export type GetPaymentMethodsInfoLazyQueryHookResult = ReturnType<
  typeof useGetPaymentMethodsInfoLazyQuery
>;
export type GetPaymentMethodsInfoQueryResult = Apollo.QueryResult<
  GetPaymentMethodsInfoQuery,
  GetPaymentMethodsInfoQueryVariables
>;
