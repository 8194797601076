import { FC, useEffect, useMemo } from 'react';
import { Role } from 'helpers/enums/common';
import { useAuth } from 'contexts/auth/context';
import { useMeLazyQuery } from 'graphql/queries/me/__generated__/me.generated';
import { MeContext } from './context';

export const MeProvider: FC = ({ children }) => {
  const { isAuth, hasOrganization } = useAuth();
  const [getMe, { data }] = useMeLazyQuery();

  const { me, roles } = useMemo(() => {
    if (data != null && data.Me != null && data.Me.permission != null) {
      return {
        me: data.Me,
        roles: data.Me.permission.roles as Role[],
      };
    }
    return {};
  }, [data]);

  useEffect(() => {
    if (isAuth && hasOrganization) {
      getMe();
    }
  }, [getMe, hasOrganization, isAuth]);

  return (
    <MeContext.Provider value={{ me, roles }}>{children}</MeContext.Provider>
  );
};
