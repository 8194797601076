import styled from 'styled-components/macro';

export const ScOrganizationsPage = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px 0;
  position: relative;
  .organizations {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    & > div {
      flex-shrink: 0;
    }
  }
  .logout {
    position: fixed;
    right: 32px;
    bottom: 32px;
  }
`;

export const ScCreateLocationModal = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  max-width: 660px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding: 40px;
  form {
    width: 100%;
    text-align: center;
    .title-text {
      svg {
        margin-bottom: 16px;
      }
      h2 {
        margin-bottom: 8px;
      }
    }
    .input-div {
      width: 100%;
      text-align: left;
      margin-bottom: 16px;
    }
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      margin-top: 32px;

      & > div {
        width: 100%;
      }
      button {
        width: fit-content;
      }
    }
  }
`;

export const ScPrganization = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  width: 186px;
  height: 220px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colorPrimaryViolet};
  position: relative;
  cursor: pointer;
  transition: ease-in-out 0.2s;
  & > .initials {
    background-color: ${({ theme }) => theme.colorPrimaryViolet};
    width: 45px;
    height: 45px;
    border-radius: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 600px;
    font-size: 24px;
    color: ${({ theme }) => theme.colorWhite};
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    transition: ease-in-out 0.2s;
  }
  :hover {
    background-color: ${({ theme }) => theme.colorPrimaryViolet};
    color: ${({ theme }) => theme.colorWhite};
    & > .initials {
      background-color: ${({ theme }) => theme.colorWhite};
      color: ${({ theme }) => theme.colorPrimaryViolet};
    }
  }
  & > h3 {
    padding-bottom: 16px;
  }
`;
