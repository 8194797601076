import * as yup from 'yup';

export type CreateCheckoutFieldDto = {
  name: string;
  type: string;
  description?: string;
};

export const createCheckoutFieldSchema: yup.SchemaOf<CreateCheckoutFieldDto> =
  yup.object({
    name: yup.string().required(),
    description: yup.string(),
    type: yup.string().required(),
  });
