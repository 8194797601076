import { getSiteTheme } from 'hooks/getSiteTheme';
import { Theme } from './contracts';
import { importImages } from './utils';

const images = importImages(
  (require as any).context('./images', false, /\.(png|jpe?g|svg)$/),
  (require as any).context('!@svgr/webpack!./images', true, /\.svg$/)
);

export enum ColorDarkType {
  theme = 'rgb(24, 24, 27)',
  grey = 'rgb(39, 39, 42)',
  green = '#02d902',
  slateDark = 'bg-slate-400',
}

const { isLight } = getSiteTheme();

export const theme: Theme = {
  primaryFontFamily: '',
  primaryFontFamilyUrl:
    'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap',
  colorBlack: '#000000',
  colorWhite: isLight ? '#f9fafb' : ColorDarkType.theme, // background color
  colorBackground: isLight ? '#F0F0F0' : ColorDarkType.theme,
  colorPrimaryBlack: isLight ? '#2B2B34' : '#FFFFFF',
  colorPrimaryViolet: '#000000', //hover or main second color
  colorSecondaryGrayLight: isLight ? '#EBECF0' : 'grey', //#34eb3d //red
  colorSecondaryGrayDark: '#7C7C83',
  colorSecondaryGrayBackground: '#F4F4F5',
  colorSecondaryVioletLight: isLight ? '#F0F0F0' : ColorDarkType.grey, //
  colorSecondaryVioletDark: isLight ? '#000000' : '#ffffff', //active item //focus inputs
  colorErrorRedLight: '#FEF2F2',
  colorErrorRedDark: '#C13333',
  colorSuccessGreenLight: isLight ? '#F4FAF7' : ColorDarkType.grey,
  colorSuccessGreenDark: isLight ? '#3E935D' : ColorDarkType.green,
  colorText: '#FFFFFF',
  backgroundBlur: 'blur(3px)',
  images,
};
