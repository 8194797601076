import { Checkbox } from 'components/elements/inputs/Checkbox';
import { FC, useCallback } from 'react';

interface CheckboxFieldProps {
  id: string;
  mainValue: boolean;
  secondaryValue: boolean;
  sortOrder: number;
  setValue: (
    id: string,
    initial: boolean,
    secondaryValue: boolean,
    sortOrder: number
  ) => void;
  loadingUpdate: boolean;
}

const CheckboxField: FC<CheckboxFieldProps> = ({
  setValue,
  id,
  mainValue,
  secondaryValue,
  loadingUpdate,
  sortOrder,
}) => {
  const handleSwitch = useCallback(() => {
    setValue(id, mainValue, secondaryValue, sortOrder);
  }, [id, mainValue, secondaryValue, setValue, sortOrder]);

  return (
    <Checkbox
      checked={mainValue}
      onClick={handleSwitch}
      disabled={loadingUpdate}
    />
  );
};

export default CheckboxField;
