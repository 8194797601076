import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetClassTimeQueryVariables = Types.Exact<{
  activityId: Types.Scalars['String'];
}>;

export type GetClassTimeQuery = {
  __typename?: 'Query';
  ActivityById: {
    __typename?: 'ActivityEntity';
    id: string;
    activity_classes: Array<{
      __typename?: 'ActivityClassEntity';
      id: string;
      start: any;
      end: any;
    }>;
  };
};

export const GetClassTimeDocument = gql`
  query GetClassTime($activityId: String!) {
    ActivityById(id: $activityId) {
      id
      activity_classes {
        id
        start
        end
      }
    }
  }
`;

/**
 * __useGetClassTimeQuery__
 *
 * To run a query within a React component, call `useGetClassTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassTimeQuery({
 *   variables: {
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useGetClassTimeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassTimeQuery,
    GetClassTimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClassTimeQuery, GetClassTimeQueryVariables>(
    GetClassTimeDocument,
    options
  );
}
export function useGetClassTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassTimeQuery,
    GetClassTimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClassTimeQuery, GetClassTimeQueryVariables>(
    GetClassTimeDocument,
    options
  );
}
export type GetClassTimeQueryHookResult = ReturnType<
  typeof useGetClassTimeQuery
>;
export type GetClassTimeLazyQueryHookResult = ReturnType<
  typeof useGetClassTimeLazyQuery
>;
export type GetClassTimeQueryResult = Apollo.QueryResult<
  GetClassTimeQuery,
  GetClassTimeQueryVariables
>;
