import styled from 'styled-components/macro';

export const ScAttendeePage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const ScHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > svg {
    width: 16px;
    cursor: pointer;
    margin-right: 16px;
  }
`;

export const ScMainInformation = styled.div`
  padding: 24px;
  background: #ffffff;
  min-height: calc(100vh - 80.3px);
  border-radius: 8px 8px 0 0;
  flex-grow: 1;
  position: relative;
  .spinner-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ScBillingInformation = styled.div`
  padding: 24px;
  background: #ffffff;
  border-radius: 8px 8px 0 0;
  position: relative;
  min-width: 324px;
  .spinner-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  & > h3 {
    margin-bottom: 24px;
  }
  & > .all-info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    & > .info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
    }
    & > .underline {
      padding-bottom: 24px;
      margin-bottom: 8px;
      border-bottom: 1px solid ${({ theme }) => theme.colorSecondaryGrayLight};
    }
  }
`;

export const ScStatsBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colorSecondaryGrayLight};
  & > .main-info-block {
    padding: 20px;
    border: 1px solid ${({ theme }) => theme.colorSecondaryGrayLight};
    border-radius: 6px;
    width: 242px;
    & > h3 {
      margin-bottom: 20px;
    }
    & > h1 {
      margin-bottom: 8px;
    }
  }
`;

export const ScLastOrder = styled.div`
  & > h2 {
    margin-bottom: 24px;
  }
  & > .order-number {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
  }
  & > .order-info {
    display: flex;
    flex-direction: row;
    & > .avatar {
      width: 75px;
      height: 75px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colorPrimaryViolet};
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      & > p {
        font-size: 32px;
        font-weight: 600;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colorWhite};
      }
    }
    & > .activity-info {
      flex-grow: 1;
      & > h2 {
        margin-bottom: 8px;
      }
    }
  }
`;

export const ScOrderTable = styled.div`
  // height: calc(100vh - 312px);
`;
