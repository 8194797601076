import { FC } from 'react';
import { Edit2, X } from 'react-feather';

interface ActionButtonsProps {
  id: string;
  name: string;
  type: string;
  description?: string | null | undefined;
  sortOrder: number;
  handleUpdate: (
    id: string,
    name: string,
    type: string,
    sortOrder: number,
    description?: string | null | undefined
  ) => void;
  handleDeleteField: (deleteCheckoutFieldId: string) => void;
}

const ActionButtons: FC<ActionButtonsProps> = ({
  handleUpdate,

  id,
  name,
  type,
  description,
  sortOrder,
  handleDeleteField,
}) => {
  return (
    <div className="btns">
      <Edit2
        width={16}
        onClick={() => handleUpdate(id, name, type, sortOrder, description)}
      />
      <X width={18} onClick={() => handleDeleteField(id)} />
    </div>
  );
};

export default ActionButtons;
