import {
  ApolloClient,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { useCallback } from 'react';

import { Tokens } from '../tokens/provider';

export type GraphqlClientActions = {
  createApolloClient: () => ApolloClient<NormalizedCacheObject>;
};

type UseGraphqlClientActions = (props: {
  tokens?: Tokens;
}) => GraphqlClientActions;

const getAuthHeaders = (access_token?: string) => {
  if (!access_token) return null;

  return {
    Authorization: `Bearer ${access_token}`,
  };
};

export const useGraphqlClientActions: UseGraphqlClientActions = ({
  tokens,
}) => {
  const createApolloClient = useCallback(() => {
    const errorLink = onError(
      // ({ graphQLErrors, networkError, operation, forward }) => {
      ({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          // eslint-disable-next-line no-console
          console.error('GraphqL errors: ', graphQLErrors);
          for (const err of graphQLErrors) {
            switch (err.extensions.code) {
              case 'UNAUTHENTICATED': {
                // if (scope) {
                //   clearTokens(scope)
                // }
                // if (authToken && refreshToken) {
                // return promiseToObservable(onRefreshToken()).flatMap(() => {
                //   const oldHeaders = operation.getContext().headers;
                //   operation.setContext({
                //     headers: {
                //       ...oldHeaders,
                //       ...getAuthHeaders(),
                //     },
                //   });
                //   return forward(operation);
                // });
                // console.error(authToken, refreshToken);
                // return;
                // }
              }
            }
          }
        }
        if (networkError) {
          // eslint-disable-next-line no-console
          console.error(`[Network error]: ${networkError}`);
        }
      }
    );
    const httpLink = createUploadLink({
      uri: `${process.env.REACT_APP_SERVER_URL}/graphql`,
      headers: Object.fromEntries(
        [
          [
            'Authorization',
            getAuthHeaders(tokens?.access_token)?.Authorization,
          ],
        ].filter(([, value]) => value !== undefined)
      ),
    });
    return new ApolloClient({
      link: from([errorLink, httpLink]),
      cache: new InMemoryCache(),
      defaultOptions: {
        mutate: { errorPolicy: 'all' },
        query: { errorPolicy: 'all' },
        watchQuery: { errorPolicy: 'all' },
      },
    });
  }, [tokens?.access_token]);

  return {
    createApolloClient,
  };
};
