import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCheckoutFieldMutationVariables = Types.Exact<{
  input: Types.CreateCheckoutFieldsInput;
}>;

export type CreateCheckoutFieldMutation = {
  __typename?: 'Mutation';
  CreateCheckoutField: { __typename?: 'CheckoutFieldsEntity'; id: string };
};

export const CreateCheckoutFieldDocument = gql`
  mutation CreateCheckoutField($input: CreateCheckoutFieldsInput!) {
    CreateCheckoutField(input: $input) {
      id
    }
  }
`;
export type CreateCheckoutFieldMutationFn = Apollo.MutationFunction<
  CreateCheckoutFieldMutation,
  CreateCheckoutFieldMutationVariables
>;

/**
 * __useCreateCheckoutFieldMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutFieldMutation, { data, loading, error }] = useCreateCheckoutFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCheckoutFieldMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCheckoutFieldMutation,
    CreateCheckoutFieldMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCheckoutFieldMutation,
    CreateCheckoutFieldMutationVariables
  >(CreateCheckoutFieldDocument, options);
}
export type CreateCheckoutFieldMutationHookResult = ReturnType<
  typeof useCreateCheckoutFieldMutation
>;
export type CreateCheckoutFieldMutationResult =
  Apollo.MutationResult<CreateCheckoutFieldMutation>;
export type CreateCheckoutFieldMutationOptions = Apollo.BaseMutationOptions<
  CreateCheckoutFieldMutation,
  CreateCheckoutFieldMutationVariables
>;
