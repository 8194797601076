import { forwardRef, useCallback, useMemo, useState } from 'react';
import { Button } from 'components/elements/Button';
import { SpinnerInBox } from 'components/elements/Spinner';
import { TextSmall, TitleH2 } from 'components/styles/common';
import { useMoveAttendeeMutation } from 'graphql/mutations/orders/__generated__/moveAttendee.generated';
import { useGetAtivityClassesQuery } from 'graphql/queries/activities/__generated__/getAtivityClasses.generated';
import { successAlert } from 'helpers/alerts';
import { getFullDate } from 'helpers/dayjs/formats';
import { ColorsEnums } from 'helpers/enums/common';
import { SearchComponent, useSearch } from 'hooks/search/useSearch';
import { useModal } from 'hooks/useModal';
import { ScMoveAttendeeModal } from './styled';
import { OptionType } from 'components/elements/inputs/common';
import TeacherItem from '../TeacherItem';
import { getSiteTheme } from 'hooks/getSiteTheme';
import { Checkbox } from 'components/catalyst-elements/checkbox';

interface MoveAttendeeModalProps {
  orderId: string;
  activityId: string;
  classId: string;
  attendeeName: string;
  onClose: () => void;
  refetch: any;
  orderRefetch: any;
}

type ActivityClassType = {
  id: string;
  title: string;
  start: string;
  booked: number;
  availablespots: number;
  teachers: OptionType[];
};

export const MoveAttendeeModal = forwardRef<
  HTMLDivElement,
  MoveAttendeeModalProps
>((props, ref) => {
  const {
    orderId,
    activityId,
    classId,
    attendeeName,
    onClose,
    refetch,
    orderRefetch,
  } = props;

  const { data: activityClassesData, refetch: activityClassesRefetch } =
    useGetAtivityClassesQuery({
      variables: { activityByIdId: activityId },
    });

  const { closeOrderModal } = useModal();

  const [moveAttendee] = useMoveAttendeeMutation();

  const { searchValue, searchData, methods } = useSearch();

  const [selectedClassId, setSelectedClassId] = useState<string | null>(null);

  const activityClassess = useMemo(() => {
    if (activityClassesData != null) {
      const data = activityClassesData.ActivityById;
      return data.activity_classes.reduce((acc, item) => {
        if (item.id !== classId)
          acc.push({
            ...item,
            availablespots: data.spots - item.booked,

            teachers: item.timelines
              .map((item) => item.timelineTeachers)
              .flat()
              .map((item) => ({
                label: item?.teacher.name || '',
                value: item?.teacher.id || '',
                avatarUrl: item?.teacher.avatar?.link,
              })),
          });
        return acc;
      }, [] as ActivityClassType[]);
    }
  }, [activityClassesData, classId]);

  const activityClassessWithSearch = useMemo(() => {
    if (activityClassess != null) {
      return searchData(
        activityClassess,
        'title',
        searchValue
      ) as ActivityClassType[];
    }
  }, [activityClassess, searchData, searchValue]);

  const handleSelectClass = (id: string) => {
    setSelectedClassId((prev) => (prev === id ? null : id));
  };

  const handleMoveAttendee = useCallback(() => {
    if (selectedClassId != null) {
      moveAttendee({
        variables: {
          orderId,
          activityClassId: selectedClassId,
        },
      }).then(({ data, errors }) => {
        if (data != null && errors == null) {
          refetch[0]();
          refetch[1]();
          activityClassesRefetch();
          orderRefetch();
          onClose();
          closeOrderModal();
          successAlert('Attendee moved!');
        }
      });
    }
  }, [
    activityClassesRefetch,
    closeOrderModal,
    moveAttendee,
    onClose,
    orderId,
    orderRefetch,
    refetch,
    selectedClassId,
  ]);

  const { isLight } = getSiteTheme();

  return (
    <ScMoveAttendeeModal className="!p-[10px] sm:!p-[25px]" ref={ref}>
      <TitleH2 className="text-center">
        Select the class in which you want to attend {attendeeName}
      </TitleH2>
      <SearchComponent {...{ methods }} />
      <div className="mt-1 h-[450px] sm:h-[470px] flow-root overflow-auto overflow-x-scroll shadow ring-1 ring-black dark:ring-zinc-700 ring-opacity-5 rounded-lg">
        <div
          className={`inline-block min-w-full py-2 align-middle relative ${
            isLight ? 'bg-white' : 'bg-zinc-900'
          } `}
        ></div>
        <table
          className={`min-w-full border-separate border-spacing-0 ${
            isLight ? 'divide-gray-300' : 'divide-gray-500'
          }`}
        >
          <thead className={`${isLight ? 'bg-white' : 'bg-slate-800'}`}>
            <tr>
              <th
                className={` max-h-[50px] top-0 z-10 border-b border-gray-300 dark:border-zinc-600 bg-white dark:bg-zinc-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter table-cell ${
                  isLight ? 'text-gray-900' : 'text-slate-100'
                }`}
              >
                Title
              </th>
              <th
                className={` max-h-[50px] top-0 z-10 border-b border-gray-300 dark:border-zinc-600 bg-white dark:bg-zinc-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter table-cell ${
                  isLight ? 'text-gray-900' : 'text-slate-100'
                }`}
              >
                Start date
              </th>
              <th
                className={` max-h-[50px] top-0 z-10 border-b border-gray-300 dark:border-zinc-600 bg-white dark:bg-zinc-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter table-cell ${
                  isLight ? 'text-gray-900' : 'text-slate-100'
                }`}
              >
                Available spots
              </th>
              <th
                className={` max-h-[50px] top-0 z-10 border-b border-gray-300 dark:border-zinc-600 bg-white dark:bg-zinc-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter table-cell ${
                  isLight ? 'text-gray-900' : 'text-slate-100'
                }`}
              >
                Teacher
              </th>
            </tr>
          </thead>
          {activityClassessWithSearch != null ? (
            <tbody
              className={`divide-y  ${
                isLight
                  ? 'bg-white divide-gray-200'
                  : `bg bg-zinc-900 divide-gray-600`
              }`}
            >
              {activityClassessWithSearch.map(
                ({ id, title, start, availablespots, teachers }) => (
                  <tr
                    className="group"
                    key={id}
                    onClick={() => handleSelectClass(id)}
                  >
                    <td
                      className={`whitespace-nowrap px-3 py-4 text-sm table-cell border-b border-b-gray-200 dark:border-b-zinc-700 text-gray-500 dark:text-slate-300 first-of-type:font-bold group-last-of-type:border-none`}
                    >
                      <div className="class-title flex items-center gap-[5px]">
                        <Checkbox checked={id === selectedClassId} />
                        <p>{title}</p>
                      </div>
                    </td>
                    <td
                      className={`whitespace-nowrap px-3 py-4 text-sm table-cell border-b border-b-gray-200 dark:border-b-zinc-700 text-gray-500 dark:text-slate-300 first-of-type:font-bold group-last-of-type:border-none`}
                    >
                      {getFullDate(start)}
                    </td>
                    <td
                      className={`whitespace-nowrap px-3 py-4 text-sm table-cell border-b border-b-gray-200 dark:border-b-zinc-700 text-gray-500 dark:text-slate-300 first-of-type:font-bold group-last-of-type:border-none`}
                    >
                      {availablespots}
                    </td>
                    <td
                      className={`whitespace-nowrap px-3 py-4 text-sm table-cell border-b border-b-gray-200 dark:border-b-zinc-700 text-gray-500 dark:text-slate-300 first-of-type:font-bold group-last-of-type:border-none`}
                    >
                      {teachers.map((item) => (
                        <TeacherItem key={item.value} {...{ ...item }} />
                      ))}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          ) : (
            <SpinnerInBox />
          )}
        </table>
        {activityClassessWithSearch?.length === 0 && (
          <div className="empty">
            <TitleH2>No class</TitleH2>
            <TextSmall>Try another query</TextSmall>
          </div>
        )}
      </div>

      <div className="bottom">
        <Button color={ColorsEnums.white} onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleMoveAttendee}>Move</Button>
      </div>
    </ScMoveAttendeeModal>
  );
});
