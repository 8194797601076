import { FC, MutableRefObject, useMemo, useState } from 'react';
import { MoreVertical, Search, Trash2 } from 'react-feather';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Cell } from 'react-table';
import { TitleH2, TitleH4 } from 'components/styles/common';
import { ScContent } from '../styled';
import { ScActionsBlock, ScTags } from './styled';
import { Button } from 'components/elements/Button';
import { Modal } from 'components/elements/Modal';
import { useComponentVisible } from 'hooks/visibility';
import { theme } from 'theme';
import { SearchDto, searchSchema } from 'hooks/search/search';
import InputForm from 'components/elements/inputs';
import { useGetTagsQuery } from 'graphql/queries/tags/__generated__/getTags.generated';
import { CreateTagModal } from 'components/modules/CreateTagModal';
import { useDeleteTagMutation } from 'graphql/mutations/tags/__generated__/deleteTag.generated';
import { TagEntity } from 'types.generated';
import Table from 'components/elements/Table';
import { getSiteTheme } from 'hooks/getSiteTheme';

export const Tags = () => {
  const { data: dataTags, loading, refetch } = useGetTagsQuery();
  const [deleteTag] = useDeleteTagMutation();

  const methods = useForm<SearchDto>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    resolver: yupResolver(searchSchema),
    defaultValues: {
      searchValue: '',
    },
  });
  const { watch } = methods;

  const searchValue = watch('searchValue');

  const [isOpenCreateLocationModal, setOpenCreateLocationModal] =
    useState(false);
  const onCreateLocationModal = () =>
    setOpenCreateLocationModal((prev) => !prev);

  const tags = useMemo(() => {
    if (dataTags != null)
      return dataTags.CurrentOrganization.tags?.filter((item) => {
        const name = item.name.toLowerCase();

        const search =
          searchValue != null ? searchValue.toLowerCase() : searchValue;
        return name.indexOf(search) > -1;
      });
  }, [dataTags, searchValue]);

  const columns = useMemo(
    () => [
      {
        Header: 'Tag name',
        accessor: 'name',
      },

      {
        Header: '',
        accessor: 'actions',
        Cell: (props: Cell<TagEntity>) => {
          const { id, name } = props.row.original;
          return <TdActions {...{ id, name, deleteTag, refetch }} key={id} />;
        },
      },
    ],
    [deleteTag, refetch]
  );

  return (
    <ScContent>
      <ScTags>
        <div className="header">
          <TitleH2>Tags</TitleH2>
          <Button onClick={onCreateLocationModal}>Add tag</Button>
        </div>
        <div className="body">
          <FormProvider {...methods}>
            <form>
              <InputForm<SearchDto>
                id="searchValue"
                placeholder="Search"
                type="text"
                Icon={Search}
              />
            </form>
          </FormProvider>
          <div className="tag-table">
            <Table<TagEntity>
              {...{
                columns,
                data: tags as TagEntity[],
                emptyText: 'No tags',
                emptyDescription: 'You don’t have any tag yet.',
                isLoading: loading,
              }}
            />
          </div>
        </div>
      </ScTags>
      {isOpenCreateLocationModal && (
        <Modal onClose={onCreateLocationModal}>
          {(props) => (
            <CreateTagModal
              {...props}
              onClose={onCreateLocationModal}
              refetch={refetch}
            />
          )}
        </Modal>
      )}
    </ScContent>
  );
};

interface TdActionsProps {
  id: string;
  name: string;
  deleteTag: () => Promise<any>;
  refetch: any;
}

export const TdActions: FC<TdActionsProps> = ({
  id,
  name,
  deleteTag,
  refetch,
}) => {
  const { ref, openerRef, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleOpen = () => setIsComponentVisible((prev) => !prev);

  return (
    <div className="actions">
      <div ref={ref}>
        <MoreVertical width={16} onClick={handleOpen} />
      </div>

      {isComponentVisible && (
        <ActionsBlock
          openerRef={openerRef}
          id={id}
          deleteTag={deleteTag}
          refetch={refetch}
        />
      )}
    </div>
  );
};

interface ActionsBlockProps {
  openerRef: MutableRefObject<any>;
  id: string;
  deleteTag: any;
  refetch: any;
}

const ActionsBlock: FC<ActionsBlockProps> = ({
  openerRef,
  id,
  deleteTag,
  refetch,
}) => {
  const handleDelete = () => {
    deleteTag({ variables: { deleteTagId: id } }).then(() => refetch());
  };

  const { isLight } = getSiteTheme();

  return (
    <ScActionsBlock
      ref={openerRef}
      className={`${!isLight && '!bg-slate-900'}`}
    >
      <div className="item" onClick={handleDelete}>
        <Trash2 width={16} color={theme.colorErrorRedDark} />{' '}
        <TitleH4>Delete</TitleH4>
      </div>
    </ScActionsBlock>
  );
};
