import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LocationsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type LocationsQuery = {
  __typename?: 'Query';
  CurrentOrganization: {
    __typename?: 'OrganizationEntity';
    locations?: Array<{
      __typename?: 'LocationEntity';
      address: string;
      name: string;
      id: string;
    }> | null;
  };
};

export const LocationsDocument = gql`
  query Locations {
    CurrentOrganization {
      locations {
        address
        name
        id
      }
    }
  }
`;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<LocationsQuery, LocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LocationsQuery, LocationsQueryVariables>(
    LocationsDocument,
    options
  );
}
export function useLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LocationsQuery,
    LocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LocationsQuery, LocationsQueryVariables>(
    LocationsDocument,
    options
  );
}
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<
  typeof useLocationsLazyQuery
>;
export type LocationsQueryResult = Apollo.QueryResult<
  LocationsQuery,
  LocationsQueryVariables
>;
