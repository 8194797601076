import axios from 'lib/axios';

export type RegisterUserDto = {
  email: string;
  password: string;
  passwordConfirm?: string;
};
export type RegisterUserRespond = boolean;

export const registerUser = async (
  data: RegisterUserDto
): Promise<RegisterUserRespond> => {
  return await axios
    .post('/controller/auth/user/registration', data)
    .then(({ data }) => data);
};
