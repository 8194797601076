import styled from 'styled-components/macro';

export const ScSearchModal = styled.div`
  border-radius: 16px;
  width: 95%;
  max-width: 1000px;
  margin: 40px auto;
  min-height: 64px;
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
  & > h2 {
    margin-bottom: 16px;
  }
`;

export const ScSearchInput = styled.div`
  padding: 15px 19px;
  width: 100%;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;

  & > svg {
    margin-right: 12px;
  }

  & > input {
    border: none;
    outline: none;
    width: 100%;
    height: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #a1a1aa;
  }
`;

export const ScResultComponent = styled.div`
  height: 382px;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const ScState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > svg {
    margin-bottom: 16px;
  }
  & > h2 {
    margin-bottom: 8px;
  }
`;

export const ScDataState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 16px 16px;
  overflow: hidden;
  overflow-y: auto;
  & > .section {
    & > .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: ${({ theme }) => theme.colorPrimaryBlack};
      background-color: ${({ theme }) => theme.colorSecondaryGrayLight};
      padding: 12px 16px;
      width: 100%;
    }
    & > .item {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      padding: 12px 16px;
      transition: 0.1s ease-in-out;
      display: block;
      cursor: pointer;
    }
    & > .item:hover {
      background-color: ${({ theme }) => theme.colorSecondaryVioletDark};
      color: ${({ theme }) => theme.colorWhite};
    }
  }
`;
