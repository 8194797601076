import * as yup from 'yup';

export type AddAttendeeSchemaDto = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  country?: string;
  postcode?: string;
  activityClassId: string;
  activityId: string;
};

export const addAttendeeSchema: yup.SchemaOf<AddAttendeeSchemaDto> = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required(),
    phoneNumber: yup.string(),
    country: yup.string(),
    postcode: yup
      .string()
      .matches(/^[0-9]*$/, 'Must be only digits')
      .max(5, 'Must be exactly 5 digits'),
    activityClassId: yup.string().required(),
    activityId: yup.string().required(),
  })
  .required();
