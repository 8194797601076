import React from 'react';
import { ThemeProvider } from 'styled-components';
import { RecoilRoot } from 'recoil';
import { ApolloConsumer } from '@apollo/client';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ToastContainer } from 'react-toastify';
import { theme } from 'theme';
import { GlobalStyles } from 'components/styles/GlobalStyles';
import { ErrorBoundary } from 'components/modules/internal/ErrorBoundary';
import { InternalError } from 'components/modules/internal/InternalError';
import { Routes } from 'routes';
import { TokensProvider } from 'contexts/tokens/provider';
import { AuthProvider } from 'contexts/auth/provider';
import { GraphqlClientProvider } from 'contexts/graphqlClient/provider';
import { HttpClientProvider } from 'contexts/httpClient/provider';
import 'react-toastify/dist/ReactToastify.css';
import { ModalsContextProvider } from 'contexts/modals';
import { NotFoundBoundary } from 'react-navi';
import { NotFoundLayout } from 'components/modules/internal/NotFound';
import { SettingsProvider } from 'contexts/settings/settings';
import { ActivitiesProvider } from 'contexts/activities';
import { MeProvider } from 'contexts/me/provider';
import { UnsavedContextProvider } from 'contexts/unsaved';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <TokensProvider>
        <AuthProvider>
          <GraphqlClientProvider>
            <HttpClientProvider>
              <ApolloConsumer>
                {(client) => (
                  <RecoilRoot>
                    <UnsavedContextProvider>
                      <MeProvider>
                        <ThemeProvider theme={theme}>
                          <GlobalStyles />
                          <SettingsProvider>
                            <ModalsContextProvider>
                              <ActivitiesProvider>
                                <NotFoundBoundary render={NotFoundLayout}>
                                  <ErrorBoundary fallback={<InternalError />}>
                                    <Routes />
                                  </ErrorBoundary>
                                </NotFoundBoundary>
                              </ActivitiesProvider>
                              <ToastContainer />
                            </ModalsContextProvider>
                          </SettingsProvider>
                        </ThemeProvider>
                      </MeProvider>
                    </UnsavedContextProvider>
                  </RecoilRoot>
                )}
              </ApolloConsumer>
            </HttpClientProvider>
          </GraphqlClientProvider>
        </AuthProvider>
      </TokensProvider>
    </Elements>
  );
}

export default App;
