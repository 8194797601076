import styled from 'styled-components/macro';

export const ScSettings = styled.div`
  background-color: ${({ theme }) => theme.colorWhite};
  width: 100%;
  max-width: 1120px;
  height: 700px;
  margin: 0 auto;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  position: relative;
  & > .spinner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .test {
    position: fixed;
    top: 32px;
  }
`;

export const ScNavBar = styled.div`
  width: 224px;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colorBackground};
  padding: 24px;
  border-radius: 8px 0 0 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  & > h4 {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  & > .tabs {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export interface ScNavigationItemProps {
  active: boolean;
}

export const ScNavigationItem = styled.div<ScNavigationItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  & > svg {
    width: 20px;
    height: 20px;
  }
  :hover {
    color: ${({ theme }) => theme.colorSecondaryVioletDark};
  }
  color: ${({ theme, active }) =>
    active ? theme.colorSecondaryVioletDark : theme.colorPrimaryBlack};
`;

export const ScContent = styled.div`
  margin-top: 10px;
  overflow: hidden;
  max-width: calc(1120px - 224px);
  padding: 24px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  & > h2 {
    text-align: center;
  }
  & > .spinner-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
export const ScSettingsBody = styled.div`
  .settings-profile {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 109px;

    & > label {
      cursor: pointer;
      & > img {
        width: 109px;
        height: 109px;
        border-radius: 90px;
        object-fit: cover;
        object-position: center center;
      }
      & > p {
        margin-top: 8px;
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        color: ${({ theme }) => theme.colorSecondaryVioletDark};
      }
    }
    & > .delete {
      margin-top: 8px;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      color: ${({ theme }) => theme.colorPrimaryBlack};
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      cursor: pointer;
    }
    & > input {
      display: none;
    }
  }

  .bottom-btn {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    bottom: 24px;
    right: 24px;
  }
`;
