import _axios from 'axios';

export const getHeadersToken = () => ({
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
});

const axios = _axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/`,
  timeout: 25 * 1000,
});

export default axios;
