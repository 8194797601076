import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePaymentMethodMutationVariables = Types.Exact<{
  input: Types.UpdateOrganizationInput;
}>;

export type UpdatePaymentMethodMutation = {
  __typename?: 'Mutation';
  UpdateCurrentOrganization: {
    __typename?: 'OrganizationEntity';
    isStripeActive: boolean;
    isPayByInvoiceActive: boolean;
    id: string;
  };
};

export const UpdatePaymentMethodDocument = gql`
  mutation UpdatePaymentMethod($input: UpdateOrganizationInput!) {
    UpdateCurrentOrganization(input: $input) {
      isStripeActive
      isPayByInvoiceActive
      id
    }
  }
`;
export type UpdatePaymentMethodMutationFn = Apollo.MutationFunction<
  UpdatePaymentMethodMutation,
  UpdatePaymentMethodMutationVariables
>;

/**
 * __useUpdatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMethodMutation, { data, loading, error }] = useUpdatePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePaymentMethodMutation,
    UpdatePaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePaymentMethodMutation,
    UpdatePaymentMethodMutationVariables
  >(UpdatePaymentMethodDocument, options);
}
export type UpdatePaymentMethodMutationHookResult = ReturnType<
  typeof useUpdatePaymentMethodMutation
>;
export type UpdatePaymentMethodMutationResult =
  Apollo.MutationResult<UpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaymentMethodMutation,
  UpdatePaymentMethodMutationVariables
>;
