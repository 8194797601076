import { FC, useEffect } from 'react';

import axios, { getHeadersToken } from 'lib/axios';

import { useTokens } from '../tokens/context';

export const HttpClientProvider: FC = ({ children }) => {
  const { tokens } = useTokens();

  useEffect(() => {
    if (axios.defaults.headers !== null) {
      axios.defaults.headers.common = getHeadersToken();
    }
  }, [tokens]);

  return <>{children}</>;
};
