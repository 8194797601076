import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MoveAttendeeMutationVariables = Types.Exact<{
  orderId: Types.Scalars['String'];
  activityClassId: Types.Scalars['String'];
}>;

export type MoveAttendeeMutation = {
  __typename?: 'Mutation';
  MoveAttendee: { __typename?: 'OrderEntity'; id: string };
};

export const MoveAttendeeDocument = gql`
  mutation MoveAttendee($orderId: String!, $activityClassId: String!) {
    MoveAttendee(orderId: $orderId, activityClassId: $activityClassId) {
      id
    }
  }
`;
export type MoveAttendeeMutationFn = Apollo.MutationFunction<
  MoveAttendeeMutation,
  MoveAttendeeMutationVariables
>;

/**
 * __useMoveAttendeeMutation__
 *
 * To run a mutation, you first call `useMoveAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveAttendeeMutation, { data, loading, error }] = useMoveAttendeeMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      activityClassId: // value for 'activityClassId'
 *   },
 * });
 */
export function useMoveAttendeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveAttendeeMutation,
    MoveAttendeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MoveAttendeeMutation,
    MoveAttendeeMutationVariables
  >(MoveAttendeeDocument, options);
}
export type MoveAttendeeMutationHookResult = ReturnType<
  typeof useMoveAttendeeMutation
>;
export type MoveAttendeeMutationResult =
  Apollo.MutationResult<MoveAttendeeMutation>;
export type MoveAttendeeMutationOptions = Apollo.BaseMutationOptions<
  MoveAttendeeMutation,
  MoveAttendeeMutationVariables
>;
