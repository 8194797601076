import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteCheckoutFieldMutationVariables = Types.Exact<{
  deleteCheckoutFieldId: Types.Scalars['String'];
}>;

export type DeleteCheckoutFieldMutation = {
  __typename?: 'Mutation';
  DeleteCheckoutField: {
    __typename?: 'DeleteCheckoutFieldsRespond';
    id?: string | null;
  };
};

export const DeleteCheckoutFieldDocument = gql`
  mutation DeleteCheckoutField($deleteCheckoutFieldId: String!) {
    DeleteCheckoutField(id: $deleteCheckoutFieldId) {
      id
    }
  }
`;
export type DeleteCheckoutFieldMutationFn = Apollo.MutationFunction<
  DeleteCheckoutFieldMutation,
  DeleteCheckoutFieldMutationVariables
>;

/**
 * __useDeleteCheckoutFieldMutation__
 *
 * To run a mutation, you first call `useDeleteCheckoutFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCheckoutFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCheckoutFieldMutation, { data, loading, error }] = useDeleteCheckoutFieldMutation({
 *   variables: {
 *      deleteCheckoutFieldId: // value for 'deleteCheckoutFieldId'
 *   },
 * });
 */
export function useDeleteCheckoutFieldMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCheckoutFieldMutation,
    DeleteCheckoutFieldMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCheckoutFieldMutation,
    DeleteCheckoutFieldMutationVariables
  >(DeleteCheckoutFieldDocument, options);
}
export type DeleteCheckoutFieldMutationHookResult = ReturnType<
  typeof useDeleteCheckoutFieldMutation
>;
export type DeleteCheckoutFieldMutationResult =
  Apollo.MutationResult<DeleteCheckoutFieldMutation>;
export type DeleteCheckoutFieldMutationOptions = Apollo.BaseMutationOptions<
  DeleteCheckoutFieldMutation,
  DeleteCheckoutFieldMutationVariables
>;
