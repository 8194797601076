import { FC, useMemo } from 'react';
import { Role } from 'helpers/enums/common';
import { useMyRoleQuery } from 'graphql/queries/me/__generated__/myRole.generated';

interface ResponsiveTrainerUIPRops {
  validRoles: Role | Role[];
  Component: JSX.Element;
}

const ResponsiveTrainerUI: FC<ResponsiveTrainerUIPRops> = ({
  validRoles,
  Component,
}) => {
  const { data } = useMyRoleQuery();

  const valid = useMemo(() => {
    if (data != null && data.Me.permission != null) {
      const myRole = data.Me.permission.roles[0];
      const roles = Array.isArray(validRoles) ? validRoles : [validRoles];
      return roles[0] === Role.ALL ? true : roles.includes(myRole as Role);
    }
    return false;
  }, [data, validRoles]);

  if (valid) return Component;
  return <></>;
};

export default ResponsiveTrainerUI;
