import styled from 'styled-components/macro';

export const ScOrderModal = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  max-width: 1240px;
  margin: 40px auto;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 24px;
  position: relative;

  .spinner-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ScBillingInformation = styled.div`
  padding: 24px;
  background: #ffffff;
  height: calc(100vh - 80.3px);
  min-width: 354px;
  border-radius: 8px 8px 0 0;
  & > h3 {
    margin-bottom: 24px;
  }
  & > .all-info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    & > .info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
    }
    & > .underline {
      padding-bottom: 24px;
      margin-bottom: 8px;
      border-bottom: 1px solid ${({ theme }) => theme.colorSecondaryGrayLight};
    }
  }
`;

export const ScMainInfo = styled.div`
  border-right: 1px solid ${({ theme }) => theme.colorSecondaryGrayLight};
  padding-right: 24px;
  flex-grow: 1;
  & > .order-title {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 8px;
    align-items: center;
    & > .refund {
      background-color: ${({ theme }) => theme.colorErrorRedLight};
      color: ${({ theme }) => theme.colorErrorRedDark};
      padding: 4px 8px;
      border-radius: 12px;
    }
  }
  & > .action-btns {
    margin-top: 24px;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.colorSecondaryGrayLight};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
  }
  & > .last-order {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.colorSecondaryGrayLight};
    & > h2 {
      margin-bottom: 24px;
    }
    & > .order-number {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      margin-bottom: 16px;
    }
    & > .order-info {
      display: flex;
      flex-direction: row;
      & > .avatar {
        width: 75px;
        height: 75px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colorPrimaryViolet};
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        & > p {
          font-size: 32px;
          font-weight: 600;
          text-transform: uppercase;
          color: ${({ theme }) => theme.colorWhite};
        }
      }
      & > .activity-info {
        flex-grow: 1;
        & > h2 {
          margin-bottom: 8px;
        }
      }
    }
  }
  & > .more-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.colorSecondaryGrayLight};
    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    & > .status {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      & > .status-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        & > p {
          padding: 4px 8px;
          font-weight: 500;
          font-size: 12px;
          line-height: 13px;
          text-align: center;
          border-radius: 6px;
        }
        .successed {
          color: ${({ theme }) => theme.colorSuccessGreenDark};
          background-color: ${({ theme }) => theme.colorSuccessGreenLight};
        }
        .failed {
          color: ${({ theme }) => theme.colorErrorRedDark};
          background-color: ${({ theme }) => theme.colorErrorRedLight};
        }
        .processing {
          color: ${({ theme }) => theme.colorSecondaryGrayDark};
          background-color: ${({ theme }) => theme.colorSecondaryGrayLight};
        }
      }
    }
    & > .subtotal {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      justify-content: space-between;
    }
    & > .invoice {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      justify-content: space-between;
      margin-bottom: 16px;
      input {
        font-size: 16px;
        border: none;
        border-bottom: 1px solid ${({ theme }) => theme.colorSecondaryGrayLight};
        color: ${({ theme }) => theme.colorSecondaryGrayDark};
        text-align: right;
      }
    }

    & > .total {
      justify-content: space-between;
    }
  }
`;

export const ScComments = styled.div`
  & > h2 {
    padding-bottom: 24px;
  }
  form {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    position: relative;
    & > .desc-text {
      position: absolute;
      top: 100%;
      right: 0;
      font-weight: 400;
      font-size: 14px;
      color: ${({ theme }) => theme.colorSecondaryGrayDark};
    }
    & > .avatar {
      width: 40px;
      height: 40px;
      border-radius: 90px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) => theme.colorSecondaryVioletDark};
      color: #ffffff;
      flex-shrink: 0;
    }
    div:nth-of-type(2) {
      width: 100%;
    }
  }
  & > .comments {
    margin-top: 32px;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: calc(100vh - 708px);
    overflow: hidden;
    overflow-y: auto;
    padding-right: 8px;
  }
`;

export const ScCommentItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  & > .icon {
    width: 16px;
    height: 16px;
    border-radius: 90px;
    background-color: ${({ theme }) => theme.colorPrimaryViolet};
    flex-shrink: 0;
    /* align-self: center; */
    margin-top: 5px;
  }
  & > .user-name {
    padding: 4px 8px;
    text-align: center;
    border-radius: 6px;
    width: fit-content;
    color: ${({ theme }) => theme.colorSecondaryGrayDark};
    background-color: ${({ theme }) => theme.colorSecondaryGrayLight};
    flex-shrink: 0;
    margin-right: 8px;
  }
  & > .date {
    flex-shrink: 0;
    color: ${({ theme }) => theme.colorSecondaryGrayDark};
    font-size: 12px;
  }
  & > .text {
    width: 100%;
  }
`;

export const ScEmailLogItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0;
  gap: 8px;
  & > .status {
    padding: 4px 8px;
    text-align: center;
    border-radius: 6px;
    width: fit-content;
    color: ${({ theme }) => theme.colorSecondaryGrayDark};
    background-color: ${({ theme }) => theme.colorSecondaryGrayLight};
    flex-shrink: 0;
    margin-right: 8px;
    text-transform: capitalize;
  }
  .successes {
    color: ${({ theme }) => theme.colorSuccessGreenDark};
    background-color: ${({ theme }) => theme.colorSuccessGreenLight};
  }
  .failed {
    color: ${({ theme }) => theme.colorErrorRedDark};
    background-color: ${({ theme }) => theme.colorErrorRedLight};
  }
  .processing {
    color: ${({ theme }) => theme.colorSecondaryGrayDark};
    background-color: ${({ theme }) => theme.colorSecondaryGrayLight};
  }
  & > .date {
    flex-shrink: 0;
    color: ${({ theme }) => theme.colorSecondaryGrayDark};
    font-size: 12px;
  }
  & > .text {
    width: 100%;
  }
`;
