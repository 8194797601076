import { useCallback, useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';
import SmoothScroll from 'smooth-scroll';
import { scroll, SmoothScrollOptions } from 'helpers/scroll';

export interface UseInfiniteScrollConfig {
  bottomOffset?: number;
  throttleWait?: number;
}

export function useInfiniteScroll(
  config?: UseInfiniteScrollConfig
): [boolean, () => void, () => void] {
  const { bottomOffset = 100, throttleWait = 150 } = config || {};

  const [atTheBottom, setAtTheBottom] = useState(false);

  // eslint-disable-next-line
  const handleScroll = useCallback(
    throttle(() => {
      if (
        atTheBottom ||
        document.documentElement.offsetHeight -
          ((document.scrollingElement || document.documentElement).scrollTop +
            window.innerHeight) >
          bottomOffset
      )
        return;

      setAtTheBottom(true);
    }, throttleWait),
    [atTheBottom, throttleWait, bottomOffset]
  );

  useEffect(() => {
    if (!atTheBottom) {
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);

      // initial handle call if screen is bigger than initial content height
      handleScroll();

      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
      };
    }
  }, [handleScroll, atTheBottom]);

  const continueScroll = useCallback(() => {
    setAtTheBottom(false);
  }, []);

  return [atTheBottom, continueScroll, handleScroll];
}

export function usePreventScroll(element: HTMLElement) {
  useEffect(() => {
    element.style.overflow = 'hidden';

    return () => {
      element.style.overflow = 'unset';
    };
  }, [element.style]);
}

export function useSmoothScroll(options?: SmoothScrollOptions) {
  const ref = useRef<any>(null);

  const scrollInstance = useRef(new SmoothScroll());

  const scrollTo = useCallback(
    (localOptions?: SmoothScrollOptions) => {
      if (
        ref == null ||
        ref.current == null ||
        !(ref.current instanceof HTMLElement)
      )
        return;

      scroll.call(scrollInstance.current, ref.current, {
        ...options,
        ...localOptions,
      });
    },
    [options]
  );

  useEffect(
    () => () => {
      scrollInstance.current.cancelScroll();
      scrollInstance.current.destroy();
    },
    []
  );

  return [ref, scrollTo] as [typeof ref, typeof scrollTo];
}
