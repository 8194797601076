import styled from 'styled-components/macro';

export const ScOrganizationsPage = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px 0;
  position: relative;
  .organizations {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    & > div {
      flex-shrink: 0;
    }
  }
  .logout {
    position: fixed;
    right: 32px;
    bottom: 32px;
  }
`;

export const ScInviteTeacherModal = styled.div`
  border-radius: 8px;
  /* overflow: hidden; */
  width: 100%;
  max-width: 660px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  background-color: rgb(15 23 42);
  padding: 40px;
  form {
    width: 100%;
    text-align: center;
    .title-text {
      svg {
        margin-bottom: 16px;
      }
      h2 {
        margin-bottom: 8px;
      }
    }
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      margin-top: 32px;

      & > div {
        width: 100%;
      }
      button {
        width: fit-content;
      }
      .input-box {
        position: relative;
        & > .role-btn {
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-30%);
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }
`;

export const ScPrganization = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  width: 186px;
  height: 220px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colorPrimaryViolet};
  position: relative;
  cursor: pointer;
  transition: ease-in-out 0.2s;
  & > .initials {
    background-color: ${({ theme }) => theme.colorPrimaryViolet};
    width: 45px;
    height: 45px;
    border-radius: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 600px;
    font-size: 24px;
    color: ${({ theme }) => theme.colorWhite};
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    transition: ease-in-out 0.2s;
  }
  :hover {
    background-color: ${({ theme }) => theme.colorPrimaryViolet};
    color: ${({ theme }) => theme.colorWhite};
    & > .initials {
      background-color: ${({ theme }) => theme.colorWhite};
      color: ${({ theme }) => theme.colorPrimaryViolet};
    }
  }
  & > h3 {
    padding-bottom: 16px;
  }
`;

export const ScRoleOptions = styled.div`
  z-index: 1;
  width: 165px;
  padding: 14px 21px 14px 16px;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ebecf0;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  & > .item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;
