import { getSiteTheme } from 'hooks/getSiteTheme';
import { createGlobalStyle } from 'styled-components/macro';
import { Theme } from 'theme/contracts';

const { isLight } = getSiteTheme();

export const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
  body {
    background-color: ${({ theme }) => theme.colorBackground};
    font-family: ${({ theme }) => theme.primaryFontFamily};
    color:${({ theme }) => theme.colorPrimaryBlack};
  }

  p,a,h1,h2,h3,h4{
    text-decoration: none;
    color: inherit;
  }

  button{
    outline: none;
    border: none;
    background-color: unset;
  }

  input, button, textarea,select {
    &:focus {
      outline: none
    }
  }
  *{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }


input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

  .table-def {
    overflow-x: auto;
    width: 100%;
    font-size: 16px;
    border: 1px solid rgba(124, 124, 131, 0.2);
    border-radius: 8px;
    color: ${({ theme }) => theme.colorPrimaryBlack};
    & > table {
      width: 100%;
      text-align: left;
      border-collapse: collapse;
      white-space: nowrap;

      & > thead {
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px 0px 0px 0px;
        & > tr {
          & > th {
            padding: 16px 18px;
          }
        }
      }
      & > tbody {
        & > tr {
          cursor: pointer;
          & > td {
            padding: 13.5px 18px;
          }
        }
        & > tr:not(:last-child) {
          /* box-shadow: inset 0px -1px 0px #ebecf0; */
          border-bottom: 1px solid
            ${({ theme }) => theme.colorSecondaryGrayLight};
        }
      }
    }
    & > .empty {
      height: calc(100vh - 184.3px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }  

  /* REACT-TOGGLE */
  .react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 40px;
  height: 24px;
  padding: 0;
  border-radius: 100px;
  background-color: ${isLight ? '#EBECF0' : '#424242'};
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #424242;
}

.react-toggle--checked .react-toggle-track {
  background-color: black;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #grey;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 2px;
  left: 2.5px;
  width: 20px;
  height: 20px;
  /* border: 1px solid red; */
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 17px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  /* -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0; */
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  /* -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0; */
}

/* .react-responsive-modal-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
 
} */
.react-responsive-modal-modal {
  max-width: unset !important;
}
@media(max-width:815px){
  .react-responsive-modal-modal{
    width: calc(100% - 2.4rem);
  }
}
.input-label{
  &>p{
    color: ${({ theme }) => theme.colorSecondaryGrayDark};
  /* margin-bottom: 16px; */
  }
}

// input, textarea, select {
//   background-color: ${isLight ? 'white' : 'black'} !important;
// }

input, textarea, select{
 backgorund-color: rgba(1,1,1,0) !important;
}

// custom react-toastify

.Toastify__toast {
  border-radius: 8px;
  background-color: ${isLight ? 'white' : 'black'};
}

.Toastify__toast-body {
  align-items: flex-start;
}

.Toastify__close-button svg{
  fill: ${isLight ? 'black' : 'white'};
}

// .Toastify__toast--error {
//   border: 1px solid red;
// }

// .Toastify__toast--success {
//   border: 1px solid green !important;
// }

// .Toastify__toast--info {
//   border: 1px solid #00bfff !important;
// }

// .Toastify__toast--warning {
//   border: 1px solid #ffef73 !important;
// }

// Calendar

.fc .fc-button {
  background-color: black;
  border: none;
}

.fc .fc-button:hover {
  background-color: #3c3c3c;
}

.fc .fc-button:focus {
  box-shadow: #3c3c3c !important;
}

.fc .fc-button-primary:focus {
  box-shadow: none !important;
}

.fc .fc-button:active {
  background-color: #3c3c3c !important;
}

.fc .fc-button-active {
  background-color: #3c3c3c !important;
}

.fc-popover-header {
  background-color: ${
    isLight ? 'rgba(1, 1, 1, 1)' : 'rgb(24, 24, 27)'
  } !important;
  color: #fff;
}

.fc-day-today {
  background-color: rgb(1, 1, 1, 0) !important;
}

.fc-day-today .fc-daygrid-day-number {
  display: flex;
  justify-content:center;
  align-items:center;
  background-color: #4287f5;
  color: white;
  width: 30px;
  heigth: 30px;
  border-radius: 25px;
}

.fc-day-today .fc-col-header-cell-cushion {
  width: 100%;
  background-color: #4287f5;
  color: white;
  // border-radius: 10px;
}

.fc-daygrid-dot-event {
  // border: 1px solid rgba(182, 157, 235, 0.3);
  margin-bottom: 5px;
  background-color: ${
    isLight ? 'rgb(228, 228, 231)' : 'rgb(63, 63, 70)'
  }!important;
  color: white;
  padding: 0;
}

.fc-daygrid-dot-event:hover {
  background-color: ${
    isLight ? 'rgb(212, 212, 216)' : 'rgb(39, 39, 42)'
  }!important;
}

.fc-day-today .fc-daygrid-dot-event {
  background-color: ${
    isLight ? 'rgb(228, 228, 231)' : 'rgb(63, 63, 70)'
  }!important;
}

.fc-day-today .fc-daygrid-dot-event:hover {
  background-color: ${
    isLight ? 'rgb(212, 212, 216)' : 'rgba(39, 39, 42)'
  }!important;
}

.fc-timegrid-event {
  border: 1px solid rgb(100, 116, 139);
  background-color: ${
    isLight ? 'rgba(148, 163, 184, 1)' : 'rgb(63, 63, 70)'
  }!important;
}

.fc-timegrid-more-link {
  color: black;
}

.fc-theme-standard td, .fc-theme-standard th{
  border: 1px solid ${isLight ? '#ddd' : '#373737'} !important;
}

.fc-theme-standard .fc-list-day-cushion {
  background-color: ${!isLight && 'rgba(71, 85, 105, 1)'}  !important;
}

.fc-list-table .fc-event{
  cursor: pointer;
}

// .fc-list-table .fc-event:hover {
//  background-color: red !important;
//  color: black;
// }

.fc-event.fc-event-start.fc-event-end.fc-event-past.fc-list-event:hover {
  background-color: red !important;
}

.fc-event {
  box-shadow: none !important;
}

.fc-event-main {
  color: black !important;
  background-color: rgb(228, 228, 231) !important;
}

.fc-list-day.fc-day.fc-day-wed.fc-day-today .fc-list-day-cushion.fc-cell-shaded {
  background-color: #4287f5 !important;
  color: white !important;
}

.fc .fc-list-sticky .fc-list-day.fc-day-today .fc-list-day-cushion {
  background-color: #4287f5 !important;
  color: white
}

// .fc-list-event.fc-event-today {
//   background-color: #4287f5 !important;
//   color: white !important;
// }

// .fc-list-event.fc-event-today:hover {
//   background-color: #4287f5 !important;
//   color: black !important;
// }


.fc-direction-ltr .fc-timegrid-more-link {
  background-color: #ececec !important;
}

.fc-event-today {
  background-color: #f0f0f0 !important;
}

.fc-timeGridDay-view .fc-timegrid-slot {
  height: 3.1em;
}

.fc-timeGridWeek-view .fc-timegrid-slot {
  height: 4em;
}

.fc-time-grid:hover .fc-event:hover,
.fc-time-grid:hover .fc-bgevent:hover {
  z-index: 100 !important;
}

.fc .fc-popover {
  z-index: 100;
}

.fc-popover-body {
  background-color: ${isLight ? '#ffffff' : 'rgb(39, 39, 42)'}
}

.fc-h-event {
  background-color: #ffffff;
  border: none;
}

.fc-dayGridMonth-view .fc-day.fc-daygrid-day {
  height: 180px;
}

.fc-dayGridMonth-view .weekend {
  position: absolute !important;
  display: flex !important;
  justify-content: left !important;
  top: -33px;
}

.fc-dayGridMonth-view .fc-daygrid-day-number {
  z-index: 100;
}

.fc-dayGridMonth-view .fc-day-other .fc-daygrid-day-top {
  opacity: 1;
  color: rgb(149, 149, 149);
}

.fc .fc-toolbar.fc-header-toolbar {
  padding: 20px;
  margin-bottom: 0;
}

.fc .fc-list-event-dot {
  border: none;
}


.fc-listMonth-view .fc-scroller {
  overflow-x: scroll !important;
}

.fc-view-harness.fc-view-harness-active {
  background-color: ${isLight ? '#ffffff' : 'rgba(1, 1, 1, 0.3)'};
}

.fc-scrollgrid {
  border: 1px solid white !important;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .fc-header-toolbar{
    gap:10px;
  }

  .fc-toolbar-chunk {
    display: flex;
    flex-direction: column;
  }

  .fc-toolbar-chunk .fc-today-button{
    margin-top: 5px;
    margin-left: 0 !important;
  }
}

// description
.ql-toolbar.ql-snow {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;

}

.ql-editor{
 
}

`;
