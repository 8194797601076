import styled from 'styled-components/macro';
import { DragHandleProps } from './fields/DragHandle';

export const ScCheckout = styled.div`
  .dots {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    padding: 6.5px 0 0 0;
    & > div {
      display: flex;
      flex-direction: column;
      gap: 2px;
      & > div {
        width: 3px;
        height: 3px;
        border-radius: 90px;
        background-color: ${({ theme }) => theme.colorSecondaryGrayDark};
      }
    }
  }
  & > .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  & > .body {
    margin-top: 24px;
    margin-bottom: 16px;
    & > .table-def {
      height: 594px;
      position: relative;

      th {
        padding: 16px 18px;
        color: #71717a;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        font-weight: 500;
        text-transform: uppercase;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
      tr {
        cursor: default !important;
      }
      td:first-child {
        font-weight: bold;
      }
      td {
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
      }
    }
    .text {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      max-width: unset;
      p {
        max-width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .btns {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      svg {
        cursor: pointer;
      }
    }
  }
  & > .footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
    }
  }
`;

export const ScCreateFieldModal = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  max-width: 660px;
  margin: 40px auto;
  padding: 16px;

  .title {
    margin-bottom: 16px;
  }
  .button-btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
  }
  form {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 16px;
  }
`;

export const ScHandleWrapper = styled.div<DragHandleProps>`
  height: 24px;
  vertical-align: bottom;
  display: inline-block;
  margin-right: 0.5rem;
  svg {
    width: 100%;
    height: 100%;
  }
  cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'grab')};
`;
