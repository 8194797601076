import React from 'react';
import ReactDOM from 'react-dom';
import HelmetProvider from 'react-navi-helmet-async';
import App from './App';
import 'react-calendar/dist/Calendar.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'tippy.js/dist/tippy.css';
import './i18n';
import '../src/global.css';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
