import { useEffect, useMemo } from 'react';
import { TitleH2 } from 'components/styles/common';
import { ScContent } from '../styled';

import { SpinnerInBox } from 'components/elements/Spinner';
import { SetupPaymentOnStripe } from './SetupPaymentOnStripe';
import { useStripeAccountQuery } from 'graphql/queries/payment/__generated__/StripeAccount.generated';
import { useCreateStripeAccountMutation } from 'graphql/mutations/stipe/__generated__/createStripeAccount.generated';
import { ScPaymentLoading } from './styled';
import { useGetPaymentMethodsInfoQuery } from 'graphql/queries/payment/__generated__/getPaymentMethodsInfo.generated';
import { SetupPaumentOnPayByInvoice } from './SetupPaumentOnPayByInvoice';

export const Payment = () => {
  const {
    data: dataQuery,
    error: accError,
    loading: accLoading,
    refetch,
  } = useStripeAccountQuery();

  const [
    createStripeAccount,
    { loading: loadingMutation, error: errorMutation },
  ] = useCreateStripeAccountMutation();

  const {
    data: paymentMethodsInfo,
    loading: loadingGetPaymentMethodsInfo,
    refetch: refetchGetPaymentMethodsInfo,
  } = useGetPaymentMethodsInfoQuery();

  const account = useMemo(
    () => (dataQuery != null ? dataQuery.StripeAccount : null),
    [dataQuery]
  );

  useEffect(() => {
    if (dataQuery == null && !accLoading) {
      createStripeAccount().then(({ data }) => {
        if (data != null) refetch();
      });
    }
  }, [accLoading, createStripeAccount, dataQuery, refetch]);

  if (
    accLoading ||
    loadingMutation ||
    loadingGetPaymentMethodsInfo ||
    paymentMethodsInfo == null
  ) {
    return (
      <ScPaymentLoading>
        <SpinnerInBox size={64} />
      </ScPaymentLoading>
    );
  }

  if (accError) {
    return (
      <ScContent>
        <TitleH2>{accError.message}</TitleH2>
      </ScContent>
    );
  }

  if (errorMutation) {
    return (
      <ScContent>
        <TitleH2>{errorMutation.message}</TitleH2>
      </ScContent>
    );
  }

  return (
    <ScContent>
      <TitleH2>Payment</TitleH2>
      <SetupPaymentOnStripe
        id={account?.id}
        chargesEnabled={account?.charges_enabled}
        loadingGetPaymentMethodsInfo={loadingGetPaymentMethodsInfo}
        refetch={refetchGetPaymentMethodsInfo}
        paymentMethodsInfo={paymentMethodsInfo}
      />
      <SetupPaumentOnPayByInvoice
        paymentMethodsInfo={paymentMethodsInfo}
        refetch={refetchGetPaymentMethodsInfo}
        loadingGetPaymentMethodsInfo={loadingGetPaymentMethodsInfo}
      />
      {/* <TaxRate /> */}
    </ScContent>
  );
};
