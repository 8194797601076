import React, { useCallback, useEffect, useMemo } from 'react';
import { Text, TitleH2 } from 'components/styles/common';
import { ScIntegrations } from './styled';
import { ScContent } from '../styled';
import shopify_logo from 'components/assets/shopify_logo.png';
import { Button } from 'components/elements/Button';
import { IntegrationPlatform } from 'helpers/enums/common';
import { Spinner } from 'components/elements/Spinner';
import { useCurrentOrganizationQuery } from 'graphql/queries/organizations/__generated__/getCurrentOrganization.generated';
import InputForm from '../../../elements/inputs';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useUpdateCurrentOrganizationSettingsMutation } from '../../../../graphql/mutations/settings/__generated__/updateOrganizationSettings.generated';
import { errorAlert, successAlert } from '../../../../helpers/alerts';
import { UpdateVendorDto, updateVendorSchema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';

export const Integrations = () => {
  const [update, { loading: updateLoading }] =
    useUpdateCurrentOrganizationSettingsMutation();
  const { data, loading } = useCurrentOrganizationQuery();

  const info = useMemo(
    () => data?.CurrentOrganization,
    [data?.CurrentOrganization]
  );

  const platforms = useMemo(
    () => [
      {
        id: IntegrationPlatform.SHOPIFY,
        logo: shopify_logo,
        title: 'Shopify',
        description: `Nam suscipit cursus orci eget commodo. Sed ullamcorper vitae turpis eu volutpat. Vestibulum leo libero, vestibulum et sagittis ut, ultricies ut nisl.`,
        disabled: false,
      },
    ],
    []
  );

  const methods = useForm<UpdateVendorDto>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    resolver: yupResolver(updateVendorSchema),
  });
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (info != null) {
      reset({
        vendor: info.vendor != null ? info.vendor : '',
      });
    }
  }, [info, reset]);

  const setup = useCallback(() => {
    if (info?.shopifyShopUrl) window.location.href = info.shopifyShopUrl;
  }, [info]);

  const onSubmit: SubmitHandler<UpdateVendorDto> = async ({ vendor }) => {
    update({
      variables: {
        input: {
          vendor,
        },
      },
    }).then(({ data, errors }) => {
      if (data != null) {
        successAlert('Vendor updated!');
      }
      if (errors != null) {
        errorAlert('Bad data input');
      }
    });
  };

  return (
    <ScContent>
      <ScIntegrations>
        <div className="header">
          <TitleH2>Integrations</TitleH2>
        </div>
        <div className="body">
          {loading && info == null ? (
            <Spinner size={54} />
          ) : (
            platforms.map((item) => (
              <div className="item" key={item.id}>
                <div className="top">
                  <div className="left">
                    <img src={item.logo} alt="" />
                    <TitleH2>{item.title}</TitleH2>
                  </div>
                  <div className="right">
                    {!info?.shopifyAccessToken ? (
                      <Button disabled={!info?.shopifyShopUrl} onClick={setup}>
                        Connect
                      </Button>
                    ) : (
                      <p className="status">Account connected</p>
                    )}
                  </div>
                </div>
                <FormProvider {...methods}>
                  <form className="top" onSubmit={handleSubmit(onSubmit)}>
                    <div className="left">
                      <b>Vendor:</b>
                      <InputForm<UpdateVendorDto> id="vendor" name="vendor" />
                    </div>
                    <div className="right">
                      <Button isLoading={updateLoading}>Save</Button>
                    </div>
                  </form>
                </FormProvider>
                <div className="bottom">
                  {info?.shopifyShopName && (
                    <p className="shop-name">
                      <b>Shop name:</b> {info.shopifyShopName}
                    </p>
                  )}
                  <br />
                  <Text>{item.description}</Text>
                </div>
              </div>
            ))
          )}
        </div>
      </ScIntegrations>
    </ScContent>
  );
};
