import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendSuccessBookingEmailMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type SendSuccessBookingEmailMutation = {
  __typename?: 'Mutation';
  SendSuccessBookingEmail: { __typename?: 'OrderEntity'; id: string };
};

export const SendSuccessBookingEmailDocument = gql`
  mutation SendSuccessBookingEmail($id: String!) {
    SendSuccessBookingEmail(id: $id) {
      id
    }
  }
`;
export type SendSuccessBookingEmailMutationFn = Apollo.MutationFunction<
  SendSuccessBookingEmailMutation,
  SendSuccessBookingEmailMutationVariables
>;

/**
 * __useSendSuccessBookingEmailMutation__
 *
 * To run a mutation, you first call `useSendSuccessBookingEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSuccessBookingEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSuccessBookingEmailMutation, { data, loading, error }] = useSendSuccessBookingEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendSuccessBookingEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendSuccessBookingEmailMutation,
    SendSuccessBookingEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendSuccessBookingEmailMutation,
    SendSuccessBookingEmailMutationVariables
  >(SendSuccessBookingEmailDocument, options);
}
export type SendSuccessBookingEmailMutationHookResult = ReturnType<
  typeof useSendSuccessBookingEmailMutation
>;
export type SendSuccessBookingEmailMutationResult =
  Apollo.MutationResult<SendSuccessBookingEmailMutation>;
export type SendSuccessBookingEmailMutationOptions = Apollo.BaseMutationOptions<
  SendSuccessBookingEmailMutation,
  SendSuccessBookingEmailMutationVariables
>;
