import i18next, { i18n, LanguageDetectorAsyncModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import { languages } from 'languages';

export const availableLng = Object.keys(languages).reduce((acc, keyLoc) => {
  return acc.concat(languages[keyLoc].map((locale) => locale.locale));
}, [] as string[]);

export const fallbackLng = 'en';

const lookupItemName = 'lng';

let i18nInstance: i18n;

const I18nInstanceSetter = {
  type: '3rdParty' as const,

  init(instance: i18n) {
    i18nInstance = instance;
  },
};

export { i18nInstance };

const CustomLanguageDetector: LanguageDetectorAsyncModule = {
  type: 'languageDetector' as const,
  async: true,
  init: function () {},
  detect: function (callback: (lng: string) => void): void {
    const languageFromLocalStorage = localStorage.getItem(lookupItemName);

    if (
      languageFromLocalStorage != null &&
      languageFromLocalStorage !== '' &&
      availableLng.find((l) => l === languageFromLocalStorage) != null
    ) {
      return callback(languageFromLocalStorage);
    }

    const domain =
      process.env.NODE_ENV === 'development'
        ? ''
        : process.env.REACT_APP_API_URL;

    fetch(`${domain}/api/current_ip`)
      .then((res) => res.json())
      .then((data) => {
        const { country_code } = data as {
          ip: string;
          country_code: string;
          region_code: string;
        };

        return callback(languages[country_code][0].locale);
      })
      .catch(() => {
        return callback(fallbackLng);
      });
  },
  cacheUserLanguage: function (lng: string) {
    localStorage.setItem(lookupItemName, lng);
  },
};

export default i18next
  .use(CustomLanguageDetector)
  .use(XHR)
  .use(I18nInstanceSetter)
  .use(initReactI18next)
  .init({
    whitelist: availableLng,
    fallbackLng: fallbackLng,
    backend: {
      loadPath: `${
        process.env.NODE_ENV !== 'development' && process.env.PUBLIC_URL
          ? process.env.PUBLIC_URL
          : ''
      }/locales/{{lng}}/{{ns}}.json`,
    },
    detection: {
      order: ['localStorage'],
      caches: ['localStorage'],
      lookupLocalStorage: lookupItemName,
    },
    ns: ['common'],
    defaultNS: 'common',
    debug: process.env.REACT_APP_ENV !== 'production',
    react: { wait: true, useSuspense: false },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
