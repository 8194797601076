import React, { Suspense, useContext, useEffect, useState } from 'react';
import { compose, lazy, mount } from 'navi';
import { Router, View } from 'react-navi';
import { RouterLayout } from 'components/layouts/RouterLayout/RouterLayout';
import { AuthContextPayload, useAuth } from 'contexts/auth/context';
import { Spinner } from 'components/elements/Spinner';
import {
  MeQuery,
  useMeLazyQuery,
} from 'graphql/queries/me/__generated__/me.generated';
import { TokensContext } from 'contexts/tokens/context';

export const routes = compose(
  mount({
    '*': lazy(() => import('pages/entrypoint')),
  })
);

export type RouterContext = Pick<
  AuthContextPayload,
  'hasOrganization' | 'isAuth'
> & {
  finished?: boolean;
  confirmed?: boolean;
  me?: MeQuery['Me'];
};

export const Routes = () => {
  const [getMe, { data: me, error, loading }] = useMeLazyQuery();
  const { isAuth, hasOrganization } = useAuth();
  const { globalLoading } = useContext(TokensContext);
  const [user, setUser] = useState<RouterContext | null>(null);

  useEffect(() => {
    setUser((prev) => ({ ...prev, isAuth, hasOrganization }));
  }, [hasOrganization, isAuth]);

  useEffect(() => {
    if (user != null) getMe();
  }, [user, getMe]);

  useEffect(() => {
    // if (me != null && me.Me.permission != null)
    if (me != null)
      setUser((prev) => ({
        ...prev,
        finished: me.Me.finished,
        confirmed: me.Me.confirmed,
        me: me.Me,
      }));
  }, [me]);

  useEffect(() => {
    if (error != null) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('token_type');
    }
  }, [error]);

  if (user != null && !loading && !globalLoading)
    return (
      <Router routes={routes} context={{ ...user }}>
        <RouterLayout>
          <Suspense fallback={null}>
            <View />
          </Suspense>
        </RouterLayout>
      </Router>
    );
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spinner size={124} />
    </div>
  );
};
