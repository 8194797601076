import styled from 'styled-components';

export const ScIntegrations = styled.div`
  & > .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  & > .body {
    margin-top: 24px;
    height: 554px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    & > .spinner-div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    & > .item {
      display: flex;
      flex-direction: column;
      gap: 16px;
      & > .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        & > .left {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          & > img {
            height: 38px;
          }
        }
      }
      & > .bottom {
        max-width: 524px;
        & > .shop-name {
          opacity: 1;
        }
        & > p {
          opacity: 0.5;
        }
      }
    }
    & > .item:not(:last-child) {
      padding-bottom: 16px;
      border-bottom: 1px solid ${({ theme }) => theme.colorSecondaryGrayLight};
    }
    & > .item:first-child {
      padding-top: 16px;
      /* border-top: 1px solid ${({ theme }) =>
        theme.colorSecondaryGrayLight}; */
    }
  }
  .status {
    padding: 4px 8px;
    text-align: center;
    border-radius: 6px;
    width: fit-content;
    color: ${({ theme }) => theme.colorSuccessGreenDark};
    background-color: ${({ theme }) => theme.colorSuccessGreenLight};
  }
`;
