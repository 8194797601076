import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/outline';
import { getSiteTheme } from 'hooks/getSiteTheme';
import { FC } from 'react';
import { toast } from 'react-toastify';

interface IProps {
  message: string | JSX.Element;
  alert: string;
}

const { isLight } = getSiteTheme();

const HandlerMessage: FC<IProps> = ({ message, alert }) => {
  return (
    <div className={`flex flex-col w-full h-full ${!isLight && 'dark'}`}>
      <p className="text-black dark:text-white">{alert}</p>
      <p className="text-black dark:text-white">{message}</p>
    </div>
  );
};

export const warningAlert = (message: string | JSX.Element) =>
  toast.warn(<HandlerMessage message={message} alert="Warning" />, {
    position: 'top-right',
    closeOnClick: true,
    theme: 'light',
    autoClose: 3000,
    progress: 0,
    hideProgressBar: true,
    icon: <InformationCircleIcon className="w-6 h-6 text-yellow-400" />,
    // autoClose: true,
  });

export const infoAlert = (message: string | JSX.Element) =>
  toast.info(<HandlerMessage message={message} alert="Info" />, {
    position: 'top-right',
    closeOnClick: true,
    theme: 'light',
    autoClose: 3000,
    progress: 0,
    hideProgressBar: true,
    icon: <InformationCircleIcon className="w-6 h-6 text-blue-400" />,
  });

export const successAlert = (message: string | JSX.Element) =>
  toast.success(<HandlerMessage message={message} alert="Success" />, {
    position: 'top-right',
    closeOnClick: true,
    theme: 'light',
    autoClose: 3000,
    progress: 0,
    hideProgressBar: true,
    icon: <CheckCircleIcon className="w-6 h-6 text-green-400" />,
  });

export const errorAlert = (message: string | JSX.Element) =>
  toast.error(<HandlerMessage message={message} alert="Error" />, {
    position: 'top-right',
    closeOnClick: true,
    theme: 'light',
    autoClose: 3000,
    progress: 0,
    hideProgressBar: true,
    icon: <XCircleIcon className="w-6 h-6 text-red-400" />,
  });
