import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { Search } from 'react-feather';
import { Spinner } from 'components/elements/Spinner';
import { useTheme } from 'hooks/theme';
import { ScSearchInput } from './styled';
import { getSiteTheme } from 'hooks/getSiteTheme';

interface SearchInputProps {
  searchValue: string;
  handleChangeSearchValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  searchInputRef: MutableRefObject<HTMLInputElement | null>;
}

const SearchInput: FC<SearchInputProps> = ({
  searchValue,
  handleChangeSearchValue,
  loading,
  searchInputRef,
}) => {
  const { colorSecondaryGrayDark, colorSecondaryVioletDark } = useTheme();

  const timerSpinnerRef = useRef<NodeJS.Timeout | null>(null);

  const [iconColor, setIconColor] = useState(colorSecondaryGrayDark);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (searchValue !== '') {
      setShowSpinner(true);
      clearTimeout(timerSpinnerRef.current as NodeJS.Timeout);
      timerSpinnerRef.current = setTimeout(() => setShowSpinner(false), 1000);
    } else {
      setShowSpinner(false);
    }
  }, [searchValue]);

  const { isLight } = getSiteTheme();

  return (
    <ScSearchInput
      style={{
        boxShadow: isLight
          ? '0px 1px 0px rgba(0,0,0,0.1)'
          : '0px 1px 0px rgba(0,0,0,1)',
      }}
    >
      <Search color={iconColor} size={18} />
      <input
        className="pl-[15px] rounded-md bg-[rgb(1,1,1,0)]"
        type="text"
        placeholder="Search..."
        onChange={handleChangeSearchValue}
        onFocus={() => setIconColor(colorSecondaryVioletDark)}
        onBlur={() => setIconColor(colorSecondaryGrayDark)}
        ref={searchInputRef}
      />
      {(showSpinner || loading) && <Spinner size={18} />}
    </ScSearchInput>
  );
};

export default SearchInput;
