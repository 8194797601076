import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateStripeAccountMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type CreateStripeAccountMutation = {
  __typename?: 'Mutation';
  CreateStripeAccount: {
    __typename?: 'OrganizationEntity';
    id: string;
    createdDate?: any | null;
    updatedDate?: any | null;
    deletedDate?: any | null;
    name: string;
    registrationNumber?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    stripeAccountId?: string | null;
    postcode?: string | null;
    country?: string | null;
    city?: string | null;
    address?: string | null;
    description?: string | null;
    isStripeActive: boolean;
    isPayByInvoiceActive: boolean;
  };
};

export const CreateStripeAccountDocument = gql`
  mutation CreateStripeAccount {
    CreateStripeAccount {
      id
      createdDate
      updatedDate
      deletedDate
      name
      registrationNumber
      email
      phoneNumber
      stripeAccountId
      postcode
      country
      city
      address
      description
      isStripeActive
      isPayByInvoiceActive
    }
  }
`;
export type CreateStripeAccountMutationFn = Apollo.MutationFunction<
  CreateStripeAccountMutation,
  CreateStripeAccountMutationVariables
>;

/**
 * __useCreateStripeAccountMutation__
 *
 * To run a mutation, you first call `useCreateStripeAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeAccountMutation, { data, loading, error }] = useCreateStripeAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateStripeAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStripeAccountMutation,
    CreateStripeAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStripeAccountMutation,
    CreateStripeAccountMutationVariables
  >(CreateStripeAccountDocument, options);
}
export type CreateStripeAccountMutationHookResult = ReturnType<
  typeof useCreateStripeAccountMutation
>;
export type CreateStripeAccountMutationResult =
  Apollo.MutationResult<CreateStripeAccountMutation>;
export type CreateStripeAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateStripeAccountMutation,
  CreateStripeAccountMutationVariables
>;
