import { createContext } from 'react';
import { MeQuery } from 'graphql/queries/me/__generated__/me.generated';
import { Role } from 'helpers/enums/common';

export type MeContextData = {
  me?: MeQuery['Me'];
  roles?: Role[];
};

export const MeContext = createContext<MeContextData>(null as any);
