import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Cell } from 'react-table';
import { Button } from 'components/elements/Button';
import { TitleH2 } from 'components/styles/common';
import { ScContent } from '../styled';
import { ScCheckout } from './styled';
import { useLocalModal } from 'hooks/useLocalModal';
import { Modal } from 'components/elements/Modal';
import CreateFieldModal from './CreateFieldModal';
import { CreateCheckoutFieldDto, createCheckoutFieldSchema } from './schema';
import { useGetCheckoutFieldsQuery } from 'graphql/queries/chechoutFields/__generated__/getCheckoutFields.generated';
import { useUpdateCheckoutFieldMutation } from 'graphql/mutations/settings/__generated__/updateCheckoutField.generated';
import { CheckoutFieldsEntity } from 'types.generated';
import CheckboxField from './fields/CheckboxField';
import ActionButtons from './fields/ActionButtons';
import { useDeleteCheckoutFieldMutation } from 'graphql/mutations/settings/__generated__/deleteCheckoutField.generated';
//dnd
import TableDnD from './TableDnD';
import { useUpdateCheckoutSortOrdersMutation } from 'graphql/mutations/settings/__generated__/updateSortOrederCheckouts.generated';

interface te {
  activeId: any;
  setActiveId: any;
}

export const Checkout: FC<te> = ({ activeId, setActiveId }) => {
  const { data: dataCheckoutFields, refetch } = useGetCheckoutFieldsQuery();
  const [update, { loading: loadingUpdate }] = useUpdateCheckoutFieldMutation();
  const [deleteField] = useDeleteCheckoutFieldMutation();
  const [updateOrder] = useUpdateCheckoutSortOrdersMutation();

  const { isOpenModal, handleSwitchModal } = useLocalModal();

  const [fieldInfo, setFieldInfo] = useState<null | {
    id: string;
    sortOrder: number;
  }>(null);

  const checkoutFields = useMemo(() => {
    if (dataCheckoutFields != null) {
      const items = dataCheckoutFields.CurrentOrganization.checkoutFields;

      if (items != null && items.length > 0)
        return [...items].sort((a: any, b: any) => {
          //@ts-ignore
          return new Date(a.createdDate) - new Date(b.createdDate);
        });
      else return [];
    }
  }, [dataCheckoutFields]);

  const methods = useForm<CreateCheckoutFieldDto>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    resolver: yupResolver(createCheckoutFieldSchema),
    defaultValues: {
      type: 'Text',
    },
  });

  const { reset } = methods;

  const handleOpen = () => {
    setFieldInfo(null);
    handleSwitchModal();
  };

  const handleUpdate = useCallback(
    (
      id: string,
      name: string,
      type: string,
      sortOrder: number,
      description?: string | null
    ) => {
      setFieldInfo({ id, sortOrder });
      reset({
        name,
        description: description || '',
        type,
      });
      handleSwitchModal();
    },
    [handleSwitchModal, reset]
  );

  const setRequire = useCallback(
    (id: string, initial: boolean, enable: boolean, sortOrder: number) => {
      update({
        variables: { input: { id, required: !initial, enable, sortOrder } },
      }).then(() => {
        refetch();
      });
    },
    [refetch, update]
  );

  const setActive = useCallback(
    (id: string, initial: boolean, required: boolean, sortOrder: number) => {
      update({
        variables: { input: { id, enable: !initial, required, sortOrder } },
      }).then(() => {
        refetch();
      });
    },
    [refetch, update]
  );

  const setSortOrder = useCallback(
    (fieldIds: string[]) => {
      updateOrder({ variables: { fieldIds } });
    },
    [updateOrder]
  );

  const handleDeleteField = useCallback(
    (deleteCheckoutFieldId: string) => {
      deleteField({ variables: { deleteCheckoutFieldId } }).then(() => {
        refetch();
      });
    },
    [deleteField, refetch]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'name',
      },
      {
        Header: 'Help text',
        accessor: 'description',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Required',
        accessor: 'required',
        Cell: (props: Cell<CheckoutFieldsEntity>) => {
          const { id, required, enable, sortOrder } = props.row.original;
          return (
            <CheckboxField
              id={id}
              mainValue={required}
              secondaryValue={enable}
              setValue={setRequire}
              loadingUpdate={loadingUpdate}
              sortOrder={sortOrder}
            />
          );
        },
      },
      {
        Header: 'Active',
        accessor: 'enable',
        Cell: (props: Cell<CheckoutFieldsEntity>) => {
          const { id, required, enable, sortOrder } = props.row.original;
          return (
            <CheckboxField
              id={id}
              mainValue={enable}
              secondaryValue={required}
              setValue={setActive}
              loadingUpdate={loadingUpdate}
              sortOrder={sortOrder}
            />
          );
        },
      },
      {
        Header: 'Options',
        accessor: 'actions',
        Cell: (props: Cell<CheckoutFieldsEntity>) => {
          const { id, name, description, sortOrder, type } = props.row.original;
          return (
            <ActionButtons
              {...{
                id,
                name,
                description,
                type,
                handleUpdate,
                sortOrder,
                handleDeleteField,
              }}
            />
          );
        },
      },
    ],
    [handleDeleteField, handleUpdate, loadingUpdate, setActive, setRequire]
  );

  const [data, setData] = useState<null | CheckoutFieldsEntity[]>(null);

  useEffect(() => {
    if (data != null) {
      setSortOrder(data.map(({ id }) => id));
    }
  }, [data, setSortOrder]);

  useEffect(() => {
    if (checkoutFields != null)
      setData(
        checkoutFields.sort((a, b) =>
          a.sortOrder > b.sortOrder ? 1 : b.sortOrder > a.sortOrder ? -1 : 0
        ) as CheckoutFieldsEntity[]
      );
  }, [checkoutFields]);

  return (
    <ScContent>
      <ScCheckout>
        <div className="header">
          <TitleH2>Checkout</TitleH2>
          <Button onClick={handleOpen} type="button">
            Add new field
          </Button>
        </div>
        <div className="body">
          <div className="table-def">
            {data != null && (
              <TableDnD
                {...{
                  columns,
                  data,
                  setData,
                  activeId,
                  setActiveId,
                  setSortOrder,
                }}
              />
            )}
          </div>
        </div>
      </ScCheckout>
      {isOpenModal && (
        <Modal onClose={handleOpen} alpha={0.5}>
          {(props) => (
            <CreateFieldModal
              {...{
                ...props,
                methods,
                handleClose: handleOpen,
                fieldInfo,
                refetch,
              }}
            />
          )}
        </Modal>
      )}
    </ScContent>
  );
};
