import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteLocationMutationVariables = Types.Exact<{
  deleteLocationId: Types.Scalars['String'];
}>;

export type DeleteLocationMutation = {
  __typename?: 'Mutation';
  DeleteLocation: { __typename?: 'DeleteLocationRespond'; id?: string | null };
};

export const DeleteLocationDocument = gql`
  mutation DeleteLocation($deleteLocationId: String!) {
    DeleteLocation(id: $deleteLocationId) {
      id
    }
  }
`;
export type DeleteLocationMutationFn = Apollo.MutationFunction<
  DeleteLocationMutation,
  DeleteLocationMutationVariables
>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      deleteLocationId: // value for 'deleteLocationId'
 *   },
 * });
 */
export function useDeleteLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLocationMutation,
    DeleteLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLocationMutation,
    DeleteLocationMutationVariables
  >(DeleteLocationDocument, options);
}
export type DeleteLocationMutationHookResult = ReturnType<
  typeof useDeleteLocationMutation
>;
export type DeleteLocationMutationResult =
  Apollo.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = Apollo.BaseMutationOptions<
  DeleteLocationMutation,
  DeleteLocationMutationVariables
>;
