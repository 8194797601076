import React from 'react';
import { NotFoundError } from 'navi';
import { Spinner } from 'components/elements/Spinner';

// import { ErrorBlock } from './ErrorBlock';

export const NotFoundBlock = () => {
  document.location.href = '/';

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spinner size={124} />
    </div>
  );
};

export const NotFoundLayout = (_: NotFoundError) => <NotFoundBlock />;
