import { Context, createContext, useContext } from 'react';

import { AuthActions } from './actions';

export type AuthContextPayload = AuthActions & {
  isAuth?: boolean;
  hasOrganization?: boolean;
};

export const AuthContext = createContext<Partial<AuthContextPayload>>({});

export const useAuth = () => {
  return useContext(AuthContext as Context<AuthContextPayload>);
};
