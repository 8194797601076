import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelMembershipMutationVariables = Types.Exact<{
  input: Types.CancelMembershipInput;
}>;

export type CancelMembershipMutation = {
  __typename?: 'Mutation';
  CancelMembership: { __typename?: 'PermissionEntity'; id: string };
};

export const CancelMembershipDocument = gql`
  mutation CancelMembership($input: CancelMembershipInput!) {
    CancelMembership(input: $input) {
      id
    }
  }
`;
export type CancelMembershipMutationFn = Apollo.MutationFunction<
  CancelMembershipMutation,
  CancelMembershipMutationVariables
>;

/**
 * __useCancelMembershipMutation__
 *
 * To run a mutation, you first call `useCancelMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelMembershipMutation, { data, loading, error }] = useCancelMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelMembershipMutation,
    CancelMembershipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelMembershipMutation,
    CancelMembershipMutationVariables
  >(CancelMembershipDocument, options);
}
export type CancelMembershipMutationHookResult = ReturnType<
  typeof useCancelMembershipMutation
>;
export type CancelMembershipMutationResult =
  Apollo.MutationResult<CancelMembershipMutation>;
export type CancelMembershipMutationOptions = Apollo.BaseMutationOptions<
  CancelMembershipMutation,
  CancelMembershipMutationVariables
>;
