import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import countryList from 'react-select-country-list';
import { TitleH2 } from 'components/styles/common';
import { ScContent, ScSettingsBody } from '../styled';
import { UpdateOrgDto, updateOrgSchema } from './schema';
import { Spinner } from 'components/elements/Spinner';
import InputForm from 'components/elements/inputs';
import SelectForm from 'components/elements/inputs/select';
import { Button } from 'components/elements/Button';
import { errorAlert, successAlert } from 'helpers/alerts';
import { useUpdateCurrentOrganizationSettingsMutation } from 'graphql/mutations/settings/__generated__/updateOrganizationSettings.generated';
import { useCurrentOrganizationQuery } from 'graphql/queries/organizations/__generated__/getCurrentOrganization.generated';
import TextareaForm from 'components/elements/inputs/textarea';
import { ScOrganizationSettings } from './styled';
import avatarIcon from 'theme/images/avatar.svg';

export const Organization = () => {
  const [update, { loading }] = useUpdateCurrentOrganizationSettingsMutation();
  const { data: org, loading: orgLoading } = useCurrentOrganizationQuery();

  const organization = useMemo(() => {
    if (org != null) return org.CurrentOrganization;
  }, [org]);

  const methods = useForm<UpdateOrgDto>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    resolver: yupResolver(updateOrgSchema),
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (organization != null) {
      reset({
        name: organization.name,
        postcode: organization.postcode != null ? organization.postcode : '',
        country: organization.country != null ? organization.country : '',
        //
        city: organization.city != null ? organization.city : '',
        registrationNumber:
          organization.registrationNumber != null
            ? organization.registrationNumber
            : '',
        phoneNumber:
          organization.phoneNumber != null ? organization.phoneNumber : '',
        email: organization.email != null ? organization.email : '',
        address: organization.address != null ? organization.address : '',
        description:
          organization.description != null ? organization.description : '',
      });
    }
  }, [organization, reset]);

  const options = useMemo(() => countryList().getData(), []);

  const [avatar, setAvatar] = useState<null | File>(null);
  const [preview, setPreview] = useState<null | string>(null);

  const handleSetAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != null) setAvatar(event.target.files[0]);
  };

  useEffect(() => {
    if (avatar != null) {
      const objectUrl = URL.createObjectURL(avatar);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [avatar]);

  useEffect(() => {
    if (organization != null && organization.image != null)
      setPreview(organization.image.link);
  }, [organization]);

  const onSubmit: SubmitHandler<UpdateOrgDto> = async (data) => {
    Object.keys(data).forEach((key) => {
      //@ts-ignore
      if (data[key] === '') {
        //@ts-ignore
        delete data[key];
      }
    });
    update({
      variables: {
        input: {
          ...data,
          image: avatar,
        },
      },
    }).then(({ data, errors }) => {
      if (data != null) {
        successAlert('Organization updated!');
      }
      if (errors != null) {
        errorAlert('Bad data input');
      }
    });
  };

  return (
    <ScContent>
      <TitleH2>Organization</TitleH2>
      {orgLoading && org == null ? (
        <Spinner />
      ) : (
        <ScSettingsBody>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="settings-profile"
            >
              <ScOrganizationSettings>
                <div className="row">
                  <InputForm<UpdateOrgDto>
                    required
                    id="name"
                    label="Organization name*"
                  />
                  <InputForm<UpdateOrgDto>
                    id="registrationNumber"
                    label="Company registration number"
                  />
                </div>
                <div className="row">
                  <InputForm<UpdateOrgDto> id="email" label="Email" />
                  <InputForm<UpdateOrgDto>
                    id="phoneNumber"
                    label="Phone number"
                  />
                </div>
                <div className="row">
                  <SelectForm<UpdateOrgDto>
                    id="country"
                    label="Country"
                    required
                  >
                    {options.map(({ label, value }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </SelectForm>
                  <InputForm<UpdateOrgDto> id="city" label="City" />
                </div>
                <div className="row">
                  <InputForm<UpdateOrgDto>
                    id="postcode"
                    label="Postal code"
                    required
                  />
                  <InputForm<UpdateOrgDto> id="address" label="Address" />
                </div>

                <div className="row">
                  <TextareaForm id="description" label="About company" />
                  <div className="avatar">
                    <label htmlFor="file">
                      <img alt="" src={preview || avatarIcon} />
                      <p>{preview != null ? 'Change logo' : 'Add logo'}</p>
                    </label>

                    <input
                      type="file"
                      id="file"
                      name="file"
                      onChange={(e) => handleSetAvatar(e)}
                    />
                  </div>
                </div>
              </ScOrganizationSettings>
              <div className="bottom-btn">
                <Button isLoading={loading}>Save</Button>
              </div>
            </form>
          </FormProvider>
        </ScSettingsBody>
      )}
    </ScContent>
  );
};
