import { FC, forwardRef } from 'react';
import { Spinner } from 'components/elements/Spinner';
import { TitleH4 } from 'components/styles/common';
import { ScButtonContainer } from './styled';
import { Button as CatalystButton } from 'components/catalyst-elements/button';
import { getSiteTheme } from 'hooks/getSiteTheme';

type ButtonType = 'button' | 'submit' | 'reset' | undefined;

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  color?: any;
  isLoading?: boolean;
  fullWidth?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      className,
      children,
      color,
      isLoading,
      disabled,
      type,
      // fullWidth,
      onClick: Click,
      name,
    } = props;

    const { isLight } = getSiteTheme();
    console.log(type, 'TYPE');
    return (
      <CatalystButton
        className={className}
        ref={ref}
        color={color ? color : isLight ? 'blue' : 'zinc'}
        // color={color}
        // isLoading={isLoading}
        disabled={isLoading || disabled}
        type={(type as ButtonType) || 'submit'}
        // fullWidth={fullWidth}
        onClick={Click}
        name={name}
      >
        {isLoading && <Spinner color={color} size={24} />}
        <TitleH4>{children}</TitleH4>
      </CatalystButton>
    );
  }
);

export interface ButtonContainerProps {
  direction: 'row' | 'column';
  gap: string;
}

export const ButtonContainer: FC<ButtonContainerProps> = ({
  children,
  direction,
  gap,
}) => {
  return (
    <ScButtonContainer direction={direction} gap={gap}>
      {children}
    </ScButtonContainer>
  );
};
