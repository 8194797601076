import { hexToRGB } from 'helpers/common';
import styled from 'styled-components/macro';

interface ScSetupPaymenProps {
  border?: boolean;
}

export const ScSetupPaymen = styled.div<ScSetupPaymenProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & > div {
    & > p {
      margin-top: 8px;
    }
    button {
      margin-top: 16px;
    }
  }
  border-bottom: ${({ theme, border }) =>
    border ? `1px solid ${theme.colorSecondaryGrayLight}` : ''};
  padding-bottom: 24px;
`;

export const ScPaymentLoading = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ScPayByInvoiceModal = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  max-width: 660px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: white;
  padding: 24px;
  & > h2 {
    margin-bottom: 32px;
  }
  form {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 16px;
    & > .form-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
    }
  }
`;

export const ScPaymentComponent = styled.div<ScSetupPaymenProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: ${({ theme, border }) =>
    border ? `1px solid ${theme.colorSecondaryGrayLight}` : ''};
  padding-bottom: 32px;
  & > .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    & > .title {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 16px;
    }
  }
  & > .card-template {
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.colorSecondaryGrayLight};
    & > .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;
      border-bottom: 1px solid ${({ theme }) => theme.colorSecondaryGrayLight};
      padding: 18px;
      & > .payment-item {
        width: 52px;
        height: 38px;
        border: 1px solid ${({ theme }) => theme.colorSecondaryGrayLight};
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 4px;
        border-radius: 8px;
        & > img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
    & > .bottom {
      padding: 18px;
      background-color: ${({ theme }) =>
        hexToRGB(theme.colorSecondaryGrayLight, 0.5)};
      border-radius: 0 0 12px 12px;
      display: flex;
      flex-direction: row;
      gap: 64px;
      & > .item {
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 14px;
        & > p:not(:first-child) {
          opacity: 0.5;
        }
      }
    }
  }
  & > .description {
    font-size: 14px;
    opacity: 0.75;
    max-width: 524px;
  }
`;
