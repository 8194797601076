import styled from 'styled-components';

export const ScTeam = styled.div`
  & > .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  & > .body {
    margin-top: 24px;
    & > .team-table {
      height: 550px;
      .avatar-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        & > img {
          width: 34px;
          height: 34px;
          border-radius: 90px;
          object-fit: cover;
          flex-shrink: 0;
        }
      }
      tr {
        cursor: default;
      }
      .role-td {
        position: relative;

        & > .role-btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
        }
        p {
          padding: 0;
        }
      }
      .cancel-td {
        & > svg {
          cursor: pointer;
          background-color: ${({ theme }) => theme.colorErrorRedDark};
          color: ${({ theme }) => theme.colorWhite};
          padding: 4px;
          border-radius: 90px;
        }
      }
    }
    form {
      .input-container {
        margin-top: 0px;
      }
      input {
        margin-top: 0px;
      }
    }
  }
`;
