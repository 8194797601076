import { forwardRef, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MapPin as MapPinIcon } from 'react-feather';
import { Button } from 'components/elements/Button';
import InputForm from 'components/elements/inputs';
import { ModalProps } from 'components/elements/Modal';
import { TextSmall, TitleH2 } from 'components/styles/common';
import { ScCreateLocationModal } from './styled';
import { CreateTagDto, createTagSchema } from './schema';
import { infoAlert } from 'helpers/alerts';
import { useTheme } from 'hooks/theme';
import { useCreateTagMutation } from 'graphql/mutations/tags/__generated__/createTag.generated';
import { getSiteTheme } from 'hooks/getSiteTheme';

export interface CreateTagModalProps extends Pick<ModalProps, 'onClose'> {
  refetch: any;
}

export const CreateTagModal = forwardRef<HTMLDivElement, CreateTagModalProps>(
  (props, ref) => {
    const { colorSecondaryGrayDark } = useTheme();

    const methods = useForm<CreateTagDto>({
      mode: 'onSubmit',
      reValidateMode: 'onBlur',
      shouldFocusError: true,
      resolver: yupResolver(createTagSchema),
    });
    const {
      handleSubmit,
      reset,
      formState: { errors, isSubmitSuccessful },
    } = methods;

    const [create, { loading, error }] = useCreateTagMutation();

    const onSubmit: SubmitHandler<CreateTagDto> = (input) => {
      return create({
        variables: {
          input,
        },
      });
    };

    useEffect(() => {
      if (isSubmitSuccessful && !error && props.onClose != null) {
        infoAlert('Tag created!');
        props.refetch();
        //@ts-ignore
        setTimeout(() => props.onClose(), 0);

        reset();
      }
    }, [error, isSubmitSuccessful, props, reset]);

    const { isLight } = getSiteTheme();

    return (
      <ScCreateLocationModal
        className={`${isLight ? 'bg-white' : 'bg-slate-800'}`}
        ref={ref}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="title-text">
              <MapPinIcon
                width={40}
                height={32}
                color={colorSecondaryGrayDark}
              />
              <TitleH2>Add tag</TitleH2>
              <TextSmall color={colorSecondaryGrayDark}>
                You can create tag entity to your organization
              </TextSmall>
            </div>

            <div className="input-div">
              <InputForm<CreateTagDto>
                id="name"
                disabled={loading}
                required
                placeholder="Name"
              />
            </div>

            <Button
              type="submit"
              disabled={Boolean(Object.keys(errors).length)}
              isLoading={loading}
              fullWidth={true}
            >
              Create
            </Button>
          </form>
        </FormProvider>
      </ScCreateLocationModal>
    );
  }
);
