import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConfirmPaymentMutationVariables = Types.Exact<{
  confirmPaymentId: Types.Scalars['String'];
}>;

export type ConfirmPaymentMutation = {
  __typename?: 'Mutation';
  ConfirmPayment: { __typename?: 'OrderEntity'; id: string };
};

export const ConfirmPaymentDocument = gql`
  mutation ConfirmPayment($confirmPaymentId: String!) {
    ConfirmPayment(id: $confirmPaymentId) {
      id
    }
  }
`;
export type ConfirmPaymentMutationFn = Apollo.MutationFunction<
  ConfirmPaymentMutation,
  ConfirmPaymentMutationVariables
>;

/**
 * __useConfirmPaymentMutation__
 *
 * To run a mutation, you first call `useConfirmPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPaymentMutation, { data, loading, error }] = useConfirmPaymentMutation({
 *   variables: {
 *      confirmPaymentId: // value for 'confirmPaymentId'
 *   },
 * });
 */
export function useConfirmPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmPaymentMutation,
    ConfirmPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmPaymentMutation,
    ConfirmPaymentMutationVariables
  >(ConfirmPaymentDocument, options);
}
export type ConfirmPaymentMutationHookResult = ReturnType<
  typeof useConfirmPaymentMutation
>;
export type ConfirmPaymentMutationResult =
  Apollo.MutationResult<ConfirmPaymentMutation>;
export type ConfirmPaymentMutationOptions = Apollo.BaseMutationOptions<
  ConfirmPaymentMutation,
  ConfirmPaymentMutationVariables
>;
