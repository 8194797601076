import React, { FC } from 'react';
import styled from 'styled-components';
import DragHandle from './DragHandle';

const StyledStaticData = styled.td`
  background: #ffffff;
  &:first-of-type {
    min-width: 20ch;
  }
  border-collapse: collapse;
  padding: 16px 18px;
`;

const StyledStaticTableRow = styled.tr`
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
    rgb(0 0 0 / 30%) 0px 10px 10px -5px;
`;

interface StaticTableRowProps {
  row: any;
}

const StaticTableRow: FC<StaticTableRowProps> = ({ row }) => {
  return (
    <StyledStaticTableRow {...row.getRowProps()}>
      {row.cells.map((cell: any, i: any) => {
        if (i === 0) {
          return (
            <StyledStaticData {...cell.getCellProps()}>
              <DragHandle isDragging />
              <span>{cell.render('Cell')}</span>
            </StyledStaticData>
          );
        }
        return (
          <StyledStaticData {...cell.getCellProps()}>
            {cell.render('Cell')}
          </StyledStaticData>
        );
      })}
    </StyledStaticTableRow>
  );
};

export default StaticTableRow;
