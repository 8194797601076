import { FC, useEffect, useState } from 'react';

import { decodeJWT } from 'lib/jwt';

import { TokenData } from 'http-requests/auth/login';

import { useTokensActions } from './actions';
import { TokensContext, TokensContextPayload } from './context';

export type Tokens = {
  access_token: string;
  refresh_token?: string | null;
  tokenType?: string | null;
};

export const TokensProvider: FC = ({ children }) => {
  const [globalLoading, setGlobalLoading] = useState(true);
  const [stateTokens, setStateTokens] = useState<Tokens>({
    access_token:
      localStorage.getItem('access_token') != null
        ? `${localStorage.getItem('access_token')}`
        : '',
    refresh_token: null,
    tokenType: null,
  });

  const tokensActions = useTokensActions({
    stateTokens,
    setStateTokens,
  });

  useEffect(() => {
    tokensActions.setDefault && tokensActions.setDefault();
    setGlobalLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tokens: TokensContextPayload = {
    ...tokensActions,
    tokens: stateTokens,
    globalLoading,
    setGlobalLoading,
  };

  if (stateTokens.access_token) {
    try {
      tokens.payload = decodeJWT<TokenData>(stateTokens.access_token || '');
    } catch (error) {
      tokensActions.clearTokens();
    }
  }

  return (
    <TokensContext.Provider value={tokens}>{children}</TokensContext.Provider>
  );
};
