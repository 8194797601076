import * as yup from 'yup';

export type UpdateOrgDto = {
  name: string;
  country: string;
  postcode: string;
  image?: any;
  registrationNumber?: string;
  phoneNumber?: string;
  email?: string;
  city?: string;
  address?: string;
  description?: string;
};

export const updateOrgSchema: yup.SchemaOf<UpdateOrgDto> = yup.object({
  name: yup.string().required(),
  registrationNumber: yup.string(),
  phoneNumber: yup.string(),
  country: yup.string().required(),
  email: yup.string().email(),
  postcode: yup.string().required(),
  city: yup.string(),
  address: yup.string(),
  description: yup.string(),
  image: yup.mixed(),
});
