import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAtivityClassesQueryVariables = Types.Exact<{
  activityByIdId: Types.Scalars['String'];
}>;

export type GetAtivityClassesQuery = {
  __typename?: 'Query';
  ActivityById: {
    __typename?: 'ActivityEntity';
    spots: number;
    activity_classes: Array<{
      __typename?: 'ActivityClassEntity';
      id: string;
      title: string;
      priceInCents: number;
      spots: number;
      start: any;
      booked: number;
      timelines: Array<{
        __typename?: 'TimelineEntity';
        id: string;
        timelineTeachers?: Array<{
          __typename?: 'TimelineTeacherEntity';
          createdDate?: any | null;
          deletedDate?: any | null;
          id: string;
          teacherId: string;
          timelineId: string;
          updatedDate?: any | null;
          teacher: {
            __typename?: 'MemberEntity';
            country?: string | null;
            email: string;
            id: string;
            name?: string | null;
            postcode?: string | null;
            roles: Array<string>;
            status: string;
            userId: string;
            avatar?: {
              __typename?: 'UserImageEntity';
              userId: string;
              updatedDate?: any | null;
              link: string;
              key: string;
              id: string;
              filename: string;
              deletedDate?: any | null;
              createdDate?: any | null;
            } | null;
          };
        }> | null;
      }>;
    }>;
  };
};

export const GetAtivityClassesDocument = gql`
  query getAtivityClasses($activityByIdId: String!) {
    ActivityById(id: $activityByIdId) {
      activity_classes {
        id
        title
        priceInCents
        spots
        start
        booked
        timelines {
          id
          timelineTeachers {
            createdDate
            deletedDate
            id
            teacherId
            timelineId
            updatedDate
            teacher {
              country
              avatar {
                userId
                updatedDate
                link
                key
                id
                filename
                deletedDate
                createdDate
              }
              email
              id
              name
              postcode
              roles
              status
              userId
            }
          }
        }
      }
      spots
    }
  }
`;

/**
 * __useGetAtivityClassesQuery__
 *
 * To run a query within a React component, call `useGetAtivityClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAtivityClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAtivityClassesQuery({
 *   variables: {
 *      activityByIdId: // value for 'activityByIdId'
 *   },
 * });
 */
export function useGetAtivityClassesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAtivityClassesQuery,
    GetAtivityClassesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAtivityClassesQuery,
    GetAtivityClassesQueryVariables
  >(GetAtivityClassesDocument, options);
}
export function useGetAtivityClassesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAtivityClassesQuery,
    GetAtivityClassesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAtivityClassesQuery,
    GetAtivityClassesQueryVariables
  >(GetAtivityClassesDocument, options);
}
export type GetAtivityClassesQueryHookResult = ReturnType<
  typeof useGetAtivityClassesQuery
>;
export type GetAtivityClassesLazyQueryHookResult = ReturnType<
  typeof useGetAtivityClassesLazyQuery
>;
export type GetAtivityClassesQueryResult = Apollo.QueryResult<
  GetAtivityClassesQuery,
  GetAtivityClassesQueryVariables
>;
