import { forwardRef, useEffect, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ScAddAttendeeModal } from './styled';
import { Cross } from 'components/elements/Button/Cross';
import { addAttendeeSchema, AddAttendeeSchemaDto } from './schema';
import InputForm from 'components/elements/inputs';
import { useGetActivitiesLazyQuery } from 'graphql/queries/activities/__generated__/getActivities.generated';
import SearchableSelect from 'components/elements/inputs/SearchableSelect';
import { useGetAtivityClassesLazyQuery } from 'graphql/queries/activities/__generated__/getAtivityClasses.generated';
import { TitleH2 } from 'components/styles/common';
import { Button } from 'components/elements/Button';
import { ColorsEnums } from 'helpers/enums/common';
import { useAddAttendeeMutation } from 'graphql/mutations/attendees/__generated__/addAttendee.generated';
import { isEmptyObject } from 'helpers/common';
import { errorAlert, successAlert } from 'helpers/alerts';
// import { useGetCheckoutFieldsQuery } from 'graphql/queries/chechoutFields/__generated__/getCheckoutFields.generated';
import { Spinner } from 'components/elements/Spinner';
import { getSiteTheme } from 'hooks/getSiteTheme';

export interface AddAttendeeModalProps {
  onClose: () => void;
  activityId: string | null;
  refetch: any;
}

export const AddAttendeeModal = forwardRef<
  HTMLDivElement,
  AddAttendeeModalProps
>((props, ref) => {
  const { onClose, activityId: id, refetch } = props;

  const methods = useForm<AddAttendeeSchemaDto>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    resolver: yupResolver(addAttendeeSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = methods;

  // const { data: dataCheckoutFields } = useGetCheckoutFieldsQuery();

  // const customFields = useMemo(() => {
  //   if (dataCheckoutFields != null)
  //     return dataCheckoutFields.CurrentOrganization.checkoutFields;
  // }, [dataCheckoutFields]);

  const [getActivity, { data: activitiesData }] = useGetActivitiesLazyQuery();

  const [getAtivityClasses, { data: classesData }] =
    useGetAtivityClassesLazyQuery();

  const [addAttendee, { loading }] = useAddAttendeeMutation();

  // const [confirmPayment, { loading: loadingConfirmPayment }] =
  //   useConfirmPaymentMutation();

  const activities = useMemo(() => {
    if (activitiesData != null) {
      setValue('activityId', id != null ? id : activitiesData.Activities[0].id);

      return activitiesData.Activities.map(({ id, title }) => ({
        value: id,
        label: title,
      }));
    }
  }, [activitiesData, id, setValue]);

  const classes = useMemo(() => {
    if (classesData != null && activities != null) {
      setValue(
        'activityClassId',
        classesData.ActivityById.activity_classes[0].id
      );
      return classesData.ActivityById.activity_classes.map(({ id, title }) => ({
        value: id,
        label: title,
      }));
    }
  }, [classesData, setValue, activities]);

  const activityId = watch('activityId');

  useEffect(() => {
    if (activityId != null)
      getAtivityClasses({ variables: { activityByIdId: activityId } });
  }, [activityId, getAtivityClasses]);

  const onSubmit: SubmitHandler<AddAttendeeSchemaDto> = async (data) => {
    if (isEmptyObject(errors)) {
      const {
        activityClassId,
        phoneNumber,
        firstName,
        lastName,
        email,
        ...rest
      } = data;
      addAttendee({
        variables: {
          activityClassId,
          input: {
            phoneNumber,
            firstName,
            lastName,
            email,
            checkoutFields: JSON.stringify(rest),
          },
        },
      }).then(({ data, errors }) => {
        if (errors != null) {
          errorAlert(`Some error: ${errors[0].message}`);
        }
        if (data != null) {
          successAlert('Attendee created!');
          refetch[0]();
          refetch[1]();
          onClose();
        }
      });
    }
  };

  useEffect(() => {
    getActivity();
  }, [getActivity]);

  const { isLight } = getSiteTheme();

  return (
    <ScAddAttendeeModal
      className={`${!isLight && '!bg-slate-900'} max-w-[600px]`}
      ref={ref}
    >
      <Cross onClick={onClose} right={22} top={22} size={24} />
      <TitleH2>Add attendee</TitleH2>
      {classes != null && activities != null ? (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid">
              <InputForm<AddAttendeeSchemaDto>
                id="firstName"
                type="text"
                required
                label="Name"
              />
              <InputForm<AddAttendeeSchemaDto>
                id="lastName"
                type="text"
                required
                label="Surname"
              />
              <InputForm<AddAttendeeSchemaDto>
                id="email"
                type="email"
                required
                label="Email"
              />
              <InputForm<AddAttendeeSchemaDto>
                id="phoneNumber"
                type="text"
                label="Phone number"
              />
              <SearchableSelect<AddAttendeeSchemaDto>
                id="activityId"
                label="Activity"
                required
                options={activities || []}
              />
              <SearchableSelect<AddAttendeeSchemaDto>
                id="activityClassId"
                label="Class"
                required
                options={classes || []}
              />

              {/*
              //CUSTOM FIELDS (I DONT KNOW WHAT IS IT)
               {customFields != null &&
                customFields.map((item) =>
                  item.enable ? (
                    <InputForm
                      id={camelize(item.name)}
                      label={item.name}
                      key={item.name}
                      required={item.required}
                    />
                  ) : null
                )} */}
            </div>
            <div className="bottom">
              <div />
              <div className="btns">
                <Button
                  type="button"
                  color={ColorsEnums.white}
                  onClick={onClose}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button type="submit" isLoading={loading} disabled={loading}>
                  Save
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      ) : (
        <Spinner size={54} />
      )}
    </ScAddAttendeeModal>
  );
});
