import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AvailableTeachersQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type AvailableTeachersQuery = {
  __typename?: 'Query';
  CurrentOrganization: {
    __typename?: 'OrganizationEntity';
    members?: Array<{
      __typename?: 'MemberEntity';
      id: string;
      email: string;
      name?: string | null;
      postcode?: string | null;
      country?: string | null;
      roles: Array<string>;
      status: string;
      hourlyRate?: number | null;
      userId: string;
      avatar?: {
        __typename?: 'UserImageEntity';
        link: string;
        id: string;
        filename: string;
      } | null;
    }> | null;
  };
};

export const AvailableTeachersDocument = gql`
  query AvailableTeachers {
    CurrentOrganization {
      members {
        id
        email
        name
        postcode
        country
        avatar {
          link
          id
          filename
        }
        roles
        status
        hourlyRate
        userId
      }
    }
  }
`;

/**
 * __useAvailableTeachersQuery__
 *
 * To run a query within a React component, call `useAvailableTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableTeachersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableTeachersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableTeachersQuery,
    AvailableTeachersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableTeachersQuery,
    AvailableTeachersQueryVariables
  >(AvailableTeachersDocument, options);
}
export function useAvailableTeachersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableTeachersQuery,
    AvailableTeachersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableTeachersQuery,
    AvailableTeachersQueryVariables
  >(AvailableTeachersDocument, options);
}
export type AvailableTeachersQueryHookResult = ReturnType<
  typeof useAvailableTeachersQuery
>;
export type AvailableTeachersLazyQueryHookResult = ReturnType<
  typeof useAvailableTeachersLazyQuery
>;
export type AvailableTeachersQueryResult = Apollo.QueryResult<
  AvailableTeachersQuery,
  AvailableTeachersQueryVariables
>;
