import { Button } from 'components/elements/Button';
import { TextSmall, TitleH1, TitleH2, TitleH3 } from 'components/styles/common';
import { useUpdateInvoiceNumberMutation } from 'graphql/mutations/orders/__generated__/updateInvoiceNumber.generated';
import { getFullDate } from 'helpers/dayjs/formats';
import { ColorsEnums, Role } from 'helpers/enums/common';
import { useTheme } from 'hooks/theme';
import { useMe } from 'hooks/useMe';
import { FC, useCallback, useState } from 'react';
import { OrderEntity } from 'types.generated';
import Comments from './Comments';
import { ScMainInfo } from './styled';
import { formatValue } from 'react-currency-input-field';
import { Link } from 'react-navi';
import { getSiteTheme } from 'hooks/getSiteTheme';
import Status from 'components/elements/Status';

interface MainInfoProps {
  order: OrderEntity;
  handleCancelOrder: () => void;
  handleOpenMoveAttendeeModal: () => void;
  classInfo: {
    start: string;
    end: string;
  };
  handleConfirmPayment: () => void;
  confirmLoading: boolean;
  loadingConfirmPayment: boolean;
  refetch: any;
}

const MainInfo: FC<MainInfoProps> = ({
  order,
  handleCancelOrder,
  handleOpenMoveAttendeeModal,
  classInfo,
  handleConfirmPayment,
  confirmLoading,
  loadingConfirmPayment,
  refetch,
}) => {
  const { colorSecondaryGrayDark } = useTheme();
  const { roles } = useMe();

  const [updateInvoiceNumber] = useUpdateInvoiceNumberMutation();

  const [invoiceNumber, setInvoiceNumber] = useState(
    order.invoiceNumber || undefined
  );

  const handleUpdateInvoiceNumber = useCallback(() => {
    if (invoiceNumber != null) {
      updateInvoiceNumber({
        variables: { orderId: order.id, invoiceNumber },
      });
    }
  }, [invoiceNumber, order.id, updateInvoiceNumber]);

  const handleChangeInvoiceNumber = (value: string) => {
    setInvoiceNumber(value);
  };

  const { isLight } = getSiteTheme();
  console.log(order, 'ORDER');
  return (
    <ScMainInfo>
      <div className="order-title">
        {order.status !== 'canceled' ? (
          <>
            <TitleH1>Order number: </TitleH1>
            <TitleH1 color={colorSecondaryGrayDark}>
              #{order?.orderNumber}
            </TitleH1>
          </>
        ) : (
          <>
            <TitleH1>Order number: </TitleH1>
            <TitleH1 color={colorSecondaryGrayDark}>
              #{order?.orderNumber}
            </TitleH1>
            <p className="refund">Cancelled</p>
          </>
        )}
      </div>
      <TextSmall className="time">
        {getFullDate(order?.createdDate, 'MMM DD, YYYY  HH:mm')}
      </TextSmall>
      <div className="action-btns">
        {order != null &&
          order.status !== 'canceled' &&
          !roles?.includes(Role.TEACHER) && (
            <div className="flex justify-between w-full">
              <div className="flex gap-10">
                <Button color={ColorsEnums.error} onClick={handleCancelOrder}>
                  Cancel booking
                </Button>
                <Button onClick={handleOpenMoveAttendeeModal}>
                  Move attendee
                </Button>
              </div>
            </div>
          )}
      </div>
      <div className="last-order">
        <div className="order-info">
          <div className="avatar">
            <p className={`${!isLight && '!text-white'}`}>
              {order?.activityTitle.slice(0, 2)}
            </p>
          </div>
          <div className="activity-info">
            <Link href={`/activity/bookings/${order.activityId}`}>
              <TitleH2>{order?.activityTitle}</TitleH2>
            </Link>
            <TextSmall>
              {`${order?.activityClassTitle}: ${getFullDate(
                classInfo.start
              )} - ${getFullDate(classInfo.end)}`}
            </TextSmall>
          </div>
          <TitleH2>
            {order != null
              ? order.spendInCents === 0
                ? 'FREE'
                : formatValue({
                    value: (order.spendInCents / 100).toFixed(0),
                    groupSeparator: '.',
                    decimalSeparator: ',',
                    suffix: ' DKK',
                  })
              : ''}
          </TitleH2>
        </div>
      </div>
      <div className="more-info">
        <div className="status">
          <div className="status-info">
            <TitleH2>Status</TitleH2>
            <Status status={order?.status} />
          </div>
          {order != null && order.status === 'processing' && (
            <Button
              onClick={handleConfirmPayment}
              isLoading={confirmLoading || loadingConfirmPayment}
            >
              Confirm payment
            </Button>
          )}
        </div>
        <div className="invoice">
          <p>
            <b>Invoice number:</b>
          </p>
          <div>
            <input
              className="bg-[rgba(1,1,1,0)]"
              value={invoiceNumber}
              onChange={(e) => handleChangeInvoiceNumber(e.target.value)}
              placeholder={
                roles?.includes(Role.TEACHER)
                  ? 'Invoice number'
                  : 'Set invoice number'
              }
              onBlur={handleUpdateInvoiceNumber}
              disabled={roles?.includes(Role.TEACHER)}
            />
          </div>
        </div>
        <div className="subtotal">
          <p>Price</p>
          <p>
            {order != null
              ? order.spendInCents === 0
                ? 'FREE'
                : formatValue({
                    value: (
                      order.spendInCents / 100 -
                      (order.spendInCents / 100) * 0.25
                    ).toFixed(0),
                    groupSeparator: '.',
                    decimalSeparator: ',',
                    suffix: ' DKK',
                  })
              : ''}
          </p>
        </div>
        {order != null && order.spendInCents !== 0 && (
          <div className="subtotal">
            <p>Tax</p>
            <p>
              {formatValue({
                value: ((order.spendInCents / 100) * 0.25).toFixed(0),
                groupSeparator: '.',
                decimalSeparator: ',',
                suffix: ' DKK',
              })}
            </p>
          </div>
        )}
        <div className="total">
          <TitleH3>Total price</TitleH3>
          <TitleH3>
            {order != null
              ? order.spendInCents === 0
                ? 'FREE'
                : formatValue({
                    value: (order.spendInCents / 100).toFixed(0),
                    groupSeparator: '.',
                    decimalSeparator: ',',
                    suffix: ' DKK',
                  })
              : ''}
          </TitleH3>
        </div>
      </div>
      <Comments order={order} refetch={refetch} />
    </ScMainInfo>
  );
};

export default MainInfo;
