import * as React from 'react';
import {
  FieldPath,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';
import { ErrorText } from './common';
import { theme } from 'theme';
import { Input } from 'components/catalyst-elements/input';
import { Field, Label } from 'components/catalyst-elements/fieldset';

export type InputProps<T extends FieldValues = FieldValues> = {
  label?: string;
  id: FieldPath<T>;
  placeholder?: string;
  helperText?: React.ReactNode;
  type?: React.HTMLInputTypeAttribute;
  readOnly?: boolean;
  hideError?: boolean;
  validation?: RegisterOptions;
  Icon?: any;
} & React.ComponentPropsWithoutRef<'input'>;

export default function InputForm<T extends FieldValues = FieldValues>({
  value,
  label,
  placeholder = '',
  helperText,
  id,
  type = 'text',
  readOnly = false,
  hideError = false,
  validation,
  Icon,
  ...rest
}: InputProps<T>) {
  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<T>();

  const inputValue = watch(id);

  if (id === 'priceInCents' && inputValue === undefined) {
    //@ts-ignore
    setValue('priceInCents', value);
  }

  if (id === 'spots' && inputValue === undefined) {
    //@ts-ignore
    setValue('spots', value);
  }

  if (id === 'variant_id' && inputValue === undefined) {
    return <></>;
  }

  return (
    <Field className="">
      <Label className={`!text-[#7C7C83] !font-normal`} id={id}>
        <div className="input-container relative">
          {Icon != null && (
            <Icon
              className="icon w-[20px] absolute bottom-[7px] left-[10px] z-10"
              color={theme.colorSecondaryGrayDark}
            />
          )}
          {label}
          <Input
            {...register(id, validation)}
            {...rest}
            type={type}
            name={id}
            id={id}
            readOnly={readOnly}
            // readOnlyStyled={readOnly}
            placeholder={placeholder}
            aria-describedby={id}
            onError={errors[id]}
            // error={errors[id]}
            // Icon={Icon}
            className="after::focus-within:!ring-none sm:after::focus-within:!ring-none"
            style={{
              background: 'rgba(1, 1, 1, 0) !important',
              paddingLeft: Icon != null ? '40px' : 'auto',
            }}
          />
        </div>
      </Label>
      <ErrorText
        hideError={hideError}
        helperText={helperText}
        errors={errors}
        id={id}
      />
    </Field>
  );
}
