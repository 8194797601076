import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTeacherMutationVariables = Types.Exact<{
  input: Types.UpdateTeacherInput;
}>;

export type UpdateTeacherMutation = {
  __typename?: 'Mutation';
  UpdateTeacher: {
    __typename?: 'PermissionEntity';
    id: string;
    createdDate?: any | null;
    updatedDate?: any | null;
    deletedDate?: any | null;
    roles: Array<string>;
    status: string;
    notes?: string | null;
    userId: string;
    organizationId: string;
    user: {
      __typename?: 'UserEntity';
      id: string;
      createdDate?: any | null;
      updatedDate?: any | null;
      deletedDate?: any | null;
      email: string;
      name?: string | null;
      confirmed: boolean;
      finished: boolean;
      postcode?: string | null;
      country?: string | null;
      hourlyRate?: number | null;
    };
  };
};

export const UpdateTeacherDocument = gql`
  mutation UpdateTeacher($input: UpdateTeacherInput!) {
    UpdateTeacher(input: $input) {
      id
      createdDate
      updatedDate
      deletedDate
      roles
      status
      notes
      userId
      user {
        id
        createdDate
        updatedDate
        deletedDate
        email
        name
        confirmed
        finished
        postcode
        country
        hourlyRate
      }
      organizationId
    }
  }
`;
export type UpdateTeacherMutationFn = Apollo.MutationFunction<
  UpdateTeacherMutation,
  UpdateTeacherMutationVariables
>;

/**
 * __useUpdateTeacherMutation__
 *
 * To run a mutation, you first call `useUpdateTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeacherMutation, { data, loading, error }] = useUpdateTeacherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeacherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeacherMutation,
    UpdateTeacherMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTeacherMutation,
    UpdateTeacherMutationVariables
  >(UpdateTeacherDocument, options);
}
export type UpdateTeacherMutationHookResult = ReturnType<
  typeof useUpdateTeacherMutation
>;
export type UpdateTeacherMutationResult =
  Apollo.MutationResult<UpdateTeacherMutation>;
export type UpdateTeacherMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeacherMutation,
  UpdateTeacherMutationVariables
>;
