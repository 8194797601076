import styled from 'styled-components';

export const ScLocation = styled.div`
  & > .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  & > .body {
    margin-top: 24px;
    & > .location-table {
      height: 550px;
      tr {
        cursor: default;
      }
      .actions {
        text-align: end;
        position: relative;
        svg {
          cursor: pointer;
        }
      }
    }
    form {
      .input-container {
        margin-top: 0px;
      }
      input {
        margin-top: 0px;
      }
    }
  }
`;

export const ScActionsBlock = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #ffffff;
  border-radius: 8px;
  padding: 8px 40px 8px 16px;
  border: 1px solid #ebecf0;
  right: 16px;
  z-index: 1;
  /* shadow-base */
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  & > .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
`;
