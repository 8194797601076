import styled from 'styled-components/macro';

export const ScTermsModal = styled.div`
  border-radius: 8px;
  width: 95%;
  max-width: 724px;
  margin: 40px auto;
  min-height: 64px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px;
  position: relative;
  & > div {
    & > h1 {
      margin-bottom: 32px;
    }
    & > h2 {
      margin-bottom: 16px;
    }
    & > h3 {
      margin-bottom: 16px;
    }
    & > p:not(:last-child) {
      margin-bottom: 8px;
    }
    & > p {
      color: ${({ theme }) => theme.colorSecondaryGrayDark};
      font-size: 14px;
    }
    & > ul {
      margin-bottom: 16px;
      padding-left: 21px;
      & > li {
        color: ${({ theme }) => theme.colorSecondaryGrayDark};
        font-size: 14px;
        margin-bottom: 4px;
      }
    }
  }
`;
