import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import { ScSearchModal } from './styled';

import SearchInput from './SearchInput';
import { useGlobalSearchLazyQuery } from 'graphql/queries/search/__generated__/GlobalSearch.generated';
import { getSiteTheme } from 'hooks/getSiteTheme';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import { Link } from 'react-navi';
import { useGetActivitiesQuery } from 'graphql/queries/activities/__generated__/getActivities.generated';
import { Spinner } from 'components/elements/Spinner';
import { useMe } from 'hooks/useMe';
import { Role } from 'helpers/enums/common';
import { useGetActivityToBookingsLazyQuery } from 'graphql/queries/activities/__generated__/getActivityToBookings.generated';

export type DataItemType = {
  title: string;
  items: { id: string; title: string }[];
  to: string;
};

export interface SearchModalProps {
  onClose: () => void;
}

export const SearchModal = forwardRef<HTMLDivElement, SearchModalProps>(
  (props, ref) => {
    // const navigator = useNavigation();
    const { roles } = useMe();
    const { data: activityData } = useGetActivitiesQuery();
    console.log(activityData, 'activityData');

    const [getClasses, { data: activityClassesData }] =
      useGetActivityToBookingsLazyQuery();

    console.log(activityClassesData, 'activityClassesData');

    const [search, { data: searchData, loading }] = useGlobalSearchLazyQuery();
    const searchInputRef = useRef<null | HTMLInputElement>(null);

    const [searchValue, setSearchValue] = useState('');

    const timerSearchRef = useRef<NodeJS.Timeout | null>(null);

    const [data, setData] = useState<DataItemType[] | null>(null);

    const [dataSearch, setDataSearch] = useState<any>(null);

    useEffect(() => {
      if (activityData) {
        const activities = activityData.Activities.map((item) => ({
          id: item.id,
          title: item.title,
        }));
        setTimeout(() => {
          setDataSearch([
            {
              title: 'Activity',
              items: activities,
            },
          ]);
        }, 1);
      }
    }, [activityData]);

    console.log(dataSearch, 'DataSearch');

    // const handleClick = useCallback((url) => {
    //   navigator.navigate(url)
    // }, [navigator]);

    const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) =>
      setSearchValue(e.target.value);

    useEffect(() => {
      if (searchValue.length > 0) {
        clearTimeout(timerSearchRef.current as NodeJS.Timeout);
        timerSearchRef.current = setTimeout(() => {
          search({ variables: { search: searchValue } });
        }, 1000);
      } else setData((prev) => (prev != null ? null : prev));
    }, [search, searchValue]);

    useEffect(() => {
      if (searchData != null) {
        const { activities, activityClasses } = searchData.globalSearch;
        if (activities.length > 0 || activityClasses.length > 0) {
          setData([
            {
              title: 'Activities',
              items: activities,
              to: 'activity/overview',
            },
            {
              title: 'Activity Classes',
              items: activityClasses.map((item) => ({
                title: item.title,
                id: item.activity.id,
              })),
              to: 'activity/days',
            },
          ]);
        } else {
          setData([]);
        }
      }
    }, [searchData]);
    console.log(data, 'DATA');
    useEffect(() => {
      if (searchInputRef.current != null) {
        searchInputRef.current.focus();
      }
    }, [searchInputRef]);

    const filteredSearchData = useMemo(() => {
      if (activityData) {
        return activityData.Activities.filter((item) =>
          item.title.includes(searchValue)
        ).map((item) => ({
          title: item.title,
          id: item.id,
        }));
      }
    }, [activityData, searchValue]);
    console.log(filteredSearchData, 'filteredSearchData');
    const { isLight } = getSiteTheme();
    //////////////////////////////////////////////
    console.log(dataSearch, 'dataSearch');
    return (
      <ScSearchModal
        ref={ref}
        className={`${isLight ? '!bg-white' : '!bg-zinc-900'}`}
      >
        <SearchInput
          {...{ searchValue, handleChangeSearchValue, loading, searchInputRef }}
        />
        {/* <ResultComponent {...{ searchValue, data }} /> */}
        <div className="h-[500px] p-4 overflow-hidden overflow-y-scroll">
          {filteredSearchData !== undefined && roles ? (
            <>
              <h3>Activities</h3>
              {filteredSearchData.map(({ title, id }: any) => {
                return (
                  <>
                    <div className="p-1 mx-2 text-sm py-2 border-b-[1px] border-b-gray-200">
                      <div className="flex gap-2 hover:bg-gray-200 p-1 rounded-md">
                        <ArrowCircleRightIcon
                          width={16}
                          onClick={() => {
                            getClasses({
                              variables: {
                                input: {
                                  activityId: `${id}`,
                                },
                              },
                            });
                          }}
                        />
                        <Link
                          href={`/activity/${
                            !roles?.includes(Role.TEACHER)
                              ? 'overview'
                              : 'bookings'
                          }/${id}`}
                        >
                          <p>{title}</p>
                        </Link>
                      </div>
                      {activityClassesData && (
                        <ol className="">
                          {activityClassesData.ActivityClasses.map((item) => {
                            if (id === item.activity.id) {
                              return (
                                <Link
                                  href={`/activity/bookings/${item.activity.id}?activityClassName=${item.title}`}
                                >
                                  <li className="text-sm text-bold list-disc ml-10 hover:bg-gray-100 p-1 rounded-md">
                                    {item.title}
                                  </li>
                                </Link>
                              );
                            }
                            return <></>;
                          })}
                        </ol>
                      )}
                    </div>
                  </>
                );
              })}
            </>
          ) : (
            <Spinner size={18} />
          )}
        </div>
      </ScSearchModal>
    );
  }
);

//<> Temporary
