import { TextSmall } from 'components/styles/common';
import { StylesConfig } from 'react-select';
import { theme } from 'theme';
import { ScErrorText } from './styled';
import { getSiteTheme } from 'hooks/getSiteTheme';

interface LabelProps {
  label: string | undefined;
  id: string;
}

export const Label: React.FC<LabelProps> = ({ label, id }) => {
  return (
    <>
      {label && (
        <label htmlFor={id} className="input-label">
          <TextSmall>{label}</TextSmall>
        </label>
      )}
    </>
  );
};

interface ErrorTextProps {
  hideError: boolean;
  helperText: React.ReactNode;
  errors: any;
  id: string;
}

export const ErrorText: React.FC<ErrorTextProps> = ({
  hideError,
  helperText,
  errors,
  id,
}) => {
  return (
    <ScErrorText>
      {!hideError && errors[id] && (
        <TextSmall color={theme.colorErrorRedDark}>
          {errors[id].message}
        </TextSmall>
      )}
      {helperText && <TextSmall>{helperText}</TextSmall>}
    </ScErrorText>
  );
};

export type OptionType = {
  label: string;
  value: string;
  priceInCents?: number;
  spots?: number;
  variant_id?: number | null | undefined;
  avatarUrl?: string | null;
  timelineTeacher?: string;
  archivedDate?: Date;
};

const { isLight } = getSiteTheme();

export const colourStylesSelect: StylesConfig<any> = {
  control: (base: any, state: any) => ({
    ...base,
    fontSize: 14,
    minHeight: 'unset',
    boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)',
    backgroundColor: isLight ? '#ffffff' : 'rgb(255 255 255 / 0.05)',
    borderRadius: '0.5rem',
    borderColor: state.isFocused
      ? isLight
        ? 'black'
        : '#ffffff'
      : isLight
      ? 'rgba(9, 9, 11, 0.18)'
      : 'rgb(255 255 255 / 0.1)',
    ':hover': {
      borderColor: state.isFocused
        ? isLight
          ? 'black'
          : '#ffffff'
        : isLight
        ? 'rgba(9, 9, 11, 0.3)'
        : 'rgb(255 255 255 / 0.2)',
    },
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: '5.5px',
  }),
  menu: (styles) => ({
    ...styles,
    top: 33,
    color: isLight ? '#000000' : 'white',
    backgroundColor: isLight ? 'white' : 'rgb(39, 39, 42)',
    border: '1px solid rgb(63, 63, 70)',
    borderRadius: '0.5rem',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,

      borderRadius: '0.5rem',
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? isLight
          ? '#f0f0f0'
          : 'rgb(24, 24, 27)'
        : isFocused
        ? isLight
          ? '#f0f0f0'
          : 'rgb(24, 24, 27)'
        : !isLight
        ? 'rgb(39, 39, 42)'
        : undefined,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? '#000000'
        : isFocused
        ? '#000000'
        : '#000000',
      cursor: 'pointer',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? isLight
              ? '#f0f0f0'
              : 'rgba(24, 24, 27, 0.8)'
            : isLight
            ? '#f0f0f0'
            : 'rgba(24, 24, 27, 0.8)'
          : undefined,
      },
    };
  },
};
