import { useCallback, useContext } from 'react';

import {
  improveToken,
  ImproveTokenDto,
  ImproveTokenRespond,
} from 'http-requests/auth/improveToken';
import {
  loginUser,
  LoginUserDto,
  LoginUserRespond,
} from 'http-requests/auth/login';
import { refreshUserToken } from 'http-requests/auth/refreshToken';
import {
  registerUser,
  RegisterUserDto,
  RegisterUserRespond,
} from 'http-requests/auth/registration';

import { TokensContext, TokensContextPayload } from '../tokens/context';
import {
  ForgetPasswordDto,
  forgetPasswordUser,
} from 'http-requests/auth/forgetPassword';
import {
  ResetPasswordDto,
  resetPasswordUser,
} from 'http-requests/auth/resetPassword';

export type AuthActions = {
  login: (data: LoginUserDto) => Promise<LoginUserRespond>;
  registration: (data: RegisterUserDto) => Promise<RegisterUserRespond>;
  refreshToken: () => void;
  improve: (data: ImproveTokenDto) => Promise<ImproveTokenRespond>;
  logout: () => void;
  clearTokens: () => void;
  forgetPassword: (data: ForgetPasswordDto) => void;
  resetPassword: (data: ResetPasswordDto) => void;
};

type UseAuthActions = (
  props: TokensContextPayload,
  payload: {
    isAuth: boolean;
  }
) => AuthActions;

export const useAuthActions: UseAuthActions = (
  { clearTokens, setTokens, tokens },
  { isAuth }
) => {
  const { setGlobalLoading } = useContext(TokensContext);

  const login = useCallback<AuthActions['login']>(
    async (data) => {
      if (isAuth) {
        throw new Error('User already authorized');
      }

      // setGlobalLoading(true);
      const result = await loginUser(data);

      setTokens(result);
      // setGlobalLoading(false);

      return result;
    },
    [isAuth, setTokens]
  );

  const registration = useCallback<AuthActions['registration']>(
    async (data) => {
      if (isAuth) {
        throw new Error('User already authorized');
      }

      // setGlobalLoading(true);
      const result = await registerUser(data);
      // setGlobalLoading(false);

      return result;
    },
    [isAuth]
  );

  const refreshToken = useCallback<AuthActions['refreshToken']>(async () => {
    if (!isAuth) {
      throw new Error('User is not authorized');
    }

    // setGlobalLoading(true);

    if (!tokens.refresh_token) {
      clearTokens();
      // setGlobalLoading(false);

      return false;
    }

    const result = await refreshUserToken({
      refreshToken: tokens.refresh_token,
    });

    setTokens(result);
    // setGlobalLoading(false);

    return Boolean(result);
  }, [clearTokens, isAuth, setTokens, tokens.refresh_token]);

  const logout = useCallback(() => {
    if (!isAuth) {
      throw new Error('User is not authorized');
    }

    // setGlobalLoading(true);
    localStorage.removeItem('registration-email');
    clearTokens();
    document.location.href = '/';

    // setGlobalLoading(false);
  }, [clearTokens, isAuth]);

  // TODO: check and fix
  const improve = useCallback<AuthActions['improve']>(
    async (data) => {
      if (!isAuth) {
        throw new Error('User is not authorized');
      }
      //@ts-ignore
      setGlobalLoading(true);

      localStorage.removeItem('refresh_token');
      localStorage.removeItem('access_token');

      const result = await improveToken(data);

      setTokens(result);

      setTimeout(() => {
        //@ts-ignore
        setGlobalLoading(false);
      }, 1000);

      return result;
    },
    [isAuth, setGlobalLoading, setTokens]
  );

  const forgetPassword = useCallback<AuthActions['forgetPassword']>(
    async (data) => {
      if (isAuth) {
        throw new Error('User already authorized');
      }

      const result = await forgetPasswordUser(data);

      return result;
    },
    [isAuth]
  );

  const resetPassword = useCallback<AuthActions['resetPassword']>(
    async (data) => {
      if (isAuth) {
        throw new Error('User already authorized');
      }

      const result = await resetPasswordUser(data);

      return result;
    },
    [isAuth]
  );

  return {
    login,
    registration,
    refreshToken,
    logout,
    improve,
    clearTokens,
    forgetPassword,
    resetPassword,
  };
};
