import React, { FC, useMemo } from 'react';
import { Bell, Hash, Info, Share2, ShoppingCart } from 'react-feather';
import {
  BriefcaseIcon,
  CreditCardIcon,
  LocationMarkerIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/outline';
import { TitleH2, TitleH4 } from 'components/styles/common';
import { Role } from 'helpers/enums/common';
import { useTheme } from 'hooks/theme';
import { SettingTab } from '.';
import ResponsiveTrainerUI from '../ResponsiveTrainerUI';
import { ScNavBar, ScNavigationItem, ScNavigationItemProps } from './styled';
import { getSiteTheme } from 'hooks/getSiteTheme';

interface SettingsNavBarProps {
  email: string;
  isSettingsOpen: boolean;
  setIsSettingsOpen: (isSettingsOpen: boolean) => void;
  activeTab: SettingTab;
  setActiveTab: React.Dispatch<React.SetStateAction<SettingTab>>;
}

type NavigationItemType = {
  Icon: JSX.Element;
  title: string;
  action: () => void;
  id: SettingTab;
  setIsSettingsOpen: (isSettingsOpen: boolean) => void;
};

export const SettingsNavBar: FC<SettingsNavBarProps> = ({
  email,
  setIsSettingsOpen,
  activeTab,
  setActiveTab,
}) => {
  const { colorSecondaryGrayDark } = useTheme();

  const tabs = useMemo(
    () => [
      {
        Icon: <UserIcon />,
        title: 'My profile',
        action: () => setActiveTab(SettingTab.PROFILE),
        id: SettingTab.PROFILE,
        validRole: Role.ALL,
      },
      {
        Icon: <BriefcaseIcon />,
        title: 'Organization',
        action: () => setActiveTab(SettingTab.ORGANIZATION),
        id: SettingTab.ORGANIZATION,
        validRole: [Role.OWNER, Role.PLANNER],
      },
      {
        Icon: <UsersIcon />,
        title: 'Team',
        action: () => setActiveTab(SettingTab.TEAM),
        id: SettingTab.TEAM,
        validRole: [Role.OWNER, Role.PLANNER],
      },
      {
        Icon: <CreditCardIcon />,
        title: 'Payment',
        action: () => setActiveTab(SettingTab.PAYMENT),
        id: SettingTab.PAYMENT,
        validRole: [Role.OWNER, Role.PLANNER],
      },
      {
        Icon: <LocationMarkerIcon />,
        title: 'Location',
        action: () => setActiveTab(SettingTab.LOCATIONS),
        id: SettingTab.LOCATIONS,
        validRole: [Role.OWNER, Role.PLANNER],
      },
      {
        Icon: <Hash />,
        title: 'Tags',
        action: () => setActiveTab(SettingTab.TAGS),
        id: SettingTab.TAGS,
        validRole: [Role.OWNER, Role.PLANNER],
      },
      {
        Icon: <Bell />,
        title: 'Notifications',
        action: () => setActiveTab(SettingTab.NOTIFICATIONS),
        id: SettingTab.NOTIFICATIONS,
        validRole: [Role.OWNER, Role.PLANNER],
      },
      {
        Icon: <ShoppingCart />,
        title: 'Checkout',
        action: () => setActiveTab(SettingTab.CHECKOUT),
        id: SettingTab.CHECKOUT,
        validRole: [Role.OWNER, Role.PLANNER],
      },
      {
        Icon: <Info />,
        title: 'Terms',
        action: () => setActiveTab(SettingTab.TERMS),
        id: SettingTab.TERMS,
        validRole: [Role.OWNER, Role.PLANNER],
      },
      {
        Icon: <Share2 />,
        title: 'Integrations',
        action: () => setActiveTab(SettingTab.INTEGRATIONS),
        id: SettingTab.INTEGRATIONS,
        validRole: [Role.OWNER, Role.PLANNER],
      },
    ],
    [setActiveTab]
  );

  const { isLight } = getSiteTheme();

  return (
    <ScNavBar
      className={`!h-full ${isLight ? '!bg-slate-100' : '!bg-zinc-800'}`}
    >
      {/* <Logo black={true} /> */}
      <TitleH4 color={colorSecondaryGrayDark}>{email}</TitleH4>
      <div className="tabs">
        {tabs.map((tab) => (
          <ResponsiveTrainerUI
            key={tab.title}
            Component={
              //@ts-ignore
              <NavigationItem
                active={tab.id === activeTab}
                {...tab}
                setIsSettingsOpen={setIsSettingsOpen}
              />
            }
            validRoles={tab.validRole}
          />
        ))}
      </div>
    </ScNavBar>
  );
};

const NavigationItem: FC<NavigationItemType & ScNavigationItemProps> = ({
  Icon,
  title,
  action,
  active,
  setIsSettingsOpen,
}) => {
  return (
    <ScNavigationItem
      onClick={() => {
        setIsSettingsOpen(false);
        action();
      }}
      active={active}
    >
      {Icon}
      <TitleH2>{title}</TitleH2>
    </ScNavigationItem>
  );
};
