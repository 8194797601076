import { forwardRef } from 'react';
import { ScTermsModal } from './styled';
import { Cross } from 'components/elements/Button/Cross';
import { TitleH2 } from 'components/styles/common';

export interface TermsModalProps {
  onClose: () => void;
  terms?: string | null;
}

export const TermsModal = forwardRef<HTMLDivElement, TermsModalProps>(
  (props, ref) => {
    const { onClose, terms } = props;

    return (
      <ScTermsModal ref={ref}>
        <Cross onClick={onClose} right={22} top={22} size={16} />
        {/* <TitleH1>Terms&Conditions</TitleH1> */}
        {terms != null ? (
          <div dangerouslySetInnerHTML={{ __html: terms }} />
        ) : (
          <div>
            <TitleH2>Persondatapolitik</TitleH2>
            <p>
              Vi behandler dine data sikkert. Her kan du læse mere om, hvordan
              vi indsamler og behandler data.
            </p>
            <p>
              Simply sustain indsamler data, som er nødvendige for at kunne
              handle med dig i forbindelse med vores konsulent- og
              kursusydelser. De indsamlede data er: Navn, stilling, adresse,
              telefonnummer, e-mailadresse samt data, der er nødvendige for at
              rådgive dig i forhold til valg af retning på kurser.
            </p>
            <p>De indsamlede data opsamles som følger:</p>
            <ul>
              <li>
                Ved tilmelding til kursus, hvor vi har en databehandleraftale
                med Yonobi
              </li>
              <li>
                Når du laver en adfærdsprofil, hvor vi har en
                databehandleraftale med E-stimate
              </li>
              <li>
                Ved henvendelse på e-mail, hvor du ønsker at købe en
                konsulentydelse
              </li>
              <li>Ved kursusevalueringer</li>
            </ul>
            <p>
              Vi gemmer din e-mailadresse, så vi kan kontakte dig med relevant
              information om din videre uddannelse i Simply sustain, herunder
              særlige konferencer og tilbud, der måtte have din interesse. Vi
              udsender ikke nyhedsbreve til din e-mailadresse.
            </p>
            <p>
              Vi opbevarer dine data sikkert og dine data slettes efter 2 år.
              Undtaget herfra er dog data, som vedrører fakturering og økonomi,
              hvor vi følger bogføringslovens regler. Dine data videregives ikke
              til tredjepart uden dit skriftlige samtykke.
            </p>
            <p>
              Du kan til hver tid kontakte os, såfremt du ønsker oplyst, hvilke
              data vi har indsamlet om dig, eller såfremt du ønsker de
              indsamlede data slettet.
            </p>
            <p>
              Du kan også kontakte os, såfremt du ikke ønsker at modtage
              relevant tilbud om uddannelse fremover.
            </p>
            <p>Dataansvarlig hos Simply sustain er Ann Møller Svendsen</p>
            <TitleH2>Handelsbetingelser</TitleH2>
            <p>
              Ved modtagelse af din tilmelding faktureres dit kursusforløb, som
              forfalder til betaling 14 dage efter udstedelse af faktura.
              Beløbet er således forudbetalt, og sikrer at pladsen er din. Din
              tilmelding er ikke bindende før 30 dage inden kursusstart. Det vil
              sige, at du kan melde fra til kurser og uddannelser op til 30 dage
              før kursusstart uden omkostninger. Vi refunderer hele det
              fakturerede beløb. Såfremt du melder fra senere, har du mulighed
              for at blive flyttet til et andet hold. Vi refunderer ikke
              kursusbeløbet, men du kan naturligvis udskyde din uddannelse eller
              bytte til et andet forløb.
            </p>
          </div>
        )}
      </ScTermsModal>
    );
  }
);
