import * as Types from '../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateNotificationMutationVariables = Types.Exact<{
  input: Types.UpdateNotificationInput;
}>;

export type UpdateNotificationMutation = {
  __typename?: 'Mutation';
  UpdateNotification: {
    __typename?: 'NotificationEntity';
    id: string;
    createdDate?: any | null;
    updatedDate?: any | null;
    deletedDate?: any | null;
    replyEmail?: string | null;
    timeToSendNotification: string;
    positionToSendNotification: string;
    positionMeetingToSendNotification: string;
    subject: string;
    body: string;
    type: string;
    organizationId: string;
  };
};

export const UpdateNotificationDocument = gql`
  mutation UpdateNotification($input: UpdateNotificationInput!) {
    UpdateNotification(input: $input) {
      id
      createdDate
      updatedDate
      deletedDate
      replyEmail
      timeToSendNotification
      positionToSendNotification
      positionMeetingToSendNotification
      subject
      body
      type
      organizationId
    }
  }
`;
export type UpdateNotificationMutationFn = Apollo.MutationFunction<
  UpdateNotificationMutation,
  UpdateNotificationMutationVariables
>;

/**
 * __useUpdateNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationMutation, { data, loading, error }] = useUpdateNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationMutation,
    UpdateNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNotificationMutation,
    UpdateNotificationMutationVariables
  >(UpdateNotificationDocument, options);
}
export type UpdateNotificationMutationHookResult = ReturnType<
  typeof useUpdateNotificationMutation
>;
export type UpdateNotificationMutationResult =
  Apollo.MutationResult<UpdateNotificationMutation>;
export type UpdateNotificationMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationMutation,
  UpdateNotificationMutationVariables
>;
