import { createContext, FC, useCallback, useEffect, useState } from 'react';
import { ApolloQueryResult, LazyQueryExecFunction } from '@apollo/client';
import {
  GetActivitiesQuery,
  useGetActivitiesLazyQuery,
} from 'graphql/queries/activities/__generated__/getActivities.generated';
import { ActivityEntity, Exact } from 'types.generated';

interface ActivitiesContextData {
  activities: ActivityEntity[] | null;
  loading: boolean;
}

interface ActivitiesContextActions {
  refetch: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetActivitiesQuery>>;
  getActivities: LazyQueryExecFunction<
    GetActivitiesQuery,
    Exact<{
      [key: string]: never;
    }>
  >;
  addActivity: (activity: ActivityEntity) => void;
}

export const ActivitiesContext = createContext<
  ActivitiesContextData & ActivitiesContextActions
>(null as any);

export const ActivitiesProvider: FC = ({ children }) => {
  const [getActivities, { data, loading, refetch }] =
    useGetActivitiesLazyQuery();

  const [activities, setActivities] = useState<ActivityEntity[] | null>(null);

  useEffect(() => {
    if (data != null) {
      setActivities(data.Activities as ActivityEntity[]);
    }
  }, [data, refetch]);

  const addActivity = useCallback(
    (activity: ActivityEntity) => {
      if (activities != null) {
        setActivities((prev) => [...(prev as ActivityEntity[]), activity]);
      }
    },
    [activities]
  );

  return (
    <ActivitiesContext.Provider
      value={{ loading, activities, getActivities, refetch, addActivity }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
