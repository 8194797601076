import React, { FC, useEffect } from 'react';
import { NotFoundBoundary } from 'react-navi';
import { NotFoundLayout } from 'components/modules/internal/NotFound';
import SettingsDetector from 'helpers/settings';
import { useModal } from 'hooks/useModal';
import { useSettings } from 'hooks/useSettings';

export const RouterLayout: FC = ({ children }) => {
  const { openSettingsModal } = useModal();
  const { setActiveTab } = useSettings();

  useEffect(() => {
    SettingsDetector(openSettingsModal, setActiveTab);
  }, [openSettingsModal, setActiveTab]);

  return (
    <NotFoundBoundary render={NotFoundLayout}>{children}</NotFoundBoundary>
  );
};
