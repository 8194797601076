import * as Types from '../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteNotificationMutationVariables = Types.Exact<{
  deleteNotificationId: Types.Scalars['String'];
}>;

export type DeleteNotificationMutation = {
  __typename?: 'Mutation';
  DeleteNotification: {
    __typename?: 'DeleteNotificationRespond';
    id?: string | null;
    createdDate?: any | null;
    updatedDate?: any | null;
    deletedDate?: any | null;
    replyEmail?: string | null;
    timeToSendNotification?: string | null;
    positionToSendNotification?: string | null;
    positionMeetingToSendNotification?: string | null;
    subject?: string | null;
    body?: string | null;
    type?: string | null;
    organizationId?: string | null;
  };
};

export const DeleteNotificationDocument = gql`
  mutation DeleteNotification($deleteNotificationId: String!) {
    DeleteNotification(id: $deleteNotificationId) {
      id
      createdDate
      updatedDate
      deletedDate
      replyEmail
      timeToSendNotification
      positionToSendNotification
      positionMeetingToSendNotification
      subject
      body
      type
      organizationId
    }
  }
`;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<
  DeleteNotificationMutation,
  DeleteNotificationMutationVariables
>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      deleteNotificationId: // value for 'deleteNotificationId'
 *   },
 * });
 */
export function useDeleteNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteNotificationMutation,
    DeleteNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteNotificationMutation,
    DeleteNotificationMutationVariables
  >(DeleteNotificationDocument, options);
}
export type DeleteNotificationMutationHookResult = ReturnType<
  typeof useDeleteNotificationMutation
>;
export type DeleteNotificationMutationResult =
  Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<
  DeleteNotificationMutation,
  DeleteNotificationMutationVariables
>;
