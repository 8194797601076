import styled from 'styled-components/macro';

interface TextProps {
  color?: string;
  bold?: boolean;
}

export const DivClass = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export const TitleH1 = styled.h1<TextProps>`
  font-weight: 600;
  font-size: 28px;
  line-height: 135%;

  color: ${({ color }) => (color != null ? color : '')};
`;

export const TitleH2 = styled.h2<TextProps>`
  font-weight: ${({ bold }) => (bold || bold == null ? 600 : 500)};
  font-size: 18px;
  line-height: 135%;
  color: ${({ color }) => (color != null ? color : '')};
`;

export const TitleH3 = styled.h3<TextProps>`
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  color: ${({ color }) => (color != null ? color : '')};
`;

export const TitleH4 = styled.h4<TextProps>`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ color }) => (color != null ? color : '')};
`;

export const Text = styled.p<TextProps>`
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  font-size: 16px;
  line-height: 140%;
  color: ${({ color }) => (color != null ? color : '')};
`;

export const TextSmall = styled.p<TextProps>`
  font-weight: ${({ bold }) => (bold != null ? 600 : 500)};
  font-size: 14px;
  line-height: 150%;
  color: ${({ color }) => (color != null ? color : '')};
`;

interface ScPageProps {
  isFull?: boolean;
}

export const ScPage = styled.div<ScPageProps>`
  max-width: ${({ isFull }) =>
    isFull ? 'calc(100% - 256px)' : 'calc(100vw - 432px)'};
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ScWhitePage = styled.div<ScPageProps>`
  /* max-width: ${({ isFull }) =>
    isFull ? 'calc(100%)' : 'calc(100% - 256px)'}; */
  width: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
  // background-color: ${({ theme }) => theme.colorBackground};
  ::before {
    content: 'bg';
    // background-color: ${({ theme }) => theme.colorWhite};
    color: ${({ theme }) => theme.colorWhite};
    width: 100%;
    position: fixed;
    height: 120vh;
    z-index: -1;
  }
`;

export const ScPageHeader = styled.div<ScPageProps>`
  width: ${({ isFull }) => (isFull ? 'calc(100%)' : 'calc(100vw - 235px)')};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding: 32px 21px 24px 0; */

  padding: ${({ isFull }) => (isFull ? '32px 0 24px 0' : '32px 21px 24px 0')};
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colorBackground};
  z-index: 2;
  max-height: 80.3px;
`;

interface ScPageBodyProps {
  withoutMinHeight?: boolean;
  minHeight?: string;
  scroll?: boolean;
}

export const ScPageBody = styled.div<ScPageBodyProps>`
  width: 100%;
  background-color: ${({ theme }) => theme.colorWhite};
  border-radius: 8px;
  padding: 0 24px 24px 24px;
  min-height: ${({ withoutMinHeight, minHeight }) =>
    minHeight != null
      ? `${minHeight}`
      : withoutMinHeight
      ? `unset`
      : `calc(100% - 96px)`};
  height: ${({ scroll }) => (scroll != null ? `calc(100vh - 96px)` : '')};
  overflow: ${({ scroll }) => (scroll != null ? 'auto' : '')};
`;

export const ScBodyHeader = styled.div`
  z-index: 1;
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colorSecondaryGrayLight};
  background-color: ${({ theme }) => theme.colorWhite};
  /* position: sticky; */
  max-height: 70.59px;
  top: 0;
  & > .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }
`;

export const ModalDiv = styled.div`
  background-color: ${({ theme }) => theme.colorWhite};
  padding: 24px;
  border-radius: 12px;
  max-width: 724px;
  margin: 0 auto;
  position: relative;
  form {
    & > .bottom {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 16px;
      padding-top: 16px;
      margin-top: 32px;
      border-top: 1px solid ${({ theme }) => theme.colorSecondaryGrayLight};
    }
  }
`;

export const BigModalDiv = styled.div`
  background-color: ${({ theme }) => theme.colorWhite};
  padding: 24px;
  border-radius: 12px;
  max-width: 724px;
  margin: 0 auto;
  position: relative;
  min-height: 1000px;
  form {
    & > .bottom {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 16px;
      padding-top: 16px;
      margin-top: 32px;
      border-top: 1px solid ${({ theme }) => theme.colorSecondaryGrayLight};
    }
  }
`;
