import * as React from 'react';
import {
  FieldPath,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';

import { ErrorText, Label } from '../common';
import { ScInputForm } from '../styled';
import { ScTextarea } from './styled';

export type TextareaProps<T extends FieldValues = FieldValues> = {
  label?: string;
  id: FieldPath<T>;
  placeholder?: string;
  helperText?: React.ReactNode;
  readOnly?: boolean;
  hideError?: boolean;
  validation?: RegisterOptions;
} & React.ComponentPropsWithoutRef<'textarea'>;

export default function TextareaForm<T extends FieldValues = FieldValues>({
  label,
  placeholder = '',
  helperText,
  id,
  readOnly = false,
  hideError = false,
  validation,

  ...rest
}: TextareaProps<T>) {
  const {
    register,
    formState: { errors },
  } = useFormContext<T>();

  return (
    <ScInputForm>
      <Label label={label} id={id} />
      <div className="input-container">
        <ScTextarea
          {...register(id, validation)}
          {...rest}
          name={id}
          id={id}
          readOnly={readOnly}
          readOnlyStyled={readOnly}
          placeholder={placeholder}
          aria-describedby={id}
          error={errors[id]}
          rows={6}
        />
      </div>
      <ErrorText
        hideError={hideError}
        helperText={helperText}
        errors={errors}
        id={id}
      />
    </ScInputForm>
  );
}
