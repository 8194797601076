import { FC, useMemo } from 'react';

import { useAuthActions } from './actions';
import { AuthContext, AuthContextPayload } from './context';
import { useTokens } from '../tokens/context';

export const AuthProvider: FC = ({ children }) => {
  const tokens = useTokens();

  const isAuth = useMemo(() => {
    return Boolean(tokens.tokens.access_token);
  }, [tokens.tokens.access_token]);
  const hasOrganization = useMemo(() => {
    return Boolean(tokens.payload?.permissionId);
  }, [tokens.payload?.permissionId]);

  const authActions = useAuthActions(tokens, { isAuth });

  const auth: AuthContextPayload = {
    ...authActions,
    isAuth,
    hasOrganization,
  };

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
