import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateInvoiceNumberMutationVariables = Types.Exact<{
  invoiceNumber: Types.Scalars['String'];
  orderId: Types.Scalars['String'];
}>;

export type UpdateInvoiceNumberMutation = {
  __typename?: 'Mutation';
  UpdateInvoiceNumber: { __typename?: 'OrderEntity'; id: string };
};

export const UpdateInvoiceNumberDocument = gql`
  mutation UpdateInvoiceNumber($invoiceNumber: String!, $orderId: String!) {
    UpdateInvoiceNumber(invoiceNumber: $invoiceNumber, orderId: $orderId) {
      id
    }
  }
`;
export type UpdateInvoiceNumberMutationFn = Apollo.MutationFunction<
  UpdateInvoiceNumberMutation,
  UpdateInvoiceNumberMutationVariables
>;

/**
 * __useUpdateInvoiceNumberMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceNumberMutation, { data, loading, error }] = useUpdateInvoiceNumberMutation({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useUpdateInvoiceNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInvoiceNumberMutation,
    UpdateInvoiceNumberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInvoiceNumberMutation,
    UpdateInvoiceNumberMutationVariables
  >(UpdateInvoiceNumberDocument, options);
}
export type UpdateInvoiceNumberMutationHookResult = ReturnType<
  typeof useUpdateInvoiceNumberMutation
>;
export type UpdateInvoiceNumberMutationResult =
  Apollo.MutationResult<UpdateInvoiceNumberMutation>;
export type UpdateInvoiceNumberMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoiceNumberMutation,
  UpdateInvoiceNumberMutationVariables
>;
