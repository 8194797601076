import { ImageMap } from './contracts';

function getImageName(path: string) {
  return path.substring(path.lastIndexOf('/') + 1, path.lastIndexOf('.'));
}

interface RequireContext {
  keys(): string[];
  (key: string): any;
}

export function importImages(
  strCtx: RequireContext,
  svgCtx: RequireContext
): ImageMap {
  const strImagesMap = strCtx.keys().reduce<{
    [name: string]: string;
  }>((images, key) => {
    images[getImageName(key)] = strCtx(key).default;

    return images;
  }, {});

  return svgCtx.keys().reduce<ImageMap>((images, path) => {
    const name = getImageName(path);

    images[name] = {
      path: strImagesMap[name],
      component: svgCtx(path).default,
    };

    return images;
  }, {});
}
