import * as yup from 'yup';

export type UpdateUserDto = {
  name: string;
  country: string;
  postcode: string;
  avatar: any;
};

export const updateUserSchema: yup.SchemaOf<UpdateUserDto> = yup
  .object({
    name: yup.string().required(),
    country: yup.string().required(),
    postcode: yup.string().required(),
    avatar: yup.mixed(),
  })
  .required();
