import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrdersQueryVariables = Types.Exact<{
  params?: Types.InputMaybe<Types.OrderParamsInput>;
}>;

export type OrdersQuery = {
  __typename?: 'Query';
  Orders: Array<{
    __typename?: 'OrderEntity';
    id: string;
    orderNumber?: number | null;
    createdDate?: any | null;
    updatedDate?: any | null;
    deletedDate?: any | null;
    spendInCents: number;
    phoneNumber?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    postcode?: string | null;
    country?: string | null;
    status: string;
    paymentIntentId?: string | null;
    currency: string;
    activityTitle: string;
    activityClassTitle: string;
    attendeeId?: string | null;
    activityId?: string | null;
    activityClassId?: string | null;
    organizationId: string;
    checkoutFields: any;
    invoiceNumber?: string | null;
    comments?: Array<{
      __typename?: 'CommentEntity';
      id: string;
      createdDate?: any | null;
      text: string;
      userId: string;
      orderId: string;
      user?: {
        __typename?: 'UserEntity';
        email: string;
        name?: string | null;
      } | null;
    }> | null;
    emailLog?: Array<{
      __typename?: 'EmailLogEntity';
      id: string;
      createdDate?: any | null;
      updatedDate?: any | null;
      deletedDate?: any | null;
      email: string;
      replyEmail?: string | null;
      subject: string;
      body: string;
      status: string;
      type: string;
      orderId?: string | null;
      message?: string | null;
    }> | null;
    attendee?: {
      __typename?: 'AttendeeEntity';
      id: string;
      email: string;
      name?: string | null;
      postcode?: string | null;
      country?: string | null;
    } | null;
  }>;
};

export const OrdersDocument = gql`
  query Orders($params: OrderParamsInput) {
    Orders(params: $params) {
      id
      orderNumber
      createdDate
      updatedDate
      deletedDate
      spendInCents
      phoneNumber
      firstName
      lastName
      postcode
      country
      status
      paymentIntentId
      currency
      activityTitle
      activityClassTitle
      attendeeId
      activityId
      activityClassId
      organizationId
      checkoutFields
      invoiceNumber
      comments {
        id
        createdDate
        text
        userId
        orderId
        user {
          email
          name
        }
      }
      emailLog {
        id
        createdDate
        updatedDate
        deletedDate
        email
        replyEmail
        subject
        body
        status
        type
        orderId
        message
      }
      attendee {
        id
        email
        name
        postcode
        country
      }
    }
  }
`;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(
    OrdersDocument,
    options
  );
}
export function useOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(
    OrdersDocument,
    options
  );
}
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<
  OrdersQuery,
  OrdersQueryVariables
>;
