import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetActivityToBookingsQueryVariables = Types.Exact<{
  input: Types.ActivityClassesParamsInput;
}>;

export type GetActivityToBookingsQuery = {
  __typename?: 'Query';
  ActivityClasses: Array<{
    __typename?: 'ActivityClassEntity';
    id: string;
    title: string;
    priceInCents: number;
    spots: number;
    start: any;
    end: any;
    booked: number;
    archivedDate?: any | null;
    activity: {
      __typename?: 'ActivityEntity';
      spots: number;
      id: string;
      link: string;
      title: string;
      status: string;
    };
    orders?: Array<{
      __typename?: 'OrderEntity';
      id: string;
      attendeeId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      createdDate?: any | null;
      status: string;
      spendInCents: number;
      activityId?: string | null;
      checkoutFields: any;
      attendee?: {
        __typename?: 'AttendeeEntity';
        id: string;
        email: string;
        name?: string | null;
        postcode?: string | null;
        country?: string | null;
      } | null;
    }> | null;
    comments?: Array<{
      __typename?: 'ActivityClassCommentEntity';
      id: string;
      createdDate?: any | null;
      text: string;
      userId: string;
      activityClassId: string;
      user?: {
        __typename?: 'UserEntity';
        email: string;
        name?: string | null;
      } | null;
    }> | null;
  }>;
};

export const GetActivityToBookingsDocument = gql`
  query GetActivityToBookings($input: ActivityClassesParamsInput!) {
    ActivityClasses(input: $input) {
      id
      title
      priceInCents
      spots
      start
      end
      booked
      archivedDate
      activity {
        spots
        id
        link
        title
        status
      }
      orders {
        id
        attendeeId
        firstName
        lastName
        createdDate
        status
        spendInCents
        activityId
        checkoutFields
        attendee {
          id
          email
          name
          postcode
          country
        }
      }
      comments {
        id
        createdDate
        text
        userId
        activityClassId
        user {
          email
          name
        }
      }
    }
  }
`;

/**
 * __useGetActivityToBookingsQuery__
 *
 * To run a query within a React component, call `useGetActivityToBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityToBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityToBookingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetActivityToBookingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivityToBookingsQuery,
    GetActivityToBookingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActivityToBookingsQuery,
    GetActivityToBookingsQueryVariables
  >(GetActivityToBookingsDocument, options);
}
export function useGetActivityToBookingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivityToBookingsQuery,
    GetActivityToBookingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActivityToBookingsQuery,
    GetActivityToBookingsQueryVariables
  >(GetActivityToBookingsDocument, options);
}
export type GetActivityToBookingsQueryHookResult = ReturnType<
  typeof useGetActivityToBookingsQuery
>;
export type GetActivityToBookingsLazyQueryHookResult = ReturnType<
  typeof useGetActivityToBookingsLazyQuery
>;
export type GetActivityToBookingsQueryResult = Apollo.QueryResult<
  GetActivityToBookingsQuery,
  GetActivityToBookingsQueryVariables
>;
