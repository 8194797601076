import styled from 'styled-components/macro';

export const ScNotificationModal = styled.div`
  border-radius: 8px;
  width: 100%;
  max-width: 660px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  /* align-items: center; */
  padding: 16px;
  .title {
    margin-bottom: 32px;
  }
  .button-btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
  }
  .body {
    & > .label {
      font-size: 14px;
      font-weight: 400;
      color: ${({ theme }) => theme.colorSecondaryGrayDark};
      margin-bottom: 14px;
    }
    .variables {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 8px;
      & > div {
        background-color: ${({ theme }) => theme.colorSecondaryGrayLight};
        color: ${({ theme }) => theme.colorSecondaryGrayDark};
        padding: 4px 8px;
        border-radius: 8px;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  .input-container {
    select,
    textarea,
    input {
      font-size: 16px;
    }
  }
`;
