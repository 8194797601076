import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddCommentMutationVariables = Types.Exact<{
  text: Types.Scalars['String'];
  orderId: Types.Scalars['String'];
}>;

export type AddCommentMutation = {
  __typename?: 'Mutation';
  AddComment: {
    __typename?: 'CommentEntity';
    id: string;
    createdDate?: any | null;
    updatedDate?: any | null;
    deletedDate?: any | null;
    text: string;
    userId: string;
    orderId: string;
    user?: {
      __typename?: 'UserEntity';
      email: string;
      name?: string | null;
    } | null;
  };
};

export const AddCommentDocument = gql`
  mutation AddComment($text: String!, $orderId: String!) {
    AddComment(text: $text, orderId: $orderId) {
      id
      createdDate
      updatedDate
      deletedDate
      text
      userId
      user {
        email
        name
      }
      orderId
    }
  }
`;
export type AddCommentMutationFn = Apollo.MutationFunction<
  AddCommentMutation,
  AddCommentMutationVariables
>;

/**
 * __useAddCommentMutation__
 *
 * To run a mutation, you first call `useAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentMutation, { data, loading, error }] = useAddCommentMutation({
 *   variables: {
 *      text: // value for 'text'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useAddCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCommentMutation,
    AddCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCommentMutation, AddCommentMutationVariables>(
    AddCommentDocument,
    options
  );
}
export type AddCommentMutationHookResult = ReturnType<
  typeof useAddCommentMutation
>;
export type AddCommentMutationResult =
  Apollo.MutationResult<AddCommentMutation>;
export type AddCommentMutationOptions = Apollo.BaseMutationOptions<
  AddCommentMutation,
  AddCommentMutationVariables
>;
