import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TitleH2 } from 'components/styles/common';
import { ScContent, ScSettingsBody } from '../styled';
import { useUpdateUserSettingsMutation } from 'graphql/mutations/settings/__generated__/updateUserSettings.generated';
import { UpdateUserDto, updateUserSchema } from './schema';
import { useMeQuery } from 'graphql/queries/me/__generated__/me.generated';
import { Spinner } from 'components/elements/Spinner';
import InputForm from 'components/elements/inputs';
import { Button } from 'components/elements/Button';
import { successAlert } from 'helpers/alerts';
import avatarIcon from 'theme/images/avatar.svg';

export const MyProfile = () => {
  const [update, { loading }] = useUpdateUserSettingsMutation();
  const { data: me, loading: meLoading } = useMeQuery();

  const methods = useForm<UpdateUserDto>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    resolver: yupResolver(updateUserSchema),
    defaultValues: {
      country: `${me?.Me.country}`,
      postcode: `${me?.Me.postcode}`,
      name: `${me?.Me.name}`,
    },
  });

  const { handleSubmit } = methods;

  const [avatar, setAvatar] = useState<null | File>(null);
  const [preview, setPreview] = useState<null | string>(null);

  const handleSetAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != null) setAvatar(event.target.files[0]);
  };

  useEffect(() => {
    if (avatar != null) {
      const objectUrl = URL.createObjectURL(avatar);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [avatar]);

  useEffect(() => {
    if (me != null && me.Me.avatar != null) setPreview(me.Me.avatar.link);
  }, [me]);

  const onSubmit: SubmitHandler<UpdateUserDto> = async (data) => {
    console.log(data, 'DATA');
    update({
      variables: {
        input: {
          ...data,
          avatar,
        },
      },
    }).then(({ data }) => {
      console.log(data, 'DATA');
      if (data != null) {
        successAlert('Profile updated!');
      }
    });
  };

  return (
    <ScContent>
      <TitleH2>My profile</TitleH2>
      {meLoading && me == null ? (
        <Spinner />
      ) : (
        <ScSettingsBody>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="settings-profile"
            >
              <InputForm id="name" label="Name" required />
              <div className="avatar">
                <label htmlFor="file">
                  <img alt="" src={preview || avatarIcon} />
                  <p>{preview != null ? 'Change photo' : 'Add photo'}</p>
                </label>

                <input
                  type="file"
                  id="file"
                  name="file"
                  onChange={(e) => handleSetAvatar(e)}
                />
              </div>

              <div className="bottom-btn">
                <Button isLoading={loading}>Save</Button>
              </div>
            </form>
          </FormProvider>
        </ScSettingsBody>
      )}
    </ScContent>
  );
};
