import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StripeOnboardingLinkMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type StripeOnboardingLinkMutation = {
  __typename?: 'Mutation';
  StripeOnboardingLink: { __typename?: 'UrlRespond'; url: string };
};

export const StripeOnboardingLinkDocument = gql`
  mutation StripeOnboardingLink {
    StripeOnboardingLink {
      url
    }
  }
`;
export type StripeOnboardingLinkMutationFn = Apollo.MutationFunction<
  StripeOnboardingLinkMutation,
  StripeOnboardingLinkMutationVariables
>;

/**
 * __useStripeOnboardingLinkMutation__
 *
 * To run a mutation, you first call `useStripeOnboardingLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeOnboardingLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeOnboardingLinkMutation, { data, loading, error }] = useStripeOnboardingLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useStripeOnboardingLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StripeOnboardingLinkMutation,
    StripeOnboardingLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StripeOnboardingLinkMutation,
    StripeOnboardingLinkMutationVariables
  >(StripeOnboardingLinkDocument, options);
}
export type StripeOnboardingLinkMutationHookResult = ReturnType<
  typeof useStripeOnboardingLinkMutation
>;
export type StripeOnboardingLinkMutationResult =
  Apollo.MutationResult<StripeOnboardingLinkMutation>;
export type StripeOnboardingLinkMutationOptions = Apollo.BaseMutationOptions<
  StripeOnboardingLinkMutation,
  StripeOnboardingLinkMutationVariables
>;
