import styled from 'styled-components';
import { ScInputProps } from '../styled';
import { getSiteTheme } from 'hooks/getSiteTheme';

const { isLight } = getSiteTheme();

export const ScSelect = styled.select<ScInputProps>`
  margin-top: 16px;
  display: block;
  width: 100%;
  background-color: ${isLight ? 'white' : 'rgb(255 255 255 / 0.05)'} !important;
  // box-shadow: '0 0 1px rgba(0, 0, 0, 0.2)',
  border: 1px solid
    ${isLight ? 'rgba(9, 9, 11, 0.18)' : 'rgb(255 255 255 / 0.1)'};
  border-radius: 0.5rem;
  padding: 8px 12px;
  :focus {
    border-color: ${({ theme }) => theme.colorSecondaryVioletDark};
  }

  ${({ disabled, theme, error }) => {
    if (disabled) {
      return `
      cursor: not-allowed;
      background-color: ${theme.colorSecondaryGrayLight};
      :focus {
        border-color: ${theme.colorSecondaryGrayLight};;
      }
      `;
    } else if (error) {
      return `
      border-color: ${theme.colorErrorRedDark};
      :focus {
        border-color: ${theme.colorErrorRedDark};
      }
      `;
    } else {
      return `
      background-color: ${theme.colorWhite};
      :focus {
        border-color: ${theme.colorSecondaryVioletDark};
      }
      `;
    }
  }}
`;
