import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

const defaultOptions = {} as const;
export type UpdateTermsMutationVariables = Types.Exact<{
  input: Types.UpdateOrganizationInput;
}>;

export type UpdateTermsMutation = {
  __typename?: 'Mutation';
  UpdateTerms: { __typename?: 'TermssEntity'; id: string };
};

export const UpdateTermsDocument = gql`
  mutation UpdateTerms($input: UpdateOrganizationInput!) {
    UpdateCurrentOrganization(input: $input) {
      id
    }
  }
`;
export type UpdateTermsMutationFn = Apollo.MutationFunction<
  UpdateTermsMutation,
  UpdateTermsMutationVariables
>;

/**
 * __useUpdateTermsMutation__
 *
 * To run a mutation, you first call `useUpdateTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTermsMutation, { data, loading, error }] = useUpdateTermsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTermsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTermsMutation,
    UpdateTermsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTermsMutation, UpdateTermsMutationVariables>(
    UpdateTermsDocument,
    options
  );
}
export type UpdateTermsMutationHookResult = ReturnType<
  typeof useUpdateTermsMutation
>;
export type UpdateTermsMutationResult =
  Apollo.MutationResult<UpdateTermsMutation>;
export type UpdateTermsMutationOptions = Apollo.BaseMutationOptions<
  UpdateTermsMutation,
  UpdateTermsMutationVariables
>;
