enum Countries {
  UnitedKingdom = 'UK',
  Denmark = 'DK',
}

type Languages = {
  [codeCountry: string]: {
    locale: string;
    countryIcon: string;
    subName?: string;
  }[];
};

export const languages: Languages = {
  [Countries.UnitedKingdom]: [
    {
      locale: 'en',
      countryIcon: 'en',
      subName: 'en',
    },
  ],
  [Countries.Denmark]: [
    {
      locale: 'dk',
      countryIcon: 'dk',
    },
  ],
};
