import { TextSmall, TitleH3 } from 'components/styles/common';
import { getSiteTheme } from 'hooks/getSiteTheme';
import { useTheme } from 'hooks/theme';
import { ScBillingInformation } from 'pages/Attendee/components/styled';
import { FC } from 'react';

export type InfoType = {
  title: string;
  value: string;
  underline?: boolean;
};

interface BillingInformationProps {
  billingInfo?: InfoType[];
}

const BillingInformation: FC<BillingInformationProps> = ({ billingInfo }) => {
  const { colorSecondaryGrayDark } = useTheme();
  const { isLight } = getSiteTheme();

  return (
    <ScBillingInformation
      className={`${isLight ? '!bg-white' : '!bg-zinc-800'}`}
    >
      <TitleH3> Billing information</TitleH3>
      <div className="all-info">
        {billingInfo?.map((item) => (
          <Info
            key={item.title}
            {...item}
            colorSecondaryGrayDark={colorSecondaryGrayDark}
          />
        ))}
      </div>
    </ScBillingInformation>
  );
};

export default BillingInformation;

interface InfoProps {
  title: string;
  value: string | number;
  underline?: boolean;
  colorSecondaryGrayDark: string;
}

const Info: FC<InfoProps> = ({
  title,
  value,
  underline,
  colorSecondaryGrayDark,
}) => {
  return (
    <div className={`info ${underline ? 'underline' : ''}`}>
      <TextSmall color={colorSecondaryGrayDark}>{title}</TextSmall>
      <TextSmall>{value}</TextSmall>
    </div>
  );
};
