import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCheckoutSortOrdersMutationVariables = Types.Exact<{
  fieldIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type UpdateCheckoutSortOrdersMutation = {
  __typename?: 'Mutation';
  UpdateCheckoutSortOrders: Array<{
    __typename?: 'CheckoutFieldsEntity';
    id: string;
  }>;
};

export const UpdateCheckoutSortOrdersDocument = gql`
  mutation UpdateCheckoutSortOrders($fieldIds: [String!]!) {
    UpdateCheckoutSortOrders(fieldIds: $fieldIds) {
      id
    }
  }
`;
export type UpdateCheckoutSortOrdersMutationFn = Apollo.MutationFunction<
  UpdateCheckoutSortOrdersMutation,
  UpdateCheckoutSortOrdersMutationVariables
>;

/**
 * __useUpdateCheckoutSortOrdersMutation__
 *
 * To run a mutation, you first call `useUpdateCheckoutSortOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCheckoutSortOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCheckoutSortOrdersMutation, { data, loading, error }] = useUpdateCheckoutSortOrdersMutation({
 *   variables: {
 *      fieldIds: // value for 'fieldIds'
 *   },
 * });
 */
export function useUpdateCheckoutSortOrdersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCheckoutSortOrdersMutation,
    UpdateCheckoutSortOrdersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCheckoutSortOrdersMutation,
    UpdateCheckoutSortOrdersMutationVariables
  >(UpdateCheckoutSortOrdersDocument, options);
}
export type UpdateCheckoutSortOrdersMutationHookResult = ReturnType<
  typeof useUpdateCheckoutSortOrdersMutation
>;
export type UpdateCheckoutSortOrdersMutationResult =
  Apollo.MutationResult<UpdateCheckoutSortOrdersMutation>;
export type UpdateCheckoutSortOrdersMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCheckoutSortOrdersMutation,
    UpdateCheckoutSortOrdersMutationVariables
  >;
