import * as yup from 'yup';
import { InviteTeacherDto } from '.';

//@ts-ignore
export const inviteTeacherSchema: yup.SchemaOf<InviteTeacherDto> = yup
  .object({
    email: yup.string().required(),
    roles: yup.array().required(),
  })
  .required();
