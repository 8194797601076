import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePayByInvoiceMutationVariables = Types.Exact<{
  input: Types.UpdatePayByInvoiceInput;
}>;

export type UpdatePayByInvoiceMutation = {
  __typename?: 'Mutation';
  UpdatePayByInvoice: { __typename?: 'PayByInvoiceEntity'; id: string };
};

export const UpdatePayByInvoiceDocument = gql`
  mutation UpdatePayByInvoice($input: UpdatePayByInvoiceInput!) {
    UpdatePayByInvoice(input: $input) {
      id
    }
  }
`;
export type UpdatePayByInvoiceMutationFn = Apollo.MutationFunction<
  UpdatePayByInvoiceMutation,
  UpdatePayByInvoiceMutationVariables
>;

/**
 * __useUpdatePayByInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdatePayByInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayByInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayByInvoiceMutation, { data, loading, error }] = useUpdatePayByInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayByInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayByInvoiceMutation,
    UpdatePayByInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePayByInvoiceMutation,
    UpdatePayByInvoiceMutationVariables
  >(UpdatePayByInvoiceDocument, options);
}
export type UpdatePayByInvoiceMutationHookResult = ReturnType<
  typeof useUpdatePayByInvoiceMutation
>;
export type UpdatePayByInvoiceMutationResult =
  Apollo.MutationResult<UpdatePayByInvoiceMutation>;
export type UpdatePayByInvoiceMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayByInvoiceMutation,
  UpdatePayByInvoiceMutationVariables
>;
