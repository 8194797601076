import {
  FC,
  forwardRef,
  MutableRefObject,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/elements/Button';
import InputForm from 'components/elements/inputs';
import { ModalProps } from 'components/elements/Modal';
import { TextSmall, TitleH2 } from 'components/styles/common';
import { useInviteUserMutation } from 'graphql/mutations/inviteUser/__generated__/inviteUser.generated';
import { ScInviteTeacherModal, ScRoleOptions } from './styled';
import { inviteTeacherSchema } from './schema';
// import { useWorkspacesQuery } from 'graphql/queries/workspaces/__generated__/workspaces.generated';
import { infoAlert } from 'helpers/alerts';
import { useTheme } from 'hooks/theme';
import { Check, ChevronDown, Users as UsersIcon } from 'react-feather';
import { capitalizeFirstLetter } from 'helpers/common';
import { useComponentVisible } from 'hooks/visibility';
import { theme } from 'theme';
import { InviteUserInput } from 'types.generated';
import { Role } from 'helpers/enums/common';
import { getSiteTheme } from 'hooks/getSiteTheme';

export type InviteTeacherDto = InviteUserInput;
export interface InviteTeacherModalProps extends Pick<ModalProps, 'onClose'> {
  refetch: any;
}

export const InviteTeacherModal = forwardRef<
  HTMLDivElement,
  InviteTeacherModalProps
>((props, ref) => {
  const [role, setRole] = useState(Role.TEACHER);

  const {
    ref: visibleRef,
    openerRef,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  const handleOpen = () => setIsComponentVisible((prev) => !prev);

  const { colorSecondaryGrayDark, colorSecondaryVioletDark } = useTheme();

  const methods = useForm<InviteTeacherDto>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    resolver: yupResolver(inviteTeacherSchema),
    defaultValues: {
      roles: [role],
    },
  });
  const {
    handleSubmit,
    setError,
    setFocus,
    reset,

    formState: { errors, isSubmitSuccessful },
  } = methods;

  const [inviteTeacher, { loading, error }] = useInviteUserMutation();

  const onSubmit: SubmitHandler<InviteTeacherDto> = (input) => {
    return inviteTeacher({
      variables: {
        input: { ...input, roles: [role] },
      },
    });
  };

  useEffect(() => {
    if (error) {
      setError('email', { type: error.name, message: error.message });
    }
  }, [error, setError, setFocus]);

  useEffect(() => {
    if (isSubmitSuccessful && !error && props.onClose != null) {
      infoAlert(`${capitalizeFirstLetter(role.toLowerCase())} invited!`);
      props.refetch();
      //@ts-ignore
      setTimeout(() => props.onClose(), 0);

      reset();
    }
  }, [error, isSubmitSuccessful, props, reset, role]);

  return (
    <ScInviteTeacherModal className="!bg-white dark:!bg-slate-800" ref={ref}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="title-text">
            <UsersIcon width={40} height={32} color={colorSecondaryGrayDark} />
            <TitleH2>Add team members</TitleH2>
            <TextSmall color={colorSecondaryGrayDark}>
              You haven’t added any team members to your project yet. As the
              owner of this project, you can manage team member permissions.
            </TextSmall>
          </div>
          <div className="row">
            <div className="input-box">
              <InputForm<InviteTeacherDto>
                id="email"
                placeholder="teacher@email.com"
                disabled={loading}
                required
                type="email"
              />
              <TextSmall
                className="role-btn !top-[14px]"
                color={colorSecondaryVioletDark}
                onClick={handleOpen}
                ref={visibleRef}
              >
                {capitalizeFirstLetter(role.toLowerCase())}
                <ChevronDown />
              </TextSmall>
              {isComponentVisible && (
                <RoleOptions
                  {...{ setRole, openerRef, handleOpen, currentRole: role }}
                />
              )}
            </div>

            <Button
              type="submit"
              disabled={Boolean(Object.keys(errors).length)}
              isLoading={loading}
              fullWidth={true}
            >
              Send invite
            </Button>
          </div>
        </form>
      </FormProvider>
    </ScInviteTeacherModal>
  );
});

export interface RoleOptionsProps {
  openerRef: MutableRefObject<any>;

  handleOpen: () => void;
  currentRole: Role;
  setRole?: React.Dispatch<React.SetStateAction<Role>>;
  onClick?: (role: Role) => void;
}

export const RoleOptions: FC<RoleOptionsProps> = ({
  openerRef,
  setRole,
  handleOpen,
  currentRole,
  onClick,
}) => {
  const handleClick = (role: Role) => {
    if (setRole != null) setRole(role);
    if (onClick != null) onClick(role);
    handleOpen();
  };

  const roles = useMemo(() => [Role.TEACHER, Role.PLANNER], []);

  const { isLight } = getSiteTheme();

  return (
    <ScRoleOptions
      className={`${isLight ? '!bg-white' : '!bg-slate-700'}`}
      ref={openerRef}
    >
      {roles.map((item) => (
        <TextSmall
          className="item"
          key={item}
          onClick={() => handleClick(item)}
        >
          {capitalizeFirstLetter(item.toLowerCase())}
          {currentRole === item && (
            <Check color={theme.colorSecondaryVioletDark} width={16} />
          )}
        </TextSmall>
      ))}
    </ScRoleOptions>
  );
};
