import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetActivitiesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetActivitiesQuery = {
  __typename?: 'Query';
  Activities: Array<{
    __typename?: 'ActivityEntity';
    id: string;
    title: string;
    total_sales: number;
    available_spots: number;
    spots: number;
    total_classes: number;
    count_attendees: number;
    createdDate?: any | null;
    updatedDate?: any | null;
    images: Array<{
      __typename?: 'ActivityImageEntity';
      id: string;
      filename: string;
      link: string;
    }>;
  }>;
};

export const GetActivitiesDocument = gql`
  query GetActivities {
    Activities {
      id
      title
      total_sales
      available_spots
      spots
      total_classes
      count_attendees
      createdDate
      updatedDate
      images {
        id
        filename
        link
      }
    }
  }
`;

/**
 * __useGetActivitiesQuery__
 *
 * To run a query within a React component, call `useGetActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActivitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActivitiesQuery,
    GetActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(
    GetActivitiesDocument,
    options
  );
}
export function useGetActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivitiesQuery,
    GetActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(
    GetActivitiesDocument,
    options
  );
}
export type GetActivitiesQueryHookResult = ReturnType<
  typeof useGetActivitiesQuery
>;
export type GetActivitiesLazyQueryHookResult = ReturnType<
  typeof useGetActivitiesLazyQuery
>;
export type GetActivitiesQueryResult = Apollo.QueryResult<
  GetActivitiesQuery,
  GetActivitiesQueryVariables
>;
