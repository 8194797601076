import React, { FC } from 'react';
import { ScCheckbox } from './styled';
import { Checkbox as CatalystCheckbox } from 'components/catalyst-elements/checkbox';

interface CheckboxProps {
  checked: boolean;
  onClick?: () => void;
  label?: string;
  disabled?: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({
  checked,
  onClick,
  label,
  disabled,
}) => {
  return (
    <ScCheckbox onClick={onClick} onChange={onClick}>
      <CatalystCheckbox
        checked={checked}
        disabled={disabled != null ? disabled : false}
      ></CatalystCheckbox>
      {label != null && <p className="label-text">{label}</p>}
    </ScCheckbox>
  );
};
