export enum ColorsEnums {
  violet = 'violet',
  white = 'white',
  black = '#000000',
  error = 'error',
  link = 'link',
}

export enum PermissionStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  PROCESSING = 'PROCESSING',
  WAISTED = 'WAISTED',
}

export enum Role {
  OWNER = 'OWNER',
  TEACHER = 'TEACHER',
  PLANNER = 'PLANNER',
  ALL = 'all',
}

export enum ActivityStatusType {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
}

export enum MeetingClassStatus {
  AWAIT = 'await',
  ONGOING = 'ongoing',
  COMPLETED = 'completed',
  ARCHIVED = 'archived',
}

export enum NotificationEnum {
  WELCOME = 'WELCOME',
  REMINDER = 'REMINDER',
  INFORMATION = 'INFORMATION',
  CUSTOM = 'CUSTOM',
}

export enum NotificationPosition {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
}

export enum NotificationPositionMeeting {
  FIRST = 'FIRST',
  LAST = 'LAST',
}

export enum EmailTypeEnum {
  successBooking = 'Success booking',
  cancelBooking = 'Cancel booking',
  informationNotification = 'Information notification',
  reminderNotification = 'Reminder notification',
  messageToClass = 'Message to class',
}

export enum IntegrationPlatform {
  SHOPIFY = 'shopify',
  AIRBNB = 'airbnb',
}
