import { ApolloProvider } from '@apollo/client';
import { FC } from 'react';

import { useGraphqlClientActions } from './actions';
import { useTokens } from '../tokens/context';

export const GraphqlClientProvider: FC = ({ children }) => {
  const { tokens } = useTokens();

  const { createApolloClient } = useGraphqlClientActions({ tokens });

  return (
    <ApolloProvider client={createApolloClient()}>{children}</ApolloProvider>
  );
};
