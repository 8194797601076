import styled from 'styled-components/macro';

export const ScMoveAttendeeModal = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  max-width: 1025px;
  margin: 40px auto;
  height: 654px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px;
  position: relative;
  & > h2 {
    margin-bottom: 16px;
  }
  & > .table {
    & > table {
      & > thead {
        & > tr {
          & > th {
          }
        }
      }
      & > tbody {
        & > tr {
          cursor: pointer;
          & > td {
            & > .class-title {
              & > .custom-checkbox {
                width: 16px;
                height: 16px;
                border-radius: 90px;
                border: 1px solid
                  ${({ theme }) => theme.colorSecondaryGrayLight};
                display: inline-block;
              }
              & > .custom-checkbox-active {
                border: 4px solid ${({ theme }) => theme.colorPrimaryViolet};
              }
            }
          }
        }
        & > tr:not(:last-child) {
        }
      }
    }
    & > .empty {
    }
  }
  & > .bottom {
    position: absolute;
    bottom: 24px;
    right: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }
`;
