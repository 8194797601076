import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddAttendeeMutationVariables = Types.Exact<{
  activityClassId: Types.Scalars['String'];
  input: Types.BookInput;
}>;

export type AddAttendeeMutation = {
  __typename?: 'Mutation';
  AddAttendee: { __typename?: 'OrderEntity'; id: string };
};

export const AddAttendeeDocument = gql`
  mutation AddAttendee($activityClassId: String!, $input: BookInput!) {
    AddAttendee(activityClassId: $activityClassId, input: $input) {
      id
    }
  }
`;
export type AddAttendeeMutationFn = Apollo.MutationFunction<
  AddAttendeeMutation,
  AddAttendeeMutationVariables
>;

/**
 * __useAddAttendeeMutation__
 *
 * To run a mutation, you first call `useAddAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAttendeeMutation, { data, loading, error }] = useAddAttendeeMutation({
 *   variables: {
 *      activityClassId: // value for 'activityClassId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAttendeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAttendeeMutation,
    AddAttendeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAttendeeMutation, AddAttendeeMutationVariables>(
    AddAttendeeDocument,
    options
  );
}
export type AddAttendeeMutationHookResult = ReturnType<
  typeof useAddAttendeeMutation
>;
export type AddAttendeeMutationResult =
  Apollo.MutationResult<AddAttendeeMutation>;
export type AddAttendeeMutationOptions = Apollo.BaseMutationOptions<
  AddAttendeeMutation,
  AddAttendeeMutationVariables
>;
