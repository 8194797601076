import { CrossProps } from './index';
import styled from 'styled-components';

type ScCrossProps = Omit<CrossProps, 'onClick'>;

export const ScCross = styled.div<ScCrossProps>`
  right: ${({ right }) => (right != null ? `${right}px` : '8px')};
  top: ${({ top }) => (top != null ? `${top}px` : '8px')};
  position: absolute;
  & > button {
    width: 100%;
    height: 100%;
    & > svg {
      width: ${({ size }) => (size != null ? `${size}px` : '12px')};
      color: ${({ color, theme }) =>
        color != null ? color : theme.colorPrimaryBlack};
      cursor: pointer;
    }
  }
`;
