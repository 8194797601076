import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePayByInvoiceMutationVariables = Types.Exact<{
  input: Types.CreatePayByInvoiceInput;
}>;

export type CreatePayByInvoiceMutation = {
  __typename?: 'Mutation';
  CreatePayByInvoice: { __typename?: 'PayByInvoiceEntity'; id: string };
};

export const CreatePayByInvoiceDocument = gql`
  mutation CreatePayByInvoice($input: CreatePayByInvoiceInput!) {
    CreatePayByInvoice(input: $input) {
      id
    }
  }
`;
export type CreatePayByInvoiceMutationFn = Apollo.MutationFunction<
  CreatePayByInvoiceMutation,
  CreatePayByInvoiceMutationVariables
>;

/**
 * __useCreatePayByInvoiceMutation__
 *
 * To run a mutation, you first call `useCreatePayByInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayByInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayByInvoiceMutation, { data, loading, error }] = useCreatePayByInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePayByInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePayByInvoiceMutation,
    CreatePayByInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePayByInvoiceMutation,
    CreatePayByInvoiceMutationVariables
  >(CreatePayByInvoiceDocument, options);
}
export type CreatePayByInvoiceMutationHookResult = ReturnType<
  typeof useCreatePayByInvoiceMutation
>;
export type CreatePayByInvoiceMutationResult =
  Apollo.MutationResult<CreatePayByInvoiceMutation>;
export type CreatePayByInvoiceMutationOptions = Apollo.BaseMutationOptions<
  CreatePayByInvoiceMutation,
  CreatePayByInvoiceMutationVariables
>;
