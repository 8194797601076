import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StripeAccountQueryVariables = Types.Exact<{ [key: string]: never }>;

export type StripeAccountQuery = {
  __typename?: 'Query';
  StripeAccount: {
    __typename?: 'StripeAccountRespond';
    id: string;
    country: string;
    default_currency: string;
    charges_enabled: boolean;
    details_submitted: boolean;
    payouts_enabled: boolean;
    requirements?: {
      __typename?: 'Requirement';
      current_deadline?: number | null;
      currently_due?: Array<string> | null;
      disabled_reason?: string | null;
      eventually_due?: Array<string> | null;
      past_due?: Array<string> | null;
      pending_verification?: Array<string> | null;
      alternatives?: Array<{
        __typename?: 'Alternative';
        alternative_fields_due: Array<string>;
        original_fields_due: Array<string>;
      }> | null;
      errors?: Array<{
        __typename?: 'StripeError';
        code: string;
        reason: string;
        requirement: string;
      }> | null;
    } | null;
    settings: {
      __typename?: 'Settings';
      payouts?: {
        __typename?: 'Payouts';
        schedule: {
          __typename?: 'Schedule';
          delay_days: number;
          interval: string;
          monthly_anchor?: number | null;
          weekly_anchor?: string | null;
        };
      } | null;
    };
    external_accounts?: {
      __typename?: 'ExternalAccountList';
      object: string;
      has_more: boolean;
      url: string;
      data: Array<{
        __typename?: 'BankAccount';
        account?: string | null;
        account_holder_name?: string | null;
        account_holder_type?: string | null;
        bank_name?: string | null;
        country: string;
        currency: string;
        customer?: string | null;
        id: string;
        last4: string;
        object: string;
        routing_number?: string | null;
        status: string;
        account_type?: string | null;
        default_for_currency: boolean;
        available_payout_methods: Array<string>;
      }>;
    } | null;
  };
};

export const StripeAccountDocument = gql`
  query StripeAccount {
    StripeAccount {
      id
      country
      default_currency
      charges_enabled
      details_submitted
      payouts_enabled
      requirements {
        alternatives {
          alternative_fields_due
          original_fields_due
        }
        current_deadline
        currently_due
        disabled_reason
        errors {
          code
          reason
          requirement
        }
        eventually_due
        past_due
        pending_verification
      }
      settings {
        payouts {
          schedule {
            delay_days
            interval
            monthly_anchor
            weekly_anchor
          }
        }
      }
      external_accounts {
        object
        data {
          account
          account_holder_name
          account_holder_type
          bank_name
          country
          currency
          customer
          id
          last4
          object
          routing_number
          status
          account_type
          default_for_currency
          available_payout_methods
        }
        has_more
        url
      }
    }
  }
`;

/**
 * __useStripeAccountQuery__
 *
 * To run a query within a React component, call `useStripeAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    StripeAccountQuery,
    StripeAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StripeAccountQuery, StripeAccountQueryVariables>(
    StripeAccountDocument,
    options
  );
}
export function useStripeAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StripeAccountQuery,
    StripeAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StripeAccountQuery, StripeAccountQueryVariables>(
    StripeAccountDocument,
    options
  );
}
export type StripeAccountQueryHookResult = ReturnType<
  typeof useStripeAccountQuery
>;
export type StripeAccountLazyQueryHookResult = ReturnType<
  typeof useStripeAccountLazyQuery
>;
export type StripeAccountQueryResult = Apollo.QueryResult<
  StripeAccountQuery,
  StripeAccountQueryVariables
>;
