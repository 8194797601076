import { ButtonProps, ButtonContainerProps } from './index';
import styled from 'styled-components/macro';
import { buttonColorStyles, buttonHoverColorStyles } from './helpers';

export const ScButton = styled.button<ButtonProps>`
  padding: 10.5px 16px;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  transition: 0.1s ease-in;
  position: relative;
  white-space: nowrap;
  width: ${({ fullWidth }) =>
    fullWidth != null && fullWidth ? '100%' : 'fit-content'};
  ${({ color, isLoading }) => buttonColorStyles(color, isLoading)}
  :hover {
    ${({ color, isLoading, disabled }) =>
      buttonHoverColorStyles(color, isLoading, disabled)}
  }
  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  :disabled {
    cursor: ${({ isLoading }) => (isLoading ? 'wait' : 'not-allowed')};
    opacity: 0.5;
  }
`;

export const ScButtonContainer = styled.div<ButtonContainerProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ direction }) => direction};
  gap: ${({ gap }) => gap};
`;
