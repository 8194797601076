import { SettingTab } from 'components/modules/Settings';
import { createContext, FC, useState } from 'react';

type SettingsContexType = {
  activeTab: SettingTab;
  setActiveTab: React.Dispatch<React.SetStateAction<SettingTab>>;
};

export const SettingsContex = createContext<SettingsContexType>(null as any);

export const SettingsProvider: FC = ({ children }) => {
  const [activeTab, setActiveTab] = useState(SettingTab.PROFILE);
  return (
    <SettingsContex.Provider value={{ activeTab, setActiveTab }}>
      {children}
    </SettingsContex.Provider>
  );
};
