import styled from 'styled-components';

export const ScTerms = styled.div`
  & > .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  & > .body {
    margin-top: 24px;
    height: 554px;
    & > .quill {
      height: 100%;
    }
    form {
      .input-container {
        margin-top: 0px;
      }
      input {
        background-color: red;
        margin-top: 0px;
      }
    }
  }
`;
