import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { TitleH2 } from 'components/styles/common';
import { Button } from 'components/elements/Button';
import { ScTerms } from './styled';
import { ScContent } from '../styled';
import 'react-quill/dist/quill.snow.css';
import { useUpdateTermsMutation } from 'graphql/mutations/settings/__generated__/updateTerms';
import { useCurrentOrganizationQuery } from 'graphql/queries/organizations/__generated__/getCurrentOrganization.generated';
import { infoAlert } from '../../../../helpers/alerts';

export const Terms = () => {
  const [value, setValue] = useState('');

  const [update] = useUpdateTermsMutation();
  const { data } = useCurrentOrganizationQuery();

  useEffect(() => {
    if (data != null)
      setValue(data.CurrentOrganization.termsAndConditions || '');
  }, [data]);

  const onClick = () => {
    update({
      variables: {
        input: {
          //@ts-ignore
          termsAndConditions: value,
        },
      },
    }).then(({ data }) => {
      if (data != null) {
        infoAlert('Terms updated');
      }
    });
  };

  return (
    <ScContent>
      <ScTerms>
        <div className="header">
          <TitleH2>Terms</TitleH2>
          <Button onClick={onClick}>Save</Button>
        </div>
        <div className="body">
          <ReactQuill
            className="bg-white dark:bg-[rgba(1,1,1,0)]"
            theme="snow"
            value={value}
            onChange={setValue}
          />
        </div>
      </ScTerms>
    </ScContent>
  );
};
