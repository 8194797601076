import React, { FC, useMemo } from 'react';
import { ColorRing } from 'react-loader-spinner';
import { ColorsEnums } from 'helpers/enums/common';
import { theme } from 'theme';
import { getSiteTheme } from 'hooks/getSiteTheme';

interface SpinnerProps {
  color?: ColorsEnums;
  size?: number;
}

export const SpinnerInBox: FC<SpinnerProps> = (props) => {
  return (
    <div className="spinner-in-box">
      <Spinner {...props} />
    </div>
  );
};

export const Spinner: FC<SpinnerProps> = ({ color, size }) => {
  const options = useMemo(() => {
    let initial = {
      height: '32px',
      width: '32px',
      color: theme.colorSecondaryVioletDark,
      secondaryColor: theme.colorWhite,
      strokeWidth: 4,
      ariaLabel: 'loading',
    };
    if (color != null) {
      switch (color) {
        case ColorsEnums.white:
          initial = {
            ...initial,
            color: theme.colorWhite,
            secondaryColor: theme.colorPrimaryViolet,
          };
          break;
        case ColorsEnums.violet:
        default:
          initial = {
            ...initial,
            color: theme.colorSecondaryVioletDark,
            secondaryColor: theme.colorWhite,
          };
          break;
      }
    }
    if (size != null) {
      initial = { ...initial, height: `${size}`, width: `${size}` };
    }
    return initial;
  }, [color, size]);

  const { isLight } = getSiteTheme();

  const colorSpiner = isLight ? 'grey' : 'white';

  return (
    <div className="spinner-div">
      <ColorRing
        {...options}
        colors={[
          colorSpiner,
          colorSpiner,
          colorSpiner,
          colorSpiner,
          colorSpiner,
        ]}
      />
    </div>
  );
};
