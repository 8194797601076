import axios from 'lib/axios';

export type ResetPasswordDto = {
  password: string;
  passwordConfirm: string;
  token: string;
};
export type ResetPasswordTokens = {
  access_token: string;
  refresh_token: string;
};
export type ResetPasswordRespons = any;

export const resetPasswordUser = async (
  data: ResetPasswordDto
): Promise<ResetPasswordRespons> => {
  const { token, ...rest } = data;

  return await axios.post(
    `/controller/auth/user/reset-password?token=${token}`,
    rest
  );
};
