import React, { FC } from 'react';
import { ScHandleWrapper } from '../styled';

export interface DragHandleProps {
  isDragging?: boolean;
}

const DragHandle: FC<DragHandleProps> = ({ ...rest }) => {
  return (
    <ScHandleWrapper {...rest}>
      <div className="dots">
        <div className="left">
          <div />
          <div />
          <div />
        </div>
        <div className="right">
          <div />
          <div />
          <div />
        </div>
      </div>
    </ScHandleWrapper>
  );
};

export default DragHandle;
