import { Button } from 'components/elements/Button';
import InputForm from 'components/elements/inputs';
import { TitleH2 } from 'components/styles/common';
import { useCreateCheckoutFieldMutation } from 'graphql/mutations/settings/__generated__/createCheckoutField.generated';
import { useUpdateCheckoutFieldMutation } from 'graphql/mutations/settings/__generated__/updateCheckoutField.generated';
import { errorAlert, successAlert } from 'helpers/alerts';
import { ColorsEnums } from 'helpers/enums/common';
import { forwardRef } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';

import { CreateCheckoutFieldDto } from './schema';

import { ScCreateFieldModal } from './styled';
import { getSiteTheme } from 'hooks/getSiteTheme';

interface CreateFieldModalProps {
  methods: UseFormReturn<CreateCheckoutFieldDto, any>;
  handleClose: () => void;
  fieldInfo: {
    id: string;
    sortOrder: number;
  } | null;
  refetch: any;
}

const CreateFieldModal = forwardRef<HTMLDivElement, CreateFieldModalProps>(
  (props, ref) => {
    const { methods, handleClose, fieldInfo, refetch } = props;

    const { handleSubmit } = methods;

    const [create, { loading: loadingCreate }] =
      useCreateCheckoutFieldMutation();
    const [update, { loading: loadingUpdate }] =
      useUpdateCheckoutFieldMutation();

    const onSubmit: SubmitHandler<CreateCheckoutFieldDto> = (input) => {
      if (fieldInfo == null) {
        create({
          variables: {
            input: { ...input, type: input.type.toLowerCase() },
          },
        }).then(({ data, errors }) => {
          if (data != null) {
            successAlert('Success created');
            refetch();
            handleClose();
          }
          if (errors != null) {
            errorAlert(`Some error: ${errors[0].message}`);
          }
        });
      } else {
        update({
          variables: {
            input: {
              ...input,
              type: input.type.toLowerCase(),
              id: fieldInfo.id,
              sortOrder: fieldInfo.sortOrder,
            },
          },
        }).then(({ data, errors }) => {
          if (data != null) {
            successAlert('Success updated');
            refetch();
            handleClose();
          }
          if (errors != null) {
            errorAlert(`Some error: ${errors[0].message}`);
          }
        });
      }
    };

    const { isLight } = getSiteTheme();

    return (
      <ScCreateFieldModal
        className={`${isLight ? 'bg-white' : 'bg-slate-800'}`}
        ref={ref}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TitleH2 className="title">Add new field</TitleH2>
            <InputForm<CreateCheckoutFieldDto>
              id="name"
              type="text"
              label="Field name"
              required
            />
            <InputForm<CreateCheckoutFieldDto>
              id="description"
              type="text"
              label="Help text"
            />
            <InputForm<CreateCheckoutFieldDto>
              id="type"
              type="text"
              label="Field type"
              readOnly
            />
            <div className="button-btn">
              <Button
                type="button"
                color={ColorsEnums.white}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                isLoading={loadingCreate || loadingUpdate}
                disabled={loadingCreate || loadingUpdate}
              >
                Save
              </Button>
            </div>
          </form>
        </FormProvider>
      </ScCreateFieldModal>
    );
  }
);

export default CreateFieldModal;
