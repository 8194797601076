import * as React from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';
import Select, { StylesConfig } from 'react-select';
import makeAnimated from 'react-select/animated';

import { ErrorText, Label, OptionType } from '../common';
import { ScInputForm } from '../styled';
import { getSiteTheme } from 'hooks/getSiteTheme';

const animatedComponents = makeAnimated();
const { isLight } = getSiteTheme();

export type OptionTypeSelect = {
  label: string;
  value: string;
  avatarUrl?: string;
};

export const colourStyles: StylesConfig<any> = {
  control: (base: any, state: any) => ({
    ...base,
    fontSize: 14,
    minHeight: 'unset',
    boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)',
    backgroundColor: isLight ? '#ffffff' : 'rgb(255 255 255 / 0.05)',
    borderRadius: '0.5rem',
    borderColor: state.isFocused
      ? isLight
        ? 'black'
        : '#ffffff'
      : isLight
      ? 'rgba(9, 9, 11, 0.18)'
      : 'rgb(255 255 255 / 0.1)',
    ':hover': {
      borderColor: state.isFocused
        ? isLight
          ? 'black'
          : '#ffffff'
        : isLight
        ? 'rgba(9, 9, 11, 0.3)'
        : 'rgb(255 255 255 / 0.2)',
    },
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: '5.5px',
  }),
  menu: (styles) => ({
    ...styles,
    top: 33,
    backgroundColor: isLight ? 'white' : 'rgb(39, 39, 42)',
    border: '1px solid rgb(63, 63, 70)',
    borderRadius: '0.5rem',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,

      borderRadius: '0.5rem',
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? isLight
          ? '#f0f0f0'
          : 'rgb(24, 24, 27)'
        : isFocused
        ? isLight
          ? '#f0f0f0'
          : 'rgb(24, 24, 27)'
        : !isLight
        ? 'rgb(39, 39, 42)'
        : undefined,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? '#000000'
        : isFocused
        ? '#000000'
        : '#000000',
      cursor: 'pointer',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? isLight
              ? '#f0f0f0'
              : 'rgba(24, 24, 27, 0.8)'
            : isLight
            ? '#f0f0f0'
            : 'rgba(24, 24, 27, 0.8)'
          : undefined,
      },
    };
  },
};

export type SelectProps<T extends FieldValues = FieldValues> = {
  label?: string;
  id: FieldPath<T>;
  placeholder?: string;
  helperText?: React.ReactNode;
  type?: React.HTMLInputTypeAttribute;
  readOnly?: boolean;
  hideError?: boolean;
  validation?: RegisterOptions;
  options: OptionTypeSelect[] | [];
  FormatOptionLabel?: any;
  initialValue?: OptionType;
} & React.ComponentPropsWithoutRef<'select'>;

export default function SearchableSelect<T extends FieldValues = FieldValues>({
  label,
  placeholder = '',
  helperText,
  id,
  type = 'text',
  readOnly = false,
  hideError = false,
  validation,
  options,
  disabled,
  FormatOptionLabel,
  initialValue,
}: SelectProps<T>) {
  const {
    formState: { errors },
    control,
  } = useFormContext<T>();

  return (
    <ScInputForm>
      <Label label={label} id={id} />
      <div className="input-container">
        <Controller
          control={control}
          name={id}
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                id={id}
                //@ts-ignore
                options={options}
                placeholder={placeholder}
                value={
                  options.find((item) => item.value === value) != null
                    ? options.find((item) => item.value === value)
                    : initialValue != null
                    ? initialValue
                    : null
                }
                onChange={(val) => {
                  onChange(val?.value as any);
                }}
                components={animatedComponents}
                //@ts-ignore
                styles={colourStyles}
                isDisabled={disabled}
                formatOptionLabel={(item, formatOptionLabelMeta) => {
                  return FormatOptionLabel != null ? (
                    <FormatOptionLabel {...item} />
                  ) : item.label != null ? (
                    <p className={`${isLight ? 'text-black' : 'text-white'}`}>
                      {item.label}
                    </p>
                  ) : (
                    placeholder
                  );
                }}
                defaultValue={initialValue}
              />
            );
          }}
        />
      </div>
      <ErrorText
        hideError={hideError}
        helperText={helperText}
        errors={errors}
        id={id}
      />
    </ScInputForm>
  );
}
