import { Context, createContext, useContext } from 'react';

import { TokenData } from 'http-requests/auth/login';

import { TokensActions } from './actions';
import { Tokens } from './provider';

export type TokensContextPayload = TokensActions & {
  tokens: Tokens;
  payload?: TokenData;
  globalLoading: boolean;
  setGlobalLoading: (state: boolean) => void;
};

const defaultValue = {
  tokens: {
    access_token:
      localStorage.getItem('access_token') != null
        ? `${localStorage.getItem('access_token')}`
        : '',
    refresh_token: null,
    tokenType: null,
  },
};
export const TokensContext =
  createContext<Partial<TokensContextPayload>>(defaultValue);

export const useTokens = () => {
  return useContext(TokensContext as Context<TokensContextPayload>);
};
