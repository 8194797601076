import * as yup from 'yup';

export type CreatePayByInvoiceDto = {
  additionalDetails?: string;
  paymentInstructions?: string;
};

export const CreatePayByInvoiceSchema: yup.SchemaOf<CreatePayByInvoiceDto> =
  yup.object({
    additionalDetails: yup.string(),
    paymentInstructions: yup.string(),
  });
