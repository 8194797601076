import { yupResolver } from '@hookform/resolvers/yup';
import InputForm from 'components/elements/inputs';
import { FC } from 'react';
import { Search } from 'react-feather';
import { useForm, FormProvider, UseFormReturn } from 'react-hook-form';
import { SearchDto, searchSchema } from './search';

export const useSearch = () => {
  const methods = useForm<SearchDto>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    resolver: yupResolver(searchSchema),
    defaultValues: {
      searchValue: '',
    },
  });
  const { watch } = methods;

  const searchValue = watch('searchValue');

  const searchData = (data: any, key: string, searchValue: string) => {
    return data.filter((item: any) => {
      const name = item[key].toLowerCase();
      const search =
        searchValue != null ? searchValue.toLowerCase() : searchValue;
      return name.indexOf(search) > -1;
    });
  };

  return {
    searchValue,
    searchData,
    methods,
  };
};

interface SearchComponentProps {
  methods: UseFormReturn<SearchDto, any>;
  loading?: boolean;
}

export const SearchComponent: FC<SearchComponentProps> = ({
  methods,
  loading,
}) => {
  return (
    <div className="search-compoenet">
      <FormProvider {...methods}>
        <form>
          <InputForm<SearchDto>
            id="searchValue"
            placeholder="Search"
            type="text"
            Icon={Search}
            disabled={loading}
          />
        </form>
      </FormProvider>
    </div>
  );
};
