import { FC, useCallback } from 'react';
import { errorAlert } from 'helpers/alerts';
import { Button } from 'components/elements/Button';
import { ScPaymentComponent } from './styled';
import { TitleH3 } from 'components/styles/common';
import { useStripeOnboardingLinkMutation } from 'graphql/mutations/stipe/__generated__/getStripeOnboardingLink.generated';
import { useUpdatePaymentMethodMutation } from 'graphql/mutations/payment/__generated__/updatePaymentMethod.generated';
import { GetPaymentMethodsInfoQuery } from 'graphql/queries/payment/__generated__/getPaymentMethodsInfo.generated';
import { ColorsEnums } from 'helpers/enums/common';
import visa_logo from './visa_logo.png';
import mastercard_logo from './mastercard_logo.png';
import { Switch } from 'components/catalyst-elements/switch';
interface SetupPaymentOnStripeProps {
  id?: string;
  chargesEnabled?: boolean;
  paymentMethodsInfo?: GetPaymentMethodsInfoQuery;
  refetch: any;
  loadingGetPaymentMethodsInfo: boolean;
}

export const SetupPaymentOnStripe: FC<SetupPaymentOnStripeProps> = ({
  id,
  chargesEnabled,
  paymentMethodsInfo,
  refetch,
  loadingGetPaymentMethodsInfo,
}) => {
  const [getOnboardingLink] = useStripeOnboardingLinkMutation();

  const [
    updatePaymentMethodStripe,
    { loading: loadingUpdatePaymentMethodStripe },
  ] = useUpdatePaymentMethodMutation();

  const onBoarding = async () => {
    try {
      getOnboardingLink().then(({ data }) =>
        window.location.assign(`${data?.StripeOnboardingLink.url}`)
      );
    } catch (error) {
      errorAlert('Get onboarding link');
    }
  };

  const switchStripe = useCallback(() => {
    if (paymentMethodsInfo != null)
      updatePaymentMethodStripe({
        variables: {
          input: {
            isStripeActive:
              !paymentMethodsInfo.CurrentOrganization.isStripeActive,
            isPayByInvoiceActive:
              paymentMethodsInfo.CurrentOrganization.isPayByInvoiceActive,
          },
        },
      }).then(({ data }) => {
        if (data != null) refetch();
      });
  }, [paymentMethodsInfo, refetch, updatePaymentMethodStripe]);

  return (
    <ScPaymentComponent border>
      <div className="top">
        <div className="title">
          <TitleH3>Credit Card</TitleH3>
          <div className="toggle">
            <label>
              <Switch
                defaultChecked={
                  paymentMethodsInfo?.CurrentOrganization.isStripeActive
                }
                onChange={switchStripe}
                disabled={
                  loadingUpdatePaymentMethodStripe ||
                  loadingGetPaymentMethodsInfo
                }
              />
            </label>
          </div>
        </div>

        <Button
          onClick={() => onBoarding()}
          color={chargesEnabled ? ColorsEnums.white : ColorsEnums.violet}
        >
          {chargesEnabled ? 'Manage' : 'Setup'}
        </Button>
      </div>
      <div className="card-template">
        <div className="top">
          {paymentsSystems.map((item) => (
            <div className="payment-item" key={item.title}>
              <img src={item.logo} alt={item.title} />
            </div>
          ))}
        </div>
        <div className="bottom">
          <div className="item">
            <p>Credit card rate</p>
            <p>As low as 1.8% +DDK 1.80</p>
          </div>
          <div className="item">
            <p>Transaction fee</p>
            <p>0%</p>
          </div>
        </div>
      </div>
      {/* 
      OLD VERSION <ScSetupPaymen>
      <div>
        <TitleH3>Stripe | Credit card</TitleH3>
        {chargesEnabled ? (
          <TextSmall color={colorSecondaryGrayDark}>Account ID: {id}</TextSmall>
        ) : (
          <TextSmall color={colorErrorRedDark}>
            Account is not verified
          </TextSmall>
        )}
        {chargesEnabled && (
          <Button onClick={() => onBoarding()} color={ColorsEnums.white}>
            Stripe settings
          </Button>
        )}
      </div>
      {chargesEnabled && paymentMethodsInfo != null ? (
        <div className="toggle">
          <label>
            <Toggle
              defaultChecked={
                paymentMethodsInfo.CurrentOrganization.isStripeActive
              }
              icons={{
                checked: null,
                unchecked: null,
              }}
              onChange={switchStripe}
              disabled={
                loadingUpdatePaymentMethodStripe || loadingGetPaymentMethodsInfo
              }
            />
          </label>
        </div>
      ) : (
        <Button onClick={() => onBoarding()}>Setup payouts on Stripe</Button>
      )} */}
    </ScPaymentComponent>
  );
};

const paymentsSystems = [
  {
    title: 'visa_logo',
    logo: visa_logo,
  },
  {
    title: 'mastercard_logo',
    logo: mastercard_logo,
  },
];
