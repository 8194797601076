import jwt_encode from 'jwt-encode';
import jwt_decode from 'jwt-decode';

const SECRET = (Math.random() + 1).toString(36).substring(7);

export const encodeJWT = (data: any) => jwt_encode(data, SECRET);

export function decodeJWT<T = any>(token: string) {
  return jwt_decode<T>(`${token}`);
}
