import React, { forwardRef, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ScNotificationModal } from './styled';
import { NotificationDto, NotificationSchema } from './schema';
import { TitleH2 } from 'components/styles/common';
import { capitalizeFirstLetter } from 'helpers/common';
import InputForm from 'components/elements/inputs';
import { hoursToString } from 'helpers/dayjs/formats';
import TextareaForm from 'components/elements/inputs/textarea';
import { Button } from 'components/elements/Button';
import { useUpdateNotificationMutation } from 'graphql/mutations/settings/notifications/__generated__/updateNotification.generated';
import { errorAlert, infoAlert } from 'helpers/alerts';
import { useCreateNotificationMutation } from '../../../graphql/mutations/settings/notifications/__generated__/createNotification.generated';
import { UpdateNotificationInput } from '../../../types.generated';
import SelectForm from '../../elements/inputs/select';
import {
  NotificationPosition,
  NotificationPositionMeeting,
} from '../../../helpers/enums/common';
import { getSiteTheme } from 'hooks/getSiteTheme';

export interface NotificationModalProps {
  id?: string | null;
  type?: string | null;
  body?: string | null;
  timeToSendNotification?: string | null;
  positionToSendNotification?: string;
  positionMeetingToSendNotification?: string;
  replyEmail?: string | null;
  bccEmail?: string | null;
  name?: string | null;
  subject?: string | null;
  refetch: any;
  onClose: () => void;
  update?: (notification: UpdateNotificationInput) => void;
}

export const NotificationModal = forwardRef<
  HTMLDivElement,
  NotificationModalProps
>((props, ref) => {
  const [update] = useUpdateNotificationMutation();

  const {
    replyEmail,
    bccEmail,
    name,
    timeToSendNotification,
    subject,
    body,
    type,
    id,
    onClose,
    refetch,
    positionToSendNotification,
    positionMeetingToSendNotification,
  } = props;
  const typeName = type ? type : 'CUSTOM';
  const methods = useForm<NotificationDto>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    resolver: yupResolver(NotificationSchema),
    defaultValues: {
      recursive: true,
      replyEmail: replyEmail != null ? replyEmail : '',
      bccEmail: bccEmail != null ? bccEmail : '',
      name: name != null ? name : '',
      subject: subject != null ? subject : '',
      body: body != null ? body : '',
      days: timeToSendNotification != null ? +timeToSendNotification : 0,
      positionToSendNotification:
        positionToSendNotification as NotificationPosition,
      positionMeetingToSendNotification:
        positionMeetingToSendNotification as NotificationPositionMeeting,
    },
  });
  const {
    handleSubmit,
    setValue,
    watch,
    setFocus,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = methods;
  const [create, { loading, error }] = useCreateNotificationMutation();

  const watchBody = watch('body');

  const setVariable = useCallback(
    (watchBody: string, value: string) => {
      setValue('body', `${watchBody}${value}`);
      setFocus('body');
    },
    [setFocus, setValue]
  );

  const onSubmit: SubmitHandler<NotificationDto> = (input) => {
    const data = {
      replyEmail: input.replyEmail,
      bccEmail: input.bccEmail,
      name: input.name,
      timeToSendNotification: hoursToString(input.days),
      subject: input.subject,
      body: input.body,
      positionToSendNotification: input.positionToSendNotification,
      positionMeetingToSendNotification:
        input.positionMeetingToSendNotification,
    };
    Object.keys(data).forEach((key) => {
      //@ts-ignore
      if (data[key] === '') {
        //@ts-ignore
        data[key] = null;
      }
    });
    if (update != null && id != null) {
      update({ variables: { input: { ...data, id, recursive: true } } }).then(
        ({ data, errors }) => {
          if (data != null) {
            refetch();
            infoAlert('Notification updated!');
          } else if (errors != null) {
            errorAlert('Notification is not updated');
          }
        }
      );
      onClose();
      return;
    }
    return create({
      variables: {
        input: data,
      },
    });
  };

  useEffect(() => {
    if (isSubmitSuccessful && !error && onClose != null) {
      infoAlert('Notification created!');
      refetch();
      //@ts-ignore
      setTimeout(() => onClose(), 0);

      reset();
    }
  }, [error, errors, isSubmitSuccessful, onClose, refetch, reset]);

  const { isLight } = getSiteTheme();

  return (
    <ScNotificationModal
      className={`${isLight ? 'bg-white' : 'bg-slate-800'}`}
      ref={ref}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TitleH2 className="title">
            {capitalizeFirstLetter(typeName.toLowerCase())}
          </TitleH2>
          <InputForm<NotificationDto>
            id="name"
            disabled={loading}
            placeholder=""
            type="text"
            label="Name"
          />
          <InputForm<NotificationDto>
            id="days"
            disabled={loading}
            placeholder=""
            required
            type="number"
            label="Send notification time"
          />
          <SelectForm<NotificationDto>
            id="positionToSendNotification"
            label="Send notification position"
            required
          >
            {Object.values(NotificationPosition).map((value, index) => (
              <option key={index} value={value}>
                {value}
              </option>
            ))}
          </SelectForm>
          <SelectForm<NotificationDto>
            id="positionMeetingToSendNotification"
            label="Send notification which class"
            required
          >
            {Object.values(NotificationPositionMeeting).map((value, index) => (
              <option key={index} value={value}>
                {value}
              </option>
            ))}
          </SelectForm>
          <InputForm<NotificationDto>
            id="replyEmail"
            disabled={loading}
            placeholder=""
            type="email"
            label="Reply email"
          />
          <InputForm<NotificationDto>
            id="bccEmail"
            disabled={loading}
            placeholder=""
            type="email"
            label="Bcc email"
          />
          <InputForm<NotificationDto>
            id="subject"
            disabled={loading}
            placeholder=""
            type="text"
            label="Subject"
          />

          <div className="body">
            <p className="label">Body</p>
            <div className="variables">
              {NotificationVariables.map((item) => (
                <div
                  className={`${!isLight && '!text-white'}`}
                  key={item.value}
                  onClick={() => setVariable(watchBody, item.value)}
                >
                  {item.label}
                </div>
              ))}
            </div>
            <TextareaForm<NotificationDto>
              id="body"
              disabled={loading}
              placeholder=""
              required
            />
          </div>
          <div className="button-btn">
            <Button
              type="submit"
              disabled={Boolean(Object.keys(errors).length)}
              isLoading={loading}
            >
              {id != null ? 'Update' : 'Create'}
            </Button>
          </div>
        </form>
      </FormProvider>
    </ScNotificationModal>
  );
});

export const NotificationVariables = [
  {
    label: 'FirstName',
    value: '{{order.firstName}}',
  },
  {
    label: 'LastName',
    value: '{{order.lastName}}',
  },
  {
    label: 'ActivityClassStartDate',
    value: '{{formatDateTime activityClass.start}}',
  },
  {
    label: 'ActivityClassEndDate',
    value: '{{formatDateTime activityClass.end}}',
  },
  {
    label: 'MeetingDays',
    value: '{{> timelines}}',
  },
  {
    label: 'TeacherName',
    value: '{{teacher.name}}',
  },
  {
    label: 'LocationAddress',
    value: '{{location.address}}',
  },
  {
    label: 'OrganizationName',
    value: '{{activity.organization.name}}',
  },
];
