import { useMemo } from 'react';
import { useTable, useSortBy, Row, Cell } from 'react-table';
import { ChevronDown, ChevronUp } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { TextSmall, TitleH2 } from 'components/styles/common';
import { useSticky } from 'react-table-sticky';
import { getSiteTheme } from 'hooks/getSiteTheme';

interface TableProps<T extends object> {
  columns: Column[];
  data?: T[] | null;
  handleClick?: (row: Row<T>) => void;
  Link?: (cell: Cell<T, any>) => JSX.Element;
  isLoading?: boolean;
  emptyText?: string;
  emptyDescription?: string;
  EmptyElement?: JSX.Element;
  className?: string;
}

function Table<T extends object>({
  columns: columnsData,
  data: items,
  handleClick,
  Link,
  isLoading,
  emptyText,
  emptyDescription,
  EmptyElement,
  className,
}: TableProps<T>) {
  const columns = useMemo(() => columnsData, [columnsData]);
  console.log(columns, 'COL');
  const data = useMemo(() => (items != null ? (items as any[]) : []), [items]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy,
      useSticky
    );

  const { isLight } = getSiteTheme();

  return (
    <>
      {/* <ScTable {...{ isLoading }}> */}
      <div className="overflow-y-scroll h-full max-h-[700px]">
        <div
          className={`inline-block min-w-full py-2 align-middle relative ${
            isLight ? 'bg-transparent' : 'bg-zinc-900'
          } `}
        >
          <table
            {...getTableProps()}
            className={`min-w-full border-separate border-spacing-0 bg-transparent ${
              isLight ? 'divide-gray-300' : 'divide-gray-500'
            }`}
          >
            <thead
              className={`sticky z-10 bottom-0 ${
                isLight ? 'bg-transparent' : 'bg-slate-800'
              }`}
            >
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-4 px-3 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      {
                        //@ts-ignore
                        ...column.getHeaderProps(column.getSortByToggleProps())
                      }
                    >
                      {column.render('Header')}
                      <span className="chevron absolute">
                        {
                          //@ts-ignore
                          column.isSorted ? (
                            //@ts-ignore
                            column.isSortedDesc ? (
                              <ChevronDown height={16} />
                            ) : (
                              <ChevronUp height={16} />
                            )
                          ) : (
                            ''
                          )
                        }
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className={`divide-y  ${
                isLight
                  ? 'bg-white divide-gray-200'
                  : `bg bg-zinc-900 divide-gray-600`
              }`}
            >
              {isLoading != null && isLoading
                ? Array.from({ length: 30 }, (_, i) => i).map((item) => (
                    <tr className="group" key={`table-skeleton-tr-${item}`}>
                      {Object.keys(columnsData).map((item) => (
                        <td
                          key={`table-skeleton-td-${item}`}
                          className={`whitespace-nowrap px-3 py-4 text-sm table-cell border-b border-b-gray-200 dark:border-b-zinc-700 text-gray-500 dark:text-slate-300 first-of-type:font-bold group-last-of-type:border-none`}
                        >
                          <Skeleton
                            borderRadius={12}
                            style={{
                              width: '80%',
                              margin: '13.5px 0',
                            }}
                          />
                        </td>
                      ))}
                    </tr>
                  ))
                : rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr className="group" {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className={`whitespace-nowrap px-3 py-4 text-sm table-cell border-b border-b-gray-200 dark:border-b-zinc-700 text-gray-500 dark:text-slate-300 first-of-type:font-bold group-last-of-type:border-none`}
                            >
                              {Link != null ? (
                                Link(cell as any)
                              ) : (
                                <p
                                  onClick={() =>
                                    handleClick != null
                                      ? handleClick(row as any)
                                      : () => {}
                                  }
                                >
                                  {' '}
                                  {cell.render('Cell')}
                                </p>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          {!isLoading && (items?.length === 0 || items == null) && (
            <div
              className={`h-[150px] flex flex-col justify-center items-center ${
                isLight ? 'bg-white' : 'bg-zinc-900'
              }`}
            >
              <TitleH2>{emptyText != null ? emptyText : 'No data'}</TitleH2>
              <TextSmall>
                {emptyDescription != null
                  ? emptyDescription
                  : `You don’t have any data yet.`}
              </TextSmall>
              {EmptyElement != null && EmptyElement}
            </div>
          )}
        </div>
      </div>
    </>
    // </ScTable >
  );
}

export default Table;

type Column = {
  Header: string;
  accessor: string;
  Cell?: (props: any) => JSX.Element;
  className?: string;
};
