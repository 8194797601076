import * as yup from 'yup';

export type AddCommentDto = {
  text: string;
};

export const addCommentSchema: yup.SchemaOf<AddCommentDto> = yup
  .object({
    text: yup.string().required(),
  })
  .required();
