import axios from 'lib/axios';

export type ForgetPasswordDto = {
  email: string;
};
export type ForgetPasswordTokens = {
  access_token: string;
  refresh_token: string;
};
export type ForgetPasswordRespond = any;

export const forgetPasswordUser = async (
  data: ForgetPasswordDto
): Promise<ForgetPasswordRespond> => {
  return await axios
    .post('controller/auth/user/forgot-password', data)
    .then(({ data }) => data);
};
