import styled from 'styled-components';

export const ScOrganizationSettings = styled.div`
  & > .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
    & > div {
      width: 50%;
    }
  }
`;
