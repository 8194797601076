import { TitleH3 } from 'components/styles/common';
import { ScBillingInformation } from 'pages/Attendee/components/styled';
import React, { FC } from 'react';
import { EmailLogEntity, OrderEntity } from '../../../types.generated';
import { ScEmailLogItem } from './styled';
import { getFullDate } from '../../../helpers/dayjs/formats';
import { ColorsEnums, EmailTypeEnum } from '../../../helpers/enums/common';
import { getSiteTheme } from 'hooks/getSiteTheme';
import Status from 'components/elements/Status';
import { Button } from 'components/elements/Button';
import { useSendSuccessBookingEmailMutation } from 'graphql/mutations/orders/__generated__/SendSuccessEmail.generated';
import { successAlert } from 'helpers/alerts';
import { Spinner } from 'components/elements/Spinner';

interface EmailLogProps {
  order: OrderEntity;
  // handleResendEmail: (id: string) => void;
}

const EmailLog: FC<EmailLogProps> = ({ order }) => {
  const { isLight } = getSiteTheme();

  const [sendSuccessBookingEmail, { loading }] =
    useSendSuccessBookingEmailMutation();

  const handleClick = async (id: string) => {
    const a = await sendSuccessBookingEmail({ variables: { id } });

    a !== undefined && successAlert('The email was sent successfully');
  };

  return (
    <div className="z-10">
      <div className="flex justify-between items-center p-[24px]">
        <TitleH3>Email log</TitleH3>
        <Button
          color="blue"
          onClick={() => handleClick(order.id)}
          disabled={loading ? true : false}
        >
          <div className="flex justify-center items-center gap-5">
            {loading && <Spinner color={ColorsEnums.white} />}
            Resend confirmed email
          </div>
        </Button>
      </div>
      <ScBillingInformation
        className={`h-full overflow-y-auto !pt-[0] ${
          isLight ? '!bg-white' : '!bg-zinc-800'
        }`}
      >
        <div className="email-log">
          {order.emailLog != null &&
            order.emailLog.length > 0 &&
            order.emailLog.map((email) => (
              <EmailLogItem {...{ email }} key={email.id} />
            ))}
        </div>
      </ScBillingInformation>
    </div>
  );
};

export default EmailLog;
// Status
interface EmailLogItemProps {
  email: EmailLogEntity;
  // handleResendEmail: (id: string) => void;
}

const EmailLogItem: FC<EmailLogItemProps> = ({ email }) => {
  return (
    <ScEmailLogItem>
      <Status status={email.status} />
      <p className="text">
        {EmailTypeEnum[email.type as keyof typeof EmailTypeEnum]}
      </p>
      <p className="date">{getFullDate(email.createdDate, 'DD MMM, HH:mm')}</p>
      {/* <Button
        onClick={() => {
          handleResendEmail(email.id);
        }}
      >
        Resend
      </Button> */}
    </ScEmailLogItem>
  );
};
