import { FC, MutableRefObject, useCallback, useMemo, useState } from 'react';
import { Edit2, MoreVertical, Trash2 } from 'react-feather';
import { Cell } from 'react-table';
import { Modal } from 'components/elements/Modal';
import { NotificationModal } from 'components/modules/NotificationModal';
import { TitleH2, TitleH4 } from 'components/styles/common';
import { useGetOrganizationNotificationsQuery } from 'graphql/queries/notifications/__generated__/getOrganizationNotifications.generated';
import { capitalizeFirstLetter } from 'helpers/common';
import { useComponentVisible } from 'hooks/visibility';
import { ScContent } from '../styled';
import { ScNotifications, ScActionsBlock } from './styled';
import Table from 'components/elements/Table';
import { Button } from '../../../elements/Button';
import { theme } from '../../../../theme';
import {
  NotificationEntity,
  UpdateNotificationInput,
} from '../../../../types.generated';
import { errorAlert, infoAlert } from '../../../../helpers/alerts';
import { useDeleteNotificationMutation } from '../../../../graphql/mutations/settings/notifications/__generated__/deleteNotification.generated';
import { useUpdateNotificationMutation } from '../../../../graphql/mutations/settings/notifications/__generated__/updateNotification.generated';
import {
  NotificationPosition,
  NotificationPositionMeeting,
} from '../../../../helpers/enums/common';
import { getSiteTheme } from 'hooks/getSiteTheme';

type NotificationTableItem = {
  id: string;
  name?: string;
  type: string;
  body: string;
  timeToSendNotification: string;
  positionToSendNotification: NotificationPosition;
  positionMeetingToSendNotification: NotificationPositionMeeting;
};

export const Notifications = () => {
  const {
    data: notificationsData,
    loading,
    refetch,
  } = useGetOrganizationNotificationsQuery();
  const [deleteNotification] = useDeleteNotificationMutation();
  const [updateNotification] = useUpdateNotificationMutation();

  const [notificationId, setNotificationId] = useState<string | null>(null);

  const { isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const onCreateNotificationModal = useCallback(
    () => setIsComponentVisible((prev) => !prev),
    [setIsComponentVisible]
  );

  const closeCreateNotificationModal = () => {
    setIsComponentVisible((prev) => !prev);
    setNotificationId(null);
  };
  const notifications = useMemo(() => {
    if (
      notificationsData != null &&
      notificationsData.CurrentOrganization.notifications != null
    ) {
      return [...notificationsData.CurrentOrganization.notifications].sort(
        (a, b) => (a.type > b.type ? -1 : b.type > a.type ? 1 : 0)
      );
    }
  }, [notificationsData]);

  const selectedNotifications = useMemo(() => {
    if (notifications != null) {
      const i = notifications.find((item) => item.id === notificationId);
      return i != null
        ? {
            ...i,
            timeToSendNotification: `${
              +i.timeToSendNotification.split(':')[0] / 24
            }`,
          }
        : null;
    }
  }, [notificationId, notifications]);

  const handleDeleteNotification = useCallback(
    (deleteNotificationId: string) => {
      deleteNotification({ variables: { deleteNotificationId } }).then(
        ({ errors }) => {
          if (errors != null)
            errorAlert('Looks like this notification in used in some activity');
          else refetch();
        }
      );
    },
    [deleteNotification, refetch]
  );

  const handleUpdateNotification = (notification: UpdateNotificationInput) => {
    updateNotification({
      variables: {
        input: notification,
      },
    }).then(() => {
      infoAlert('Notification updated!');
      refetch();
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Text',
        accessor: 'body',
      },
      {
        Header: 'Time to send the notification',
        accessor: 'timeToSendNotification',
      },
      {
        Header: ' ',
        accessor: 'actions',
        Cell: (props: Cell<NotificationEntity>) => {
          const { id, body } = props.row.original;
          return (
            <TdActions
              {...{
                id,
                body,
                handleDeleteNotification,
                setNotificationId,
                onCreateNotificationModal,
              }}
              key={id}
            />
          );
        },
      },
    ],
    [handleDeleteNotification, onCreateNotificationModal]
  );

  const notificationsTableData = useMemo(() => {
    if (notifications != null)
      return notifications.map((notification) => ({
        id: notification.id,
        type: capitalizeFirstLetter(notification.type.toLowerCase()),
        body: notification.body,
        name: notification.name || '',
        timeToSendNotification:
          +notification.timeToSendNotification.split(':')[0] / 24 + ' days',
        positionToSendNotification:
          notification.positionToSendNotification as NotificationPosition,
        positionMeetingToSendNotification:
          notification.positionMeetingToSendNotification as NotificationPositionMeeting,
      }));
  }, [notifications]);

  return (
    <ScContent>
      <ScNotifications>
        <div className="header">
          <TitleH2>Notifications</TitleH2>
          <Button onClick={onCreateNotificationModal}>Add notification</Button>
        </div>
        <div className="body">
          <div className="notification-table">
            <Table<NotificationTableItem>
              {...{
                columns,
                data: notificationsTableData,
                emptyText: 'No notifications',
                emptyDescription: 'You don’t have any notification yet.',
                isLoading: loading,
              }}
            />
          </div>
        </div>
      </ScNotifications>
      {isComponentVisible && (
        <Modal onClose={closeCreateNotificationModal}>
          {(props) => (
            <NotificationModal
              {...props}
              onClose={closeCreateNotificationModal}
              refetch={refetch}
              update={handleUpdateNotification}
              {...selectedNotifications}
            />
          )}
        </Modal>
      )}
    </ScContent>
  );
};

interface TdActionsProps {
  id: string;
  handleDeleteNotification: (id: string) => void;
  setNotificationId: React.Dispatch<React.SetStateAction<string | null>>;
  onCreateNotificationModal: () => void;
}

export const TdActions: FC<TdActionsProps> = ({
  id,
  handleDeleteNotification,
  setNotificationId,
  onCreateNotificationModal,
}) => {
  const { ref, openerRef, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleOpen = () => setIsComponentVisible((prev) => !prev);

  const handleUpdate = useCallback(() => {
    setNotificationId(id);
    onCreateNotificationModal();
  }, [id, onCreateNotificationModal, setNotificationId]);

  return (
    <div className="actions">
      <div ref={ref}>
        <MoreVertical width={16} onClick={handleOpen} />
      </div>

      {isComponentVisible && (
        <ActionsBlock
          openerRef={openerRef}
          handleDelete={() => {
            handleDeleteNotification(id);
            handleOpen();
          }}
          handleUpdate={handleUpdate}
        />
      )}
    </div>
  );
};

interface ActionsBlockProps {
  openerRef: MutableRefObject<any>;
  handleDelete: () => void;
  handleUpdate: () => void;
}

const ActionsBlock: FC<ActionsBlockProps> = ({
  openerRef,
  handleDelete,
  handleUpdate,
}) => {
  const { isLight } = getSiteTheme();

  return (
    <ScActionsBlock
      ref={openerRef}
      className={`${!isLight && '!bg-slate-900'}`}
    >
      <div className="item" onClick={handleUpdate}>
        <Edit2 width={16} color={theme.colorSecondaryVioletDark} />{' '}
        <TitleH4>Edit</TitleH4>
      </div>
      <div className="item" onClick={handleDelete}>
        <Trash2 width={16} color={theme.colorErrorRedDark} />{' '}
        <TitleH4>Delete</TitleH4>
      </div>
    </ScActionsBlock>
  );
};
