import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentOrganizationQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type CurrentOrganizationQuery = {
  __typename?: 'Query';
  CurrentOrganization: {
    __typename?: 'OrganizationEntity';
    id: string;
    createdDate?: any | null;
    name: string;
    registrationNumber?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    stripeAccountId?: string | null;
    city?: string | null;
    country?: string | null;
    postcode?: string | null;
    address?: string | null;
    description?: string | null;
    taxRate: number;
    isPayByInvoiceActive: boolean;
    isStripeActive: boolean;
    termsAndConditions?: string | null;
    shopifyShopName?: string | null;
    shopifyAccessToken?: string | null;
    shopifyShopUrl?: string | null;
    vendor?: string | null;
    image?: {
      __typename?: 'OrganizationImageEntity';
      id: string;
      link: string;
    } | null;
  };
};

export const CurrentOrganizationDocument = gql`
  query CurrentOrganization {
    CurrentOrganization {
      id
      createdDate
      name
      registrationNumber
      email
      phoneNumber
      stripeAccountId
      city
      country
      postcode
      address
      description
      taxRate
      isPayByInvoiceActive
      isStripeActive
      termsAndConditions
      shopifyShopName
      shopifyAccessToken
      shopifyShopUrl
      vendor
      image {
        id
        link
      }
    }
  }
`;

/**
 * __useCurrentOrganizationQuery__
 *
 * To run a query within a React component, call `useCurrentOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentOrganizationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentOrganizationQuery,
    CurrentOrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentOrganizationQuery,
    CurrentOrganizationQueryVariables
  >(CurrentOrganizationDocument, options);
}
export function useCurrentOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentOrganizationQuery,
    CurrentOrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentOrganizationQuery,
    CurrentOrganizationQueryVariables
  >(CurrentOrganizationDocument, options);
}
export type CurrentOrganizationQueryHookResult = ReturnType<
  typeof useCurrentOrganizationQuery
>;
export type CurrentOrganizationLazyQueryHookResult = ReturnType<
  typeof useCurrentOrganizationLazyQuery
>;
export type CurrentOrganizationQueryResult = Apollo.QueryResult<
  CurrentOrganizationQuery,
  CurrentOrganizationQueryVariables
>;
