import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GlobalSearchQueryVariables = Types.Exact<{
  search: Types.Scalars['String'];
}>;

export type GlobalSearchQuery = {
  __typename?: 'Query';
  globalSearch: {
    __typename?: 'SearchRespond';
    activities: Array<{
      __typename?: 'ActivityEntity';
      id: string;
      title: string;
    }>;
    activityClasses: Array<{
      __typename?: 'ActivityClassEntity';
      id: string;
      title: string;
      activity: { __typename?: 'ActivityEntity'; id: string; title: string };
    }>;
  };
};

export const GlobalSearchDocument = gql`
  query GlobalSearch($search: String!) {
    globalSearch(search: $search) {
      activities {
        id
        title
      }
      activityClasses {
        id
        title
        activity {
          id
          title
        }
      }
    }
  }
`;

/**
 * __useGlobalSearchQuery__
 *
 * To run a query within a React component, call `useGlobalSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGlobalSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    GlobalSearchQuery,
    GlobalSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GlobalSearchQuery, GlobalSearchQueryVariables>(
    GlobalSearchDocument,
    options
  );
}
export function useGlobalSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GlobalSearchQuery,
    GlobalSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GlobalSearchQuery, GlobalSearchQueryVariables>(
    GlobalSearchDocument,
    options
  );
}
export type GlobalSearchQueryHookResult = ReturnType<
  typeof useGlobalSearchQuery
>;
export type GlobalSearchLazyQueryHookResult = ReturnType<
  typeof useGlobalSearchLazyQuery
>;
export type GlobalSearchQueryResult = Apollo.QueryResult<
  GlobalSearchQuery,
  GlobalSearchQueryVariables
>;
