import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrganizationNotificationsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetOrganizationNotificationsQuery = {
  __typename?: 'Query';
  CurrentOrganization: {
    __typename?: 'OrganizationEntity';
    id: string;
    notifications?: Array<{
      __typename?: 'NotificationEntity';
      id: string;
      createdDate?: any | null;
      updatedDate?: any | null;
      deletedDate?: any | null;
      replyEmail?: string | null;
      bccEmail?: string | null;
      name?: string | null;
      timeToSendNotification: string;
      positionToSendNotification: string;
      positionMeetingToSendNotification: string;
      subject: string;
      body: string;
      type: string;
      organizationId: string;
    }> | null;
  };
};

export const GetOrganizationNotificationsDocument = gql`
  query GetOrganizationNotifications {
    CurrentOrganization {
      id
      notifications {
        id
        createdDate
        updatedDate
        deletedDate
        replyEmail
        bccEmail
        name
        timeToSendNotification
        positionToSendNotification
        positionMeetingToSendNotification
        subject
        body
        type
        organizationId
      }
    }
  }
`;

/**
 * __useGetOrganizationNotificationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationNotificationsQuery,
    GetOrganizationNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrganizationNotificationsQuery,
    GetOrganizationNotificationsQueryVariables
  >(GetOrganizationNotificationsDocument, options);
}
export function useGetOrganizationNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationNotificationsQuery,
    GetOrganizationNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganizationNotificationsQuery,
    GetOrganizationNotificationsQueryVariables
  >(GetOrganizationNotificationsDocument, options);
}
export type GetOrganizationNotificationsQueryHookResult = ReturnType<
  typeof useGetOrganizationNotificationsQuery
>;
export type GetOrganizationNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationNotificationsLazyQuery
>;
export type GetOrganizationNotificationsQueryResult = Apollo.QueryResult<
  GetOrganizationNotificationsQuery,
  GetOrganizationNotificationsQueryVariables
>;
