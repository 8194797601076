import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCheckoutFieldMutationVariables = Types.Exact<{
  input: Types.UpdateCheckoutFieldsInput;
}>;

export type UpdateCheckoutFieldMutation = {
  __typename?: 'Mutation';
  UpdateCheckoutField: { __typename?: 'CheckoutFieldsEntity'; id: string };
};

export const UpdateCheckoutFieldDocument = gql`
  mutation UpdateCheckoutField($input: UpdateCheckoutFieldsInput!) {
    UpdateCheckoutField(input: $input) {
      id
    }
  }
`;
export type UpdateCheckoutFieldMutationFn = Apollo.MutationFunction<
  UpdateCheckoutFieldMutation,
  UpdateCheckoutFieldMutationVariables
>;

/**
 * __useUpdateCheckoutFieldMutation__
 *
 * To run a mutation, you first call `useUpdateCheckoutFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCheckoutFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCheckoutFieldMutation, { data, loading, error }] = useUpdateCheckoutFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCheckoutFieldMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCheckoutFieldMutation,
    UpdateCheckoutFieldMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCheckoutFieldMutation,
    UpdateCheckoutFieldMutationVariables
  >(UpdateCheckoutFieldDocument, options);
}
export type UpdateCheckoutFieldMutationHookResult = ReturnType<
  typeof useUpdateCheckoutFieldMutation
>;
export type UpdateCheckoutFieldMutationResult =
  Apollo.MutationResult<UpdateCheckoutFieldMutation>;
export type UpdateCheckoutFieldMutationOptions = Apollo.BaseMutationOptions<
  UpdateCheckoutFieldMutation,
  UpdateCheckoutFieldMutationVariables
>;
