import { ColorsEnums } from 'helpers/enums/common';
import { theme } from 'theme';

export const buttonColorStyles = (color?: ColorsEnums, isLoading?: boolean) => {
  switch (color) {
    case ColorsEnums.white:
      return {
        'background-color': theme.colorWhite,
        color: isLoading ? theme.colorWhite : theme.colorSecondaryVioletDark,
        'border-color': theme.colorSecondaryGrayLight,
      };
    case ColorsEnums.black:
      return {
        'background-color': theme.colorBlack,
        color: isLoading ? theme.colorWhite : theme.colorWhite,
        'border-color': theme.colorSecondaryGrayLight,
      };
    case ColorsEnums.error:
      return {
        'background-color': theme.colorErrorRedLight,
        color: isLoading ? theme.colorErrorRedLight : theme.colorErrorRedDark,
        'border-color': theme.colorErrorRedDark,
      };
    case ColorsEnums.link:
      return {
        'background-color': 'unset',
        color: isLoading ? 'transparent' : theme.colorPrimaryBlack,
        'border-color': 'unset',
        'border-width': '0px',
      };
    case ColorsEnums.violet:
    default:
      return {
        'background-color': theme.colorSecondaryVioletDark,
        color: isLoading ? theme.colorSecondaryVioletDark : theme.colorWhite,
        'border-color': theme.colorSecondaryVioletDark,
      };
  }
};

export const buttonHoverColorStyles = (
  color?: ColorsEnums,
  isLoading?: boolean,
  disabled?: boolean
) => {
  if (isLoading || disabled) return;
  switch (color) {
    case ColorsEnums.white:
      return {};
    case ColorsEnums.error:
      return {
        'background-color': theme.colorErrorRedDark,
        color: theme.colorWhite,
        'border-color': theme.colorErrorRedDark,
      };
    case ColorsEnums.link:
      return {};
    case ColorsEnums.violet:
    default:
      return {
        'background-color': theme.colorPrimaryViolet,
        color: theme.colorWhite,
        'border-color': theme.colorPrimaryViolet,
      };
  }
};
