import { FC, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChevronDown, Search } from 'react-feather';
import { Cell } from 'react-table';
import Tippy from '@tippyjs/react';
import { TextSmall, TitleH2 } from 'components/styles/common';
import { ScContent } from '../styled';
import { ScTeam } from './styled';
import { Button } from 'components/elements/Button';
import { Modal } from 'components/elements/Modal';
import {
  InviteTeacherModal,
  RoleOptions,
} from 'components/modules/TeacherModal';
import { useAvailableTeachersQuery } from 'graphql/queries/teachers/__generated__/availableTeachers.generated';
import { capitalizeFirstLetter } from 'helpers/common';
import { useComponentVisible } from 'hooks/visibility';
import { theme } from 'theme';
import { useChangeRoleMutation } from 'graphql/mutations/settings/__generated__/changeMemberRole.generated';
import { SearchDto, searchSchema } from 'hooks/search/search';
import InputForm from 'components/elements/inputs';
import Table from 'components/elements/Table';
import avatarIcon from 'theme/images/avatar.svg';
import { Role } from 'helpers/enums/common';
import { useCancelMembershipMutation } from 'graphql/mutations/inviteUser/__generated__/cancelMembership.generated';
import { useUpdateTeacherMutation } from 'graphql/mutations/user/__generated__/updateTeacher.generated';

export type MemberType = {
  id: string;
  name: string;
  email: string;
  role: string;
  status: string;
  avatar?: string;
  hourlyRate?: number | null;
};

export type TeacherHourlyRate = {
  id: string;
  hourlyRate?: number | null;
};

export const Team = () => {
  const { data: teachersData, loading, refetch } = useAvailableTeachersQuery();
  const [cancelMembership] = useCancelMembershipMutation();
  const [changeRole] = useChangeRoleMutation();
  // const [clicOnRate, isClicOnRate] = useState(false)

  const [updateTeacher] = useUpdateTeacherMutation();

  const methods = useForm<SearchDto>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    resolver: yupResolver(searchSchema),
    defaultValues: {
      searchValue: '',
    },
  });
  const { watch } = methods;

  const searchValue = watch('searchValue');

  const [isOpenTeacherModal, setOpenTeacherModal] = useState(false);
  const onTeacherModal = () => setOpenTeacherModal((prev) => !prev);

  const handleChangeRole = useCallback(
    (role: Role, id: string) => {
      changeRole({
        variables: {
          input: {
            roles: [role],
            permissionId: id,
          },
        },
      }).then(({ data, errors }) => {
        if (errors == null && data != null) {
          refetch();
        }
      });
    },
    [changeRole, refetch]
  );

  const teamMembers = useMemo(() => {
    if (teachersData != null)
      return teachersData.CurrentOrganization.members
        ?.filter((item) => {
          const name = item.name != null ? item.name.toLowerCase() : '—';
          const email = item.email != null ? item.email.toLowerCase() : '—';
          const search =
            searchValue != null ? searchValue.toLowerCase() : searchValue;
          return name.indexOf(search) > -1 || email.indexOf(search) > -1;
        })
        .map((item) => ({
          id: item.id,
          name: item.name || '—',
          email: item.email,
          role: item.roles.reduce((acc, role, index) => {
            if (index + 1 === item.roles.length && item.roles.length > 1)
              return acc + ` ${role}`;
            if (index === 0) return `${role}`;
            return acc + `, ${role}`;
          }, ''),
          status: item.status,
          hourlyRate: item.hourlyRate,
          avatar: item.avatar?.link || avatarIcon,
        }))
        .sort((a, b) =>
          a.status > b.status ? 1 : b.status > a.status ? -1 : 0
        );
  }, [searchValue, teachersData]);

  const handleCancelMembership = useCallback(
    (id: string) => {
      const isConfirm = window.confirm(
        'Are you sure you want to cancel membership?'
      );
      if (isConfirm) {
        cancelMembership({ variables: { input: { permissionId: id } } }).then(
          () => {
            refetch();
          }
        );
      }
    },
    [cancelMembership, refetch]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: (props: Cell<MemberType>) => (
          <div className="avatar-name">
            <img src={props.row.original.avatar || avatarIcon} alt="" />
            <p>{props.row.original.name}</p>
          </div>
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: (props: Cell<MemberType>) => (
          <Tippy content={props.row.original.email}>
            <p className="max-w">{props.row.original.email}</p>
          </Tippy>
        ),
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: (props: Cell<MemberType>) => {
          return (
            <TdRole
              role={props.value as Role}
              handleChangeRole={handleChangeRole}
              id={props.row.original.id}
            />
          );
        },
      },
      {
        Header: 'Earn Rate',
        accessor: 'hourlyRate',
        Cell: (props: Cell<MemberType>) => {
          const hourlyRate = props.row.original.hourlyRate;
          const id = props.row.original.id;
          const isTeacher =
            props.row.original.role === 'TEACHER' ? true : false;
          console.log(isTeacher, 'ROLE');
          return (
            <>
              {isTeacher ? (
                <TdHourlyRate
                  id={id}
                  hourlyRate={hourlyRate}
                  updateTeacher={updateTeacher}
                />
              ) : (
                ''
              )}
            </>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (props: Cell<MemberType>) => (
          <p className={`status ${props.value.toLowerCase()}`}>
            {capitalizeFirstLetter(props.value.toLowerCase())}
          </p>
        ),
      },
      {
        Header: '',
        accessor: 'acctions',
        Cell: (props: Cell<MemberType>) => {
          return (
            <TdCancelMembership
              handleCancelMembership={handleCancelMembership}
              id={props.row.original.id}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleCancelMembership, handleChangeRole]
  );

  return (
    <ScContent>
      <ScTeam>
        <div className="header">
          <TitleH2>Team</TitleH2>
          <Button onClick={onTeacherModal}>Add member</Button>
        </div>
        <div className="body">
          <FormProvider {...methods}>
            <form>
              <InputForm<SearchDto>
                id="searchValue"
                placeholder="Search"
                type="text"
                Icon={Search}
              />
            </form>
          </FormProvider>
          <div className="team-table">
            <Table<MemberType>
              {...{
                columns,
                data: teamMembers as MemberType[],
                emptyText: 'No members',
                emptyDescription: 'You don’t have any member yet.',
                isLoading: loading,
              }}
            />
          </div>
        </div>
      </ScTeam>
      {isOpenTeacherModal && (
        <Modal onClose={onTeacherModal} alpha={0.5}>
          {(props) => (
            <InviteTeacherModal
              {...props}
              onClose={onTeacherModal}
              refetch={refetch}
            />
          )}
        </Modal>
      )}
    </ScContent>
  );
};

interface TdHourlyRateProps {
  id: string;
  hourlyRate?: number | null;
  updateTeacher: any;
}

const TdHourlyRate: FC<TdHourlyRateProps> = ({
  id,
  hourlyRate,
  updateTeacher,
}) => {
  const [click, setClick] = useState(false);
  const [value, setValue] = useState<undefined | number | null>(hourlyRate);
  const [newHourlyRate, setNewHourlyRate] = useState<undefined | number | null>(
    null
  );

  const handlerTecherHoulryRate: any = (id: string, hourlyRate: number) => {
    console.log('handlerTecherHoulryRate - START');
    return updateTeacher({
      variables: {
        input: { permissionId: id, hourlyRate },
      },
    });
  };

  console.log(value);

  return (
    <>
      {click ? (
        <div>
          <div className="mt-2 flex rounded-md shadow-sm">
            <div className="relative flex items-stretch focus-within:z-10">
              <input
                id="number"
                name="number"
                type="number"
                placeholder="DKK"
                //@ts-ignore
                value={value}
                onChange={(e) => {
                  //@ts-ignore
                  setValue(e.target.value);
                }}
                className="block w-16 disable-arrow-input-number rounded-none rounded-l-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <button
              onClick={() => {
                setNewHourlyRate(value);
                handlerTecherHoulryRate(id, Number(value));
                setClick(false);
              }}
              type="button"
              className="relative inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Save
            </button>
          </div>
        </div>
      ) : (
        <span className="cursor-pointer" onClick={() => setClick(true)}>
          {newHourlyRate ? newHourlyRate : hourlyRate ? hourlyRate : 'None'}
        </span>
      )}
    </>
  );
};

interface TdRoleProps {
  role: Role;
  handleChangeRole: (role: Role, id: string) => void;
  id: string;
}

const TdRole: FC<TdRoleProps> = ({ role, handleChangeRole, id }) => {
  const {
    ref: visibleRef,
    openerRef,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  const handleOpen = () => setIsComponentVisible((prev) => !prev);

  const changeRole = (role: Role) => {
    handleChangeRole(role, id);
  };

  return (
    <td className="role-td">
      <TextSmall
        className="role-btn"
        color={theme.colorSecondaryVioletDark}
        onClick={handleOpen}
        ref={visibleRef}
      >
        {capitalizeFirstLetter(role.toLowerCase())}
        {/*@ts-ignore*/}
        {role !== 'OWNER' && <ChevronDown />}
      </TextSmall>
      {/*@ts-ignore*/}
      {isComponentVisible && role !== 'OWNER' && (
        <RoleOptions
          {...{
            openerRef,
            handleOpen,
            currentRole: role,
            onClick: changeRole,
          }}
        />
      )}
    </td>
  );
};

interface TdCancelMembershipPorps {
  handleCancelMembership: (id: string) => void;
  id: string;
}

const TdCancelMembership: FC<TdCancelMembershipPorps> = ({
  handleCancelMembership,
  id,
}) => {
  const cancelMembership = useCallback(() => {
    handleCancelMembership(id);
  }, [handleCancelMembership, id]);

  return (
    <td className="cancel-td">
      {/* <X onClick={cancelMembership} /> */}
      <Button color={'rose'} onClick={cancelMembership}>
        Delete
      </Button>
    </td>
  );
};
