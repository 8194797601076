import styled from 'styled-components';
export const ScInputForm = styled.div`
  & > label {
    display: block;
    color: ${({ theme }) => theme.colorSecondaryGrayDark};
  }
  & > .input-container {
    margin-top: 16px;
    position: relative;
    & > .icon {
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
    }
    & > input {
      margin-top: 16px;
    }
  }
`;

export interface ScInputProps {
  error?: any;
  readOnlyStyled?: boolean;
  Icon?: any;
}

export const ScInput = styled.input<ScInputProps>`
  display: block;
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colorSecondaryGrayLight};
  padding: 8px 12px;
  :focus {
    border-color: ${({ theme }) => theme.colorSecondaryVioletDark};
  }

  text-indent: ${({ Icon }) => (Icon != null ? '24px' : '')};

  ${({ readOnly, theme, error }) => {
    if (readOnly) {
      return `
      cursor: not-allowed;
      background-color: ${theme.colorSecondaryGrayLight};
      :focus {
        border-color: ${theme.colorSecondaryGrayLight};;
      }
      `;
    } else if (error) {
      return `
      border-color: ${theme.colorErrorRedDark};
      :focus {
        border-color: ${theme.colorErrorRedDark};
      }
      `;
    } else {
      return `
      background-color: ${theme.colorWhite};
      :focus {
        border-color: ${theme.colorSecondaryVioletDark};
      }
      `;
    }
  }}
`;

export const ScError = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 8px;
`;

export const ScErrorText = styled.div`
  margin-top: 8px;
  & > .error {
  }
  & > .help {
  }
`;
