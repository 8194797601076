import React, { FC, useMemo } from 'react';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useTable } from 'react-table';
import DraggableTableRow from './fields/DraggableTableRow';
import StaticTableRow from './fields/StaticTableRow';
import { getSiteTheme } from 'hooks/getSiteTheme';

interface TableDnDProps {
  columns: any;
  data: any;
  setData: any;
  activeId: any;
  setActiveId: any;

  setSortOrder: (fieldIds: string[]) => void;
}

const TableDnD: FC<TableDnDProps> = ({
  columns,
  data,
  setData,
  activeId,
  setActiveId,
}) => {
  const items = useMemo(() => data?.map(({ id }: any) => id), [data]);
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  function handleDragStart(event: any) {
    // setIsPossibleToClose(false);
    setActiveId(event.active.id);
  }

  function handleDragEnd(event: any) {
    const { active, over } = event;
    if (active.id !== over.id) {
      // setSortOrder(active.data.current.sortable.items as string[]);
      setData((data: any) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(data, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = rows.find(({ original }: any) => original.id === activeId);
    prepareRow(row as any);
    return row;
  }, [activeId, rows, prepareRow]);

  const { isLight } = getSiteTheme();
  // Render the UI for your table
  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <div
        className={`overflow-hidden overflow-x-scroll bg-slate-200 shadow ring-1 ring-black ring-opacity-5 rounded-lg`}
      >
        <table
          {...getTableProps()}
          className={`min-w-full divide-y ${
            isLight ? 'divide-gray-300' : 'divide-gray-500'
          }`}
        >
          <thead className={`${isLight ? 'bg-gray-50' : 'bg-slate-700'}`}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className={`px-3 py-3.5 text-left text-sm font-semibold ${
                      isLight ? 'text-gray-900' : 'text-slate-100'
                    }`}
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            className={`divide-y  ${
              isLight
                ? 'bg-white divide-gray-200'
                : `bg bg-slate-800 divide-gray-600`
            }`}
            {...getTableBodyProps()}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {rows.map((row, i) => {
                prepareRow(row);

                return (
                  <DraggableTableRow
                    //@ts-ignore

                    key={`${row.original.id}`}
                    row={row}
                  />
                );
              })}
            </SortableContext>
          </tbody>
        </table>
        <DragOverlay>
          {activeId && (
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
              }}
            >
              <tbody>
                <StaticTableRow row={selectedRow} />
              </tbody>
            </table>
          )}
        </DragOverlay>
      </div>
    </DndContext>
  );
};

export default TableDnD;
