import * as yup from 'yup';
import {
  NotificationPosition,
  NotificationPositionMeeting,
} from '../../../helpers/enums/common';

export type NotificationDto = {
  replyEmail?: string;
  bccEmail?: string;
  name?: string;
  days: number;
  subject: string;
  body: string;
  recursive?: boolean;
  positionToSendNotification: NotificationPosition;
  positionMeetingToSendNotification: NotificationPositionMeeting;
};

export const NotificationSchema: yup.SchemaOf<NotificationDto> = yup
  .object({
    replyEmail: yup.string().email(),
    bccEmail: yup.string().email(),
    name: yup.string(),
    days: yup.number().required(),
    subject: yup.string().required(),
    body: yup.string().required(),
    recursive: yup.boolean().required(),
    positionToSendNotification: yup
      .mixed()
      .oneOf([Object.values(NotificationPosition)]),
    positionMeetingToSendNotification: yup
      .mixed()
      .oneOf([Object.values(NotificationPositionMeeting)]),
  })
  .required();
