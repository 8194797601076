import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateLocationMutationVariables = Types.Exact<{
  input: Types.UpdateLocationInput;
}>;

export type UpdateLocationMutation = {
  __typename?: 'Mutation';
  UpdateLocation: { __typename?: 'LocationEntity'; id: string };
};

export const UpdateLocationDocument = gql`
  mutation UpdateLocation($input: UpdateLocationInput!) {
    UpdateLocation(input: $input) {
      id
    }
  }
`;
export type UpdateLocationMutationFn = Apollo.MutationFunction<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLocationMutation,
    UpdateLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLocationMutation,
    UpdateLocationMutationVariables
  >(UpdateLocationDocument, options);
}
export type UpdateLocationMutationHookResult = ReturnType<
  typeof useUpdateLocationMutation
>;
export type UpdateLocationMutationResult =
  Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>;
