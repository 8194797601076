import React, { useEffect } from 'react';
import { usePreventScroll } from 'hooks/scroll';
import {
  ScModal,
  ScModalBg,
  ScModalContainer,
  ScModalContent,
  ScModalContentProps,
} from './styled';
import { useComponentVisible } from 'hooks/visibility';

export interface ModalProps extends ScModalContentProps {
  children:
    | ((props: {
        ref: React.MutableRefObject<HTMLDivElement>;
      }) => React.ReactNode)
    | React.ReactNode;
  onClose?: () => void;
  withoutBackground?: boolean;
  bgWidth?: number;
  bgHeight?: number;
  bgRadius?: number;
  alpha?: number;
}

export function Modal(props: ModalProps) {
  const {
    children,
    verticalAlign,
    fullHeight,
    absolute,
    onClose,
    withoutBackground,
    bgWidth,
    bgHeight,
    bgRadius,
    alpha,
  } = props;

  const { ref, isComponentVisible } = useComponentVisible(true);

  usePreventScroll(document.body);

  useEffect(() => {
    if (!isComponentVisible) {
      onClose && onClose();
    }
  }, [isComponentVisible, onClose]);

  return (
    <ScModal>
      {withoutBackground ? (
        <></>
      ) : (
        <ScModalBg {...{ bgWidth, bgHeight, bgRadius, alpha }} />
      )}
      <ScModalContainer>
        <ScModalContent
          absolute={absolute}
          verticalAlign={verticalAlign}
          fullHeight={fullHeight}
        >
          {typeof children === 'function' ? children({ ref }) : children}
        </ScModalContent>
      </ScModalContainer>
    </ScModal>
  );
}
