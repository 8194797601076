import { SettingTab } from 'components/modules/Settings';
import { getAllUrlParams } from './common';

type ParamsType = {
  settings?: boolean | null;
  tab?: SettingTab | null;
};

export default function SettingsDetector(
  openSettingsModal: () => void,
  setActiveTab: React.Dispatch<React.SetStateAction<SettingTab>>
) {
  const { settings, tab } = getAllUrlParams(window.location.href) as ParamsType;
  if (settings) {
    openSettingsModal();
    if (tab != null) {
      setActiveTab(tab);
    }
  }
}
