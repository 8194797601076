import styled from 'styled-components';

export const ScCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  cursor: pointer;
  .label-text {
    font-weight: bold;
  }
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    /* margin-bottom: 12px; */
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: -12px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #ebecf0;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    /* background-color: #b69deb; */
    border: 1px solid #b69deb;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #b69deb;
    border: 1px solid #b69deb;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
