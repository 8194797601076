import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeRoleMutationVariables = Types.Exact<{
  input: Types.ChangeRoleInput;
}>;

export type ChangeRoleMutation = {
  __typename?: 'Mutation';
  ChangeRole: { __typename?: 'PermissionEntity'; id: string };
};

export const ChangeRoleDocument = gql`
  mutation ChangeRole($input: ChangeRoleInput!) {
    ChangeRole(input: $input) {
      id
    }
  }
`;
export type ChangeRoleMutationFn = Apollo.MutationFunction<
  ChangeRoleMutation,
  ChangeRoleMutationVariables
>;

/**
 * __useChangeRoleMutation__
 *
 * To run a mutation, you first call `useChangeRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeRoleMutation, { data, loading, error }] = useChangeRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeRoleMutation,
    ChangeRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeRoleMutation, ChangeRoleMutationVariables>(
    ChangeRoleDocument,
    options
  );
}
export type ChangeRoleMutationHookResult = ReturnType<
  typeof useChangeRoleMutation
>;
export type ChangeRoleMutationResult =
  Apollo.MutationResult<ChangeRoleMutation>;
export type ChangeRoleMutationOptions = Apollo.BaseMutationOptions<
  ChangeRoleMutation,
  ChangeRoleMutationVariables
>;
